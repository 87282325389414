class ConfiguratorRuleEventEmitter {
  constructor() {
    console.warn( " ------------------- ConfiguratorRuleEventEmitter - constructor");
    this.listeners = {};
    this.globalListeners = [];
  }

  on(event, listener) {
    const targetListeners =
      event === "*" ? this.globalListeners : this.listeners[event] || [];

    // Check if the listener is already added for the event or globally
    const isListenerAdded = targetListeners.some(
      (existingListener) => existingListener === listener
    );
    if (!isListenerAdded) {
      targetListeners.push(listener);
    }

    // If not a global listener, ensure it's stored correctly
    if (event !== "*") {
      this.listeners[event] = targetListeners;
    }
  }

  emit(emittedObject) {
    const { event } = emittedObject;

    // Notify global listeners
    this.globalListeners.forEach((listener) => listener(emittedObject));

    // Notify specific event listeners
    if (this.listeners[event]) {
      this.listeners[event].forEach((listener) => listener(emittedObject));
    }
  }

  off(event, listener) {
    const targetListeners =
      event === "*" ? this.globalListeners : this.listeners[event];

    if (targetListeners) {
      const listenerIndex = targetListeners.indexOf(listener);
      if (listenerIndex > -1) {
        targetListeners.splice(listenerIndex, 1);
      }
    }
  }

  events = {
    LOG: "log",
    START_RUN_RULES: "startRunRules",
    END_RUN_RULES: "endRunRules",
    CLEAR_RULE_OUTPUT: "clearRuleOutput",
    START_CHECK_RULES: "startCheckRules",
    END_CHECK_RULES: "endCheckRules",
    START_RULE_CHECK: "startRuleCheck",
    END_RULE_CHECK: "endRuleCheck",
    EXecute_RULE_ACTION: "executeRuleAction",
    CONDITION_RULE_SATISFIED: "conditionRuleSatisfied",
    CONDITION_RULE_NOT_SATISFIED: "conditionRuleNotSatisfied",
  };

  actions = {
    NONE: "NONE",
    CLEAR_ALL_RULES: "CLEAR_ALL_RULES",
    ADD_OR_CHANGE_MACHIN_OPTION_QTY: "ADD_OR_CHANGE_MACHIN_OPTION_QTY",
    CHANGE_MACHINE_OPTION_QTY: "CHANGE_MACHINE_OPTION_QTY",
  };
}

const instance = new ConfiguratorRuleEventEmitter();
Object.freeze(instance);

export default instance;
