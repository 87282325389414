import state from "./configuratorState";
import mutations from "./configuratorMutations";
import getters from "./configuratorGetters";
import actions from "./configuratorActions";

export default {
  state,
  mutations,
  getters,
  actions,
};
