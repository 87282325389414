<template>
  <transition :name="effect">
    <picture
      :class="[imagelayer.position == '1' ? 'base' : 'layer']"
      :style="{ zIndex: imagelayer.position }"
    >
      <img
        v-if="option.orderQty == 0 || option.orderQty == 1"
        :src="imagelayer.baseLayer"
        :srcset="imagelayer.baseLayer + ' 1x,' + imagelayer.baseLayer + ' 2x'"
        alt=""
      />
      <img
        v-else-if="option.orderQty > 1"
        :src="imagelayer.viewMulti"
        :srcset="imagelayer.viewMulti + ' 1x,' + imagelayer.viewMulti + ' 2x'"
        alt=""
      />
    </picture>
  </transition>
</template>

<script>
export default {
  name: "Layer",
  props: ["imagelayer", "imagelayers", "option"],
  created() {},
  computed: {
    effect: function () {
      if (this.imagelayer.position == "1") {
        return "fade";
      } else {
        return "unitfade";
      }
    },
  },
};
</script>
