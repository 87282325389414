<template>
  <div class="configurator-navi">
    <div class="container">
      <!--  <transition name="fade"> -->
      <div v-if="this.$store.getters.ready == true">
        <nav class="navbar navbar-expand-md navbar-inverse bg-faded c-navbar">
          <button
            class="navbar-toggler my-20 p-10 float-right"
            type="button"
            data-toggle="collapse"
            data-target="#configuratorcategories"
            aria-controls="configuratorcategories"
            aria-expanded="false"
            aria-label="Toggle navigation"
            @click="toggleCategories"
          >
            {{ getStaticText("categories") }}
            <span v-if="isToggleUnitDropdownOpen">
              <i class="fa fa-angle-up mx-2"></i>
            </span>
            <span v-else>
              <i class="fa fa-angle-down mx-2"></i>
            </span>
          </button>

          <div
            id="configuratorcategories"
            data-tt="sdfsd2"
            class="collapse navbar-collapse configurator-categories-nav"
          >
            <ul class="navbar-nav">
              <select-unit></select-unit>
              <li
                v-for="category in selectedMachineCategories"
                class="nav-item"
                :key="category.id"
                v-if="showHiddenContent(category)"
                v-on:click="selectCategory"
                :data-id="category.id"
                v-bind:class="[
                  category.id == selectedMachineCategory.id ? 'active' : '',
                ]"
              >
                {{ getText(category.name, "value") }}
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <!-- </transition> -->

      <div class="ph-item" v-if="this.$store.getters.ready == false">
        <div class="ph-col-12">
          <div class="ph-row pt-25">
            <div class="ph-col-2 big my-0 mr-20"></div>
            <div class="ph-col-2 big my-0 mr-20"></div>
            <div class="ph-col-2 big my-0 mr-20"></div>
            <div class="ph-col-2 big my-0 mr-20"></div>
            <div class="ph-col-2 big my-0 mr-20"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SelectUnit from "@/components/configurator/SelectUnit.vue";
import {  showAreaHiddenContent } from "@/utils/common-utils";


export default {
  name: "SelectCategory",
  computed: {
    ...mapGetters([
      "selectedMachineCategories",
      "getText",
      "getStaticText",
      "selectedMachineCategory",
      "configuratorDebugMode",
      "configuratorEditMode",

    ]),
  },
  data() {
    return {
      isToggleUnitDropdownOpen: false,
    };
  },
  components: {
    SelectUnit,
  },
  methods: {
    ...mapActions(["SELECT_MACHINE_CATEGORY"]),

    selectCategory: function (event) {
      this.SELECT_MACHINE_CATEGORY(~~event.target.attributes["data-id"].value);
      this.$root.$emit("categoryChange");
    },

    toggleCategories() {
      this.isToggleUnitDropdownOpen = !this.isToggleUnitDropdownOpen;
    },

    showHiddenContent: function (option) {
      return showAreaHiddenContent('category', option.isHidden);
    },
  },
};
</script>
