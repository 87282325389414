var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},_vm._l((_vm.configuratorRuleMessages),function(configuratorMessage){return _c('div',{key:configuratorMessage.id,staticClass:"alert",class:{
      'alert-danger': configuratorMessage.severity === 'Error',
      'alert-light': configuratorMessage.severity === 'Recommened',
      'alert-info': configuratorMessage.severity === 'Info'
    }},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('strong',[_c('i',{staticClass:"fa",class:{
            'fa-warning': configuratorMessage.severity === 'Error',
            'fa-bell': configuratorMessage.severity === 'Recommened',
            'fa-info': configuratorMessage.severity === 'Info'
          }}),_c('strong',[_c('span',{staticClass:"mx-1",domProps:{"innerHTML":_vm._s(configuratorMessage.title)}})])]),(configuratorMessage.hasResolveAction)?_c('div',{staticClass:"mb-1 mt-n1"},[_c('button',{staticClass:"btn btn-primary btn-sm pull-right pointer",on:{"click":function($event){return _vm.resolveRuleAction(configuratorMessage)}}},[_vm._v(" "+_vm._s(_vm.getStaticText("resolve"))+" "),_c('i',{staticClass:"fa fa-plus"})])]):_vm._e()]),_c('div',{staticClass:"d-flex flex-column justify-content-between info-message",attrs:{"role":"alert"}},[_c('div',{},[_c('div',{staticClass:"alert-message-container"},[_c('p',{staticClass:"message-body"},[_c('span',{domProps:{"innerHTML":_vm._s(configuratorMessage.message)}})])])]),(_vm.authenticated && _vm.configuratorDebugMode)?_c('div',{staticClass:"bg-primary"},[_vm._v(" "+_vm._s(configuratorMessage.id)+" - "+_vm._s(configuratorMessage.ruleName)+" ")]):_vm._e()])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }