export const emailCheck = email => {
  let emailreg = /^([a-zA-Z0-9]+[_|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
  return emailreg.test(email);
};

export const getLocalReFreshToken = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user.RefreshToken;
};
export const getLocalAccessToken = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user ? user.AccessToken : null;
};
export const updateLocalAccessToken = token => {
  const user = JSON.parse(localStorage.getItem("user"));
  const { accessToken, refreshToken } = token;
  user.AccessToken = accessToken;
  user.RefreshToken = refreshToken;
  localStorage.setItem("user", JSON.stringify(user));
};

export const routeHasQueryParams = route => {
  return route.query !== undefined && Object.keys(route.query).length > 0;
};

export const hasDebugQueryParams = query => {
  if (query === undefined || Object.keys(query).length === 0) return false;

  const urlParams = new URLSearchParams(query);
  return urlParams.has("debug");
};

export const hasEditQueryParams = query => {
  if (query === undefined || Object.keys(query).length === 0) return false;

  const urlParams = new URLSearchParams(query);
  return urlParams.has("edit") && urlParams.has("doc");
};
export const routeRequireAuth = query => {

  return hasEditQueryParams(query) || hasDebugQueryParams(query);
};

export const getUrlQuerySearchParams = query => {
  const urlParams = new URLSearchParams(query);
  return urlParams;
};
