<template>
  <div class="">
    <div
      class="alert"
      v-for="configuratorMessage in configuratorRuleMessages"
      :key="configuratorMessage.id"
      v-bind:class="{
        'alert-danger': configuratorMessage.severity === 'Error',
        'alert-light': configuratorMessage.severity === 'Recommened',
        'alert-info': configuratorMessage.severity === 'Info'
      }"
    >
      <div class="d-flex justify-content-between">
        <strong
          ><i
            class="fa"
            v-bind:class="{
              'fa-warning': configuratorMessage.severity === 'Error',
              'fa-bell': configuratorMessage.severity === 'Recommened',
              'fa-info': configuratorMessage.severity === 'Info'
            }"
          >
          </i>
          <strong
            ><span class="mx-1" v-html="configuratorMessage.title"></span
          ></strong>
        </strong>
        <div class="mb-1 mt-n1" v-if="configuratorMessage.hasResolveAction">
          <button
            class="btn btn-primary btn-sm pull-right pointer"
            v-on:click="resolveRuleAction(configuratorMessage)"
          >
            {{ getStaticText("resolve") }}
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>

      <div
        class="d-flex flex-column justify-content-between  info-message"
        role="alert"
      >
        <div class="">
          <!-- <strong><span v-html="configuratorMessage.title"></span></strong> -->
          <div class="alert-message-container">
            <p class="message-body">
              <span v-html="configuratorMessage.message"></span>
            </p>
          </div>
        </div>
        <div class="bg-primary" v-if="authenticated && configuratorDebugMode">
          {{ configuratorMessage.id }} - {{ configuratorMessage.ruleName }}
        </div>
       
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Alerts",
  computed: {
    ...mapGetters([
      "getStaticText",
      "configuratorRuleMessages",
      "authenticated",
      "configuratorDebugMode",
    ])
  },
  methods: {
    ...mapActions(["RESOLVE_CART_OPTIONS", "VALIDATE_CART_OPTIONS"]),

    resolveRuleAction: function(message) {

      this.RESOLVE_CART_OPTIONS(message);

      // Revalidate again after resolve
      // Some rules have similar actions
      this.VALIDATE_CART_OPTIONS();

      // this.$store.dispatch("resolveRuleAction", message, message);
    }
  }
};
</script>

<style scoped></style>
