import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//import VShowSlide from "v-show-slide";
import VuejsDialog from "vuejs-dialog";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import VueGtm from "@gtm-support/vue2-gtm";
import MobileMenu from "./plugins/MobileMenu";
import "./assets/sass/configurator.scss";

//import Keycloak from "keycloak-js";

//import authService from "@/services/auth";

Vue.config.productionTip = false;

Vue.use(MobileMenu);
/*
Vue.use(VueKeycloak, {
  init: {
    onLoad: "check-sso",
    checkLoginIframe: false
  },
  config: {
    realm: "aconity3d",
    url: "https://id.aconity3d.com/auth",
    clientId: "shop_frontend"
  },
});*/

Vue.use(VueGtm, {
  id: "GTM-N3W4K8T", // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether display console logs debugs (optional)
  loadScript: true, // Whether to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: ["homepage"], // Don't trigger events for specified router names (optional)
  trackOnNextTick: false,
});



//Vue.use(VShowSlide);
Vue.use(VuejsDialog);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

new Vue({
  router,
  store,
  async beforeCreate() {
    console.log("APP beforeCreate");
    //const authToken = authService.getAuthCookie();
    //console.log("authToken", authToken);
    //await store.dispatch("SET_AUTHINCATED", authToken);
  },
  /*   async beforeMount() {

  
  }, */
  mounted() {},
  render: (h) => h(App),
}).$mount("#app");
