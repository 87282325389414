<template>
  <li class="nav-item dropdown unitDropdownItem"
  v-bind:class="{ animated: !firstMachineSelection }">

    <div
      class="d-flex  align-items-baseline  justify-content-center dropdown-toggle"
      data-toggle="dropdown"
      id="navbarDropdownMenuLink"
      @click="toggleSelectUnit"
      @focus="handleFocus"
      @focusout="handleFocusOut"
      tabindex="0"
    >
      <span v-html="formatName(selectedMachine)"> </span>

      <span v-if="isToggleUnitDropdownOpen">
        <i class="fa fa-angle-up mx-2"></i>
      </span>
      <span v-else>
        <i class="fa fa-angle-down mx-2"></i>
      </span>
    </div>

    <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
      <div
        class="dropdown-item"
        v-for="(unit, index) in machineList"
        v-if="unit.name != selectedMachine.name"
        v-on:click="changeUnit(unit)"
        :key="index"
      >
        <a
          :href="'/' + selectedLanguage + '/units/' + unit.name"
          class="pointer machine-link"
          v-html="unit.displayName"
        ></a>
      </div>
    </div>
  </li>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SelectUnit",

  computed: {
    ...mapGetters(["selectedMachine", "machineList", "selectedLanguage", "firstMachineSelection"])
  },
  data() {
    return {
      isToggleUnitDropdownOpen: false,
      toggleUnitBtnInit: false,
    };
  },
  methods: {
    ...mapActions(["SET_CONFIGURATOR_SELECTED_MACHINE", "SET_CONFIGURATOR_FIRST_MACHINE_SELECTION"]),
    changeUnit(unit) {

      this.SET_CONFIGURATOR_SELECTED_MACHINE(unit);
    },

    toggleSelectUnit() {
      this.isToggleUnitDropdownOpen = !this.isToggleUnitDropdownOpen;
      this.toggleUnitBtnInit = true;

      this.SET_CONFIGURATOR_FIRST_MACHINE_SELECTION(true);
    },
    handleFocus() {
     
    },
    handleFocusOut() {
      this.isToggleUnitDropdownOpen = false;
    },

    formatName(unitName) {
      return unitName.displayName;
    }
  }
};
</script>
