export default {
  selectedMachineCategories: (state) => {
    console.log("selectedMachineCategories", state.selectedMachineCategories);
    return state.selectedMachineCategories;
  },
  selectedMachineCategoryOptions: (state) => {
    return state.selectedMachineCategoryOptions;
  },

  selectedCategoryOptions: (state) => {
    if (state.ready) {
      return state.selectedCategoryOptions;
    }
  },

  machineList: (state) => {
    return state.allMachines;
  },
  getDefaultMachine: (state) => {
    return state.defaultMachine;
  },

  configuratorDebugMode: (state) => {
    return state.configuratorDebugMode;
  },
  configuratorEditMode: (state) => {
    return state.configuratorEditMode;
  },
  configuratorEditDocumentData: (state) => {
    return state.configuratorEditDocument.customerData;
  },
  configuratorEditDocument: (state) => {
    return state.configuratorEditDocument;
  },
  getConfiguratorEditDocumentMachine: (state) => {
    const machineName = state.configuratorEditDocument.machineName;
    return state.allMachines.find((machine) => machine.name === machineName);
  },
  getSelectedMachineOptions: (state) => {
    return state.selectedMachineOptions;
  },
  categoryChangeLoading: (state) => {
    return state.categoryChangeLoading;
  },
  getAlternativeArticles: (state) => {
    return state.alternativeArticles;
  },
  getStaticText: (state, getters, rootState) => (name) => {
    if (!state.allStaticText) {
      return "## STATIC TEXT NOT LOADED ##";
    }
    const textblock = state.allStaticText.find((b) => b.name === name);

    if (textblock) {
      return textblock.text.find(
        (t) => t.lang === rootState.app.selectedLanguage
      )["value"];
    } else {
      return "## LANG ERROR ##";
    }
  },

  getStaticTextJson: (state) => (name) => {
    if (!state.allStaticText) {
      return "## STATIC JSON NOT LOADED ##";
    }
    let textblock = state.allStaticText.find((b) => b.name === name);

    if (typeof textblock !== "undefined") {
      return textblock.json.find((t) => t.lang === state.selectedLanguage)[
        "value"
      ];
    } else {
      return "## LANG ERROR ##";
    }
  },

  getText: (state, getters, rootState) => (text, columnname) => {
    if (!text) {
      return "#lang error#";
    }

    const selectedAppLang = rootState.app.selectedLanguage;

    if (typeof text === "string") {
      return text;
    } else {
      const textValue = text.find((text_) => text_.lang === selectedAppLang);
      if (textValue) {
        return textValue[columnname];
      } else {
        // console.log(text);
        return "## Text not found ##";
      }
    }
  },

  // Get machine option by option id
  getMachineOptionById: (state) => (id) => {
    if (!state.configuratorIsLoading) {
      const machineOption = state.selectedMachineOptions.find(
        (option) => option.id === id
      );

      return machineOption;
    }
  },

  selectedMachine: (state) => {
    if (!state.configuratorIsLoading) {
      return state.selectedMachine;
    }
  },

  firstMachineSelection: (state) => {
    if (!state.configuratorIsLoading) {
      const storedValue = sessionStorage.getItem("firstMachineSelected");

      if (storedValue) {
        return storedValue;
      }
      return false;
    }
  },
  selectedMachineCategory: (state) => {
    if (!state.configuratorIsLoading) {
      return state.selectedMachineCategory;
    } else {
      return {};
    }
  },

  configuratorRuleMessages: (state) => {
    if (!state.configuratorIsLoading) {
      return state.configuratorRuleMessages;
    } else {
      return [];
    }
  },
  /////////////////////////////////////////////////////////
  // CART GETTERS
  /////////////////////////////////////////////////////////
   /**
   * Return count of options for each type in cart
   * @param {*} articleId articleId of the option as in 10004575
   * @returns {number} count of options in cart
   */
   getMachineOptionQtyInCart: (state) => (articleId) => {
    let optionQtyCount = 0;

    const machineOption = state.cart.find(
      option => option.articleId === articleId
    );

    if (machineOption != undefined) {
      optionQtyCount = machineOption.orderQty;
    }

    return optionQtyCount;
  },
  getMultipleMachineOptionByArticleId: (state) => (articlesIds) => {
    return state.selectedMachineOptions.filter(option =>
      articlesIds.includes(option.articleUnitId)
    );
  },
  getConfiguratorAlertMessageLang: (state, getters, rootState) => (mesgContent) => {
 

    const selectedLang = rootState.app.selectedLanguage.toLowerCase();

    return typeof mesgContent !== "undefined"
      ? mesgContent[selectedLang]
      : "EN";
  },

   /**
   * Return count of options for each type in cart
   * @param {*} state
   */
   getArticleTypeQtyInCart: (state) => {
    let counts = {};

    state.cart.map(comp => {
      if (counts[comp.type]) {
        counts[comp.type] = counts[comp.type] + comp.orderQty;
      } else {
        counts[comp.type] = comp.orderQty;
      }
      return comp;
    });
    return counts;
  },

  cart: (state) => {
    if (!state.cartIsLoading) {
      return state.cart;
    } else {
      return [];
    }
  },
  isOptionInCart: (state) => (id) => {
    if (!state.cartIsLoading) {
      if (state.cart.some((item) => item.id === id)) {
        return true;
      } else return false;
    } else return false;
  },
   /**
   *
   * @param {*} articleId  articleId of the option as in 10004575
   * @returns {boolean} true if option is in cart
   */
  isMachineOptionInCart: (state) => (articleId) => {
    return state.cart.some(c => {
      return c.articleId === articleId;
    });
  },

  isCheckOutButtonsDisabled: (state) => {
    return state.cartButtonDisabled;
  },

  getOptionQtyInCart: (state) => (id) => {
    if (!state.cartIsLoading) {
      let item = state.cart.find((item) => item.id == id);

      if (item) {
        return item.orderQty;
      } else {
        return 0;
      }
    }
  },
  getCartOptionQty: (state) => (id) => {
    if (!state.cartIsLoading) {
      let item = state.cart.find((item) => item.id == id);
      if (item) {
        return item.orderQty;
      } else return 0;
    }
  },

  orderData: (state) => {
    return state.orderData;
  },
};
