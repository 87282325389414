<template>
  <div class="stage">
    <div class="container">
      <div v-if="this.$store.getters.ready == false" class="loading-wrapper">
        <loading></loading>
      </div>
      <transition name="fade">
        <div class="stagewrapper" v-if="this.$store.getters.ready == true">
          <template v-for="(unit, index) in cart">
            <layer
              v-if="unit.imageLayer"
              :key="index"
              :imagelayer="unit.imageLayer"
              :imagelayers="unit.imagelayers"
              :option="unit"
            >
            </layer>
          </template>
        </div>
      </transition>

      <div id="pictograms">
        <template v-for="(unit, index) in orderedCartItems">
          <div
            v-if="
              unit.pictogram &&
                unit.isHidden === false &&
                unit.inHiddenCategory === false
            "
            :key="index"
          >
            <img :src="unit.pictogram" class="img-fluid" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "@/components/system/LoadingAnimation.vue";
import Layer from "@/components/configurator/Layer.vue";
import { orderBy } from "@/utils/common-utils";

export default {
  name: "Stage",
  computed: {
    ...mapGetters(["cart"]),

    orderedCartItems: function() {
      return orderBy(this.cart, "sortOrder");
    }
  },
  components: {
    Loading,
    Layer
  }
};
</script>
