export default {
  authenticated: (state) => {
    return state.authenticated;
  },
  authToken: (state) => {
    return state.authToken;
  },
  init: (state) => {
    return state.init;
  },
  ready: (state) => {
    return state.ready;
  },

  getSessionData: (state) => {
    return state.session;
  },
  getGId: (state) => {
    return state.gid;
  },

  selectedLanguage: (state) => {
    return state.selectedLanguage;
  },
  getCountriesList: (state) => {
    return state.countriesList;
  },

  getMessageLangText: (state) => (text) => {
    if (state.ready) {
      const selectedLang = state.selectedLanguage.toLowerCase();

      if (typeof text !== "undefined") return text[selectedLang];
    }
  },

  /**
   *
   */
};
