<template>
  <div class="option-box mt-30">
    <div class="row">
      <!-- Option pictogram && thumgnail START -->

      <div class="col-3">
        <span v-if="option.orderQty == 0 || option.orderQty == 1">
          <img
            rel="preload"
            v-if="option.thumbnail"
            :src="option.thumbnail"
            class="img-fluid"
          />
          <img
            rel="preload"
            v-else-if="option.pictogram"
            :src="option.pictogram"
            class="img-fluid pictogram-thumb"
          />
        </span>

        <span v-if="option.orderQty > 1">
          <img
            rel="preload"
            v-if="option.thumbnailMulti"
            :src="option.thumbnailMulti"
            class="img-fluid"
          />
          <img
            rel="preload"
            v-else-if="option.pictogram"
            :src="option.pictogram"
            class="img-fluid pictogram-thumb"
          />
        </span>
      </div>
      <!-- Option pictogram && thumgnail END -->
      <div class="col-9">
        <!-- OPTION CHECKBOX -->
        <div class="checkbox float-right">
          <label
            class="form-check-label"
            :for="'option_' + option.id"
            style="font-size: 1.5em"
          >
            <input
              :disabled="option.isRequired"
              type="checkbox"
              v-on:change="toggleOptionSelection"
              :id="'option_' + option.id"
              :name="'option_' + option.id"
              :checked="isOptionInCart(option.id)"
              :data-option-id="option.id"
              :data-item-value="option.name"
            />
            <span class="cr">
              <i class="cr-icon fa fa-check"></i>
            </span>
          </label>
        </div>

        <!-- OPTION QTY DROPDOWN -->
        <div
          class="dropdown float-right option-count"
          v-if="option.allowOrderQty && !customOptionQty"
        >
          <button
            class="btn btn-primary btn-sm dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            :disabled="isOptionInCart(option.id) == false"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            v-if="selectedLanguage === 'DE'"
          >
            Menge: {{ getOptionQtyInCart(option.id) }}
            <!-- {{optionQty}} -->
          </button>

          <button
            class="btn btn-primary btn-sm dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            :disabled="isOptionInCart(option.id) === false"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            v-if="selectedLanguage === 'EN'"
          >
            Qty: {{ getOptionQtyInCart(option.id) }}
            <!-- {{optionQty}} -->
          </button>
          <ul
            class="dropdown-menu count-list"
            aria-labelledby="dropdownMenuButton"
          >
            <li
              class="count-item"
              v-for="index in option.maxQty"
              :key="index"
              v-on:click="toggleOptionSelectionQty(option, index)"
            >
              <a>{{ index }}</a>
            </li>
          </ul>
        </div>

        <h3 class="mt-10">
          <span v-html="getText(option.name, 'value')"></span>
        </h3>

        <div class="option-short-desc">
          <span v-html="getText(option.short_desc, 'value')"></span>
          <div v-if="getText(option.specs, 'value')">
            <p
              class="mt-10 btn btn-sm btn-primary pull-right readmore toggle-features"
              @click="toggleFeatures"
            >
              <span v-if="featuresOpen">
                {{ getStaticText("readless") }}
                <i class="fa fa-angle-up"></i>
              </span>
              <span v-else>
                {{ getStaticText("readmore") }}
                <i class="fa fa-angle-down"></i>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="featuresOpen">
      <div class="readmore-content" v-if="getText(option.specs, 'value')">
        <div class="row pt-20">
          <div class="col-3">
            <technical-data-image
              v-for="(image, index) in option.images"
              :image="image"
              :key="index"
            ></technical-data-image>
          </div>

          <div class="col-8">
            <div class="option-long-desc">
              <p>
                <strong>{{ getStaticText("technicalData") }}:</strong>
              </p>
              <span v-html="getText(option.specs, 'value')"></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex">
      <div>
        <small class="sku" v-if="!option.checkoutIgnored">{{
          option.articleUnitId
        }}</small>
      </div>

      <div
        class="mx-2 d-flex"
        v-if="(configuratorDebugMode || configuratorEditMode) && option.altArticles.length !== 0"
      >
        <div class="dropdown option-count">
          <button
            class="btn btn-primary btn-sm dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Substitution Article:
            {{ getOptionSelectedAlterntaiveArticleId(option) }}
          </button>
          <ul
            class="dropdown-menu alt-article-list"
            aria-labelledby="dropdownMenuButton"
          >
            <li
              class="alt-article-list-item"
              v-for="item in option.altArticles"
              :key="item.articleId"
              v-on:click="changeOptionAlterntiveArticle(option, item)"
            >
              <a>{{ item.articleId + " - " + item.title }}</a>
            </li>
          </ul>
        </div>
        <div class="">
          <button
            class="btn btn-danger btn-sm"
            type="button"
            id="clearArticle"
            v-on:click="clearOptionAlterntiveArticle(option)"
            v-if="option.altArticle"
          >
            X
          </button>
        </div>
      </div>
    </div>

    <!-- DEBUG <small class="sku">{{ option.sortOrder }}</small>  -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
//import { imagePreloader } from "vue-image-preloader";

import TechnicalDataImage from "@/components/configurator/TechnicalDataImage.vue";

export default {
  name: "SingleOption",
  components: {
    TechnicalDataImage
    // imagePreloader,
  },
  computed: {
    ...mapGetters([
      "isOptionInCart",
      "getMachineOptionById",
      "getCartOptionQty",
      "getOptionQtyInCart",
      "getStaticText",
      "getText",
      "cart",
      "selectedLanguage",
      "categoryChangeLoading",
      "configuratorEditMode",
      "configuratorDebugMode",
      "getAlternativeArticles"
    ])
  },
  data() {
    return {
      featuresOpen: false,
      optionQty: 0,
      imageURL: "",
      customOptionQty: false,
      isImageLoaded: false,
      imageRequested: false,
      optionAlterntivaArticles: []
    };
  },
  props: {
    option: Object
  },

  created() {
    this.optionQty = 1;
    //this.optionQty = this.getCartOptionQty(this.option.id);
  //  this.setOptionAltArticlesList();

  },

 

  loaded() {
   console.log("SingleOption - LOAD");
   console.log("SingleOption - configuratorEditMode", this.configuratorEditMode);
   console.log("SingleOption - configuratorDebugMode", this.configuratorDebugMode);
  },

  

  mounted() {
    this.updateImageAndPreload(this.option.pictogram_1);

   // console.warn('this.getAlternativeArticlesthis.getAlternativeArticlesthis.getAlternativeArticles', this.getAlternativeArticles)

  //  this.setOptionAltArticlesList();

    this.$root.$on("categoryChange", () => {
      this.isImageLoaded = false;
      this.imageRequested = false;
      this.updateImageAndPreload(this.option.pictogram_1);
    });
  },

  methods: {
    ...mapActions([
      "ADD_OPTION_TO_CART",
      "REMOVE_OPTION_FROM_CART",
      "VALIDATE_CART_OPTIONS",
      "CHANGE_OPTION_QTY",
      "CHANGE_OPTION_ALTERNATIVE_ARTICLE",
      "CLEAR_OPTION_ALTERNATIVE_ARTICLE"
    ]),
    updateImageAndPreload(url) {
      this.imageRequested = true;
      if (!url) {
        this.isImageLoaded = true;
        this.imageURL = "";
        return;
      }
      this.isImageLoaded = false;
      const img = new Image();
      img.src = url;
      /* eslint-disable func-names */
      img.onload = function() {
        this.imageURL = url;
        this.isImageLoaded = true;
      }.bind(this);
    },

    setOptionAltArticlesList() {
      const configuratorARticles = this.getAlternativeArticles;
     

      const optionAltArticles = this.option.altArticles || [];

      const optionAconityAtArticles = configuratorARticles.filter(article => {
        return optionAltArticles.includes(article.articleId);
      });


      this.optionAlterntivaArticles = [...optionAconityAtArticles];


      return this.optionAlterntivaArticles
    },

    getOptionQty(option) {
      console.log("getOptionQty");
      let count = 0;
      this.cart.forEach(cartItem => {
        if (cartItem.allowOrderQty && cartItem.id == option.id) {
          count = cartItem.orderQty;
        }
      });
      return count;
    },

    getOptionSelectedAlterntaiveArticleId(option) {
      return option.altArticle;
    },

    toggleFeatures() {
      this.featuresOpen = !this.featuresOpen;
    },
    toggleOptionSelection(event) {
      console.groupCollapsed("singleOptionComponent toggleOptionSelection()");

      const option = this.getMachineOptionById(
        ~~event.target.attributes["data-option-id"].value
      );

      console.log("selectedOption", option);
      console.groupEnd();

      // ADD TO CART
      if (event.target.checked) {
        this.ADD_OPTION_TO_CART(option);
        this.VALIDATE_CART_OPTIONS();

        // REMOVE FROM CART
      } else {
        this.REMOVE_OPTION_FROM_CART(option);
        this.VALIDATE_CART_OPTIONS();
      }

      this.$forceUpdate();
    },

    //
    onOptionQtyInputEnter() {
      this.changeCount(this.option, this.optionQty);
      this.toggleUpdateOptionQtyInput();
    },

    toggleUpdateOptionQtyInput() {
      this.optionQty = this.getOptionQtyInCart(this.option.id);
      this.customOptionQty = !this.customOptionQty;
    },

    changeOptionAlterntiveArticle(option, aconityArticle) {
      console.log("changeOptionAlterntiveArticle", option, aconityArticle);

      const altArticleId = aconityArticle.articleId;

      this.CHANGE_OPTION_ALTERNATIVE_ARTICLE({ option, altArticleId });

      this.VALIDATE_CART_OPTIONS();
    },

    clearOptionAlterntiveArticle(option) {
      console.log("clearOptionAlterntiveArticle", option);

      this.CLEAR_OPTION_ALTERNATIVE_ARTICLE({ option });

      this.VALIDATE_CART_OPTIONS();
    },
    toggleOptionSelectionQty(option, count) {
      this.CHANGE_OPTION_QTY({ option, count });

      this.$forceUpdate();
      this.$root.$emit("cartChange");

      this.VALIDATE_CART_OPTIONS();
    },

    changeCount(option, count) {
      if (count === 0) {
        this.REMOVE_OPTION_FROM_CART(option);
      } else if (count > 50) {
        count = 50;
        let payload = { option: option, count: count };
        this.CHANGE_OPTION_QTY(payload);
      } else {
        let payload = { option: option, count: count };
        this.CHANGE_OPTION_QTY(payload);
      }

      this.$forceUpdate();
      this.$root.$emit("cartChange");

      this.VALIDATE_CART_OPTIONS();
    }
  }
};
</script>
