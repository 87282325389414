import store from "../store";

const storeUtils = {
  isLoggedIn: () => {
    const authenticated = store.getters.authenticated;
    return authenticated;
  },

  isDebugMode: () => {

    return store.getters.configuratorDebugMode
    
  },
  isEditMode: () => {
    return store.getters.configuratorEditMode

  },

  /**
   *
   * @param {*} articleId  articleId of the option as in 10004575
   * @returns {boolean} true if option is in cart
   */
  /* isMachineOptionInCart: articleId => {
    return store.getters.cart.some(c => {
      return c.articleId === articleId;
    });
  }, */
  storeCountriesList: () => {
    return store.getters.getCountriesList;
  },

  getAcontiyArticles: () => {

    return store.getters.getAlternativeArticles

  },

 

  getMachineOptionByArticleId: articleId => {
    return store.getters.getSelectedMachineOptions.find(
      option => option.articleId == articleId
    );
  },
  getMachineOptionByArticleUnitid: articleUnitId => {
    return store.getters.getSelectedMachineOptions.find(
      option => option.articleUnitId == articleUnitId
    );
  },
  getMachineOptionByid: machineOptionId => {
    return store.getters.getSelectedMachineOptions.find(
      option => option.id == machineOptionId
    );
  },
 

  /* getConfiguratorAlertMessageLang: mesgContent => {
    const selectedLang = store.getters.selectedLanguage.toLowerCase();

    return typeof mesgContent !== "undefined"
      ? mesgContent[selectedLang]
      : "EN";
  }, */

  getMultipleMachineOptionByArticleId: articlesIds => {
    return store.getters.getSelectedMachineOptions.filter(option =>
      articlesIds.includes(option.articleUnitId)
    );
  }
};

/* getOptionQtyInCart: (state, articleId) =>{
  let optionQtyCount = 0;

  const machineOption = state.cart.find(option => option.articleId === articleId);

  if (machineOption != undefined) {
    optionQtyCount = machineOption.orderQty;
  }

  return optionQtyCount;
} */

/* isOptionInCart: (state, articleId) => {

  return state.cart.some((c) => {
    return c.articleId === articleId;
  });
} */

/* getCartIds: (state) => {
  let ids = [];
  if (state.cart) {
    state.cart.forEach((item) => {
      ids.push(item.id);
    });
  }
  return ids;
}
 */

export default storeUtils;
