import state from "./appState";
import mutations from "./appMutations";
import getters from "./appGetters";
import actions from "./appActions";

export default {
  state,
  mutations,
  getters,
  actions,
};
