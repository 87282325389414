<template>
  <Configurator></Configurator>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import Configurator from "../components/configurator/Configurator.vue";
/* import Stage from "../components/configurator/Stage.vue";
import SelectCategory from "../components/configurator/SelectCategory.vue";
import ShowCategoryOptions from "../components/configurator/ShowCategoryOptions.vue";
import Cart from "../components/configurator/Cart.vue"; */

import { getUrlQuerySearchParams } from "@/services/util";

export default {
  props: ["showCheckout"],
  name: "ConfiguratorView",
  components: {
    Configurator,
    /* Stage,
    SelectCategory,
    ShowCategoryOptions,
    Cart */
  },
  data() {
    return {
      currentConfiguratorMachine: null,
    };
  },
  computed: {
    ...mapGetters([
      "selectedLanguage",
      "getDefaultMachine",
      "machineList",
      "configuratorEditMode",
      "authenticated",
    ]),
    ...mapState(["init", "ready"]),
  },
  methods: {
    ...mapActions([
      "SET_READY",
      "TOGGLE_CONFIGURATOR_MODE",
      "SET_CONFIGURATOR_SELECTED_MACHINE",
      "LOAD_SELECTED_MACHINE_OPTIONS",
      "ADD_DEFAULT_OPTIONS_TO_CART",
      "LOAD_CONFIGURATOR_EDITOR_DOCUMENT",
      "ADD_CONFIGURATOR_EDIT_OPTIONS_TO_CART",
      "CLEAR_CART",
      "SELECT_MACHINE_CATEGORY",
      "VALIDATE_CART_OPTIONS",
    ]),

    setConfigurator() {
      console.warn("setConfigurator()");

      // Set selected defualt machine
      this.SET_CONFIGURATOR_SELECTED_MACHINE(
        this.currentConfiguratorMachine.name
      ).then(() => {
        this.LOAD_SELECTED_MACHINE_OPTIONS()
          .then(async () => {
            if (this.configuratorEditMode) {
              console.warn("EDIT MODE IS LAODED WILL LOAD FROM DOCUMENT");
              // ADD all articles from loaded configurator document to cart and run validation

              this.ADD_CONFIGURATOR_EDIT_OPTIONS_TO_CART();
            } else {
              console.warn("EDIT MODE IS NOT LAODED WILL LOAD DEFAULT");
              this.ADD_DEFAULT_OPTIONS_TO_CART();
            }
          })
          .then(() => {
            this.SELECT_MACHINE_CATEGORY();
            this.SET_READY(true);
            this.TOGGLE_CONFIGURATOR_MODE(true);
            this.VALIDATE_CART_OPTIONS();
            this.$forceUpdate();
          });
      });
    },
    setupConfiguratorMachine() {
      console.warn("setupConfiguratorMachine()");
      console.warn("this.$route.params.unit", this.$route.params.unit);

      const configuratorLang = this.selectedLanguage;
      const defaultMachine = this.$store.getters.getDefaultMachine;

      const requestedMachineExisit = this.machineList.find(
        (machine) => machine.name === this.$route.params.unit
      );

      this.currentConfiguratorMachine = this.configuratorEditMode
        ? this.$store.getters.getConfiguratorEditDocumentMachine
        : this.$route.params.unit === undefined
        ? defaultMachine
        : requestedMachineExisit;

      if (
        this.$route.path !==
        `/${configuratorLang}/units/${this.currentConfiguratorMachine.name}`
      ) {
        this.$router.push({
          path: `/${configuratorLang}/units/${this.currentConfiguratorMachine.name}`,
          query: { ...this.$route.query },
        });
      }
    },
  },

  async created() {
    console.warn("---------------- created() --------------------");

    if (this.configuratorEditMode && this.authenticated) {
      console.warn(
        "FOUND QUERY PARAMS In route will check if edit and debug mode is requested"
      );

      const queryEditParams = getUrlQuerySearchParams(this.$route.query);

      const requestId = queryEditParams.get("requestId");
      const documentId = queryEditParams.get("doc");

      await this.LOAD_CONFIGURATOR_EDITOR_DOCUMENT({
        requestId: requestId,
        documentId: documentId,
      });

      // LOAD
    }

    this.setupConfiguratorMachine();

    this.setConfigurator();
  },
};
</script>
