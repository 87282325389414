export default {
  configuratorIsLoading: false,
  isInConfiguratorMode: false,
  configuratorDebugMode: false,
  configuratorEditMode: false,

  // SELECTED MACHINE DATA
  firstMachineSelected: false,
  defaultMachine: {},
  selectedMachine: {},
  selectedMachineCategory: {},
  selectedMachineCategories: [],
  selectedMachineOptions: [],
  selectedMachineCategoryOptions: [],
  selectedMachineImageLayers: [],
  selectedMachineConfigurationRules: [],
  configuratorRuleMessages: [],
  configuratorEditDocument: {},
  categoryChangeLoading: false,
  
  // ALL MACHINE DATA
  allMachines: [],
  allCategories: [],
  allArticleTypes: [],
  allStaticText: [],
  alternativeArticles: [],


  // CART DATA
  submitted: false,
  isInCheckoutMode: false,
  cartIsLoading: false,
  cartItems: {
    added: [],
    deleted: [],
  },
  cart: [],
  cartValidated: [],
  cartButtonDisabled: false,
  orderData: {},
  //deletedCartItems: [],
};
