<template>
  <div>
    <img
      :src="image.url"
      class="img-fluid pointer"
      @click="openTechnicalDateModal"
      alt=""
    />
    <sweet-modal ref="modal" overlay-theme="dark"
      ><img :src="image.url" class="img-fluid pointer" alt=""
    /></sweet-modal>
  </div>
</template>

<script>
import { SweetModal } from "sweet-modal-vue";
export default {
  name: "SingleTechnicalCataImage",
  props: {
    image: Object,
  },
  components: {
    SweetModal,
  },
  methods: {
    openTechnicalDateModal() {
      this.$refs.modal.open();
    },
  },
};
</script>
