<template>
  <div class="site app-wrapper">
    <websiteHeader></websiteHeader>
    <router-view />
    <websiteFooter></websiteFooter>
  </div>
</template>

<script>
import websiteHeader from "@/components/layout/Header.vue";
import websiteFooter from "@/components/layout/Footer.vue";
export default {
  name: "layout",
  components: {
    websiteHeader,
    websiteFooter,
  },
};
</script>

<style lang="scss">
@import "src/scss/configurator_variables";
@import "src/scss/configurator";
</style>
