<template>
  <div id="cart">
    <transition name="fade">
      <div>
        <div
          id="ministage"
          v-if="this.$router.currentRoute.name !== 'checkoutView'"
        >
          <mini-stage></mini-stage>
        </div>

        <div class="scrolling-cart-container con-scrollable mb-2">
          <div class="scroll-area  mb-2">
            <div class="bg-light-gray">
              <div class="d-flex" @click="toggleCollapse()">
                <h6
                  class="p-10"
                  v-if="this.$router.currentRoute.name !== 'checkoutView'"
                >
                  {{ getStaticText("curconf") }}
                </h6>

                <span v-if="!confHidden">
                  <i class="fa fa-angle-up mt-2 pt-1"></i>
                </span>
                <span v-else>
                  <i class="fa fa-angle-down  mt-2 pt-1"></i>
                </span>
              </div>

              <ul
                class="scrolling-cart con-scrollable"
                :style="{ display: confHidden ? 'none' : 'block' }"
                id="sidebar-products"
                ref="sidebar"
              >
                <li
                  v-for="item in orderedCartItems"
                  :key="item.id"
                  v-if="showHiddenContent((item))"
                
                  v-bind:style= "[item.altArticle ? {backgroundColor: '#1f1f1f'} : null]"
                >
                  <img
                    class="card-item-pictogram"
                    :src="item.pictogram"
                    v-if="item.pictogram"
                    alt=""
                  />
                  <div class="card-item-qty">
                    {{ getCartOptionQty(item.id) }}
                  </div>
                  <img
                    class="card-item-qty-icon"
                    src="@/assets/images/close.svg"
                  />
                  <div
                    class="card-item-text"
                    v-html="getText(item.name, 'value')"
                  ></div>
                </li>
              </ul>
            </div>
          </div>

          <div class="scrolling-alert mt-3 con-scrollabl">
            <alerts
              v-if="
                configuratorRuleMessages.length > 0 &&
                  this.$router.currentRoute.name !== 'checkoutView'
              "
            >
            </alerts>
          </div>
        </div>

        <button
          v-if="this.$router.currentRoute.name !== 'checkoutView'"
          @click="goToCheckout"
          class="btn btn-primary btn-block"
          v-bind:class="{ disabled: isCheckoutDisabled }"
          :disabled="isCheckoutDisabled"
        >
          <i class="fa fa-shopping-cart"></i>
          {{ configuratorEditMode ? "Update" : getStaticText("getoffer") }}
        </button>
      </div>
    </transition>

    <div class="ph-item" v-if="this.$store.getters.ready == false">
      <div class="ph-col-12">
        <div class="ph-picture" style="height: 600px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Alerts from "@/components/configurator/Alerts.vue";
import MiniStage from "@/components/configurator/Stage.vue";
import router from "../../router";
import { orderBy } from "@/utils/common-utils";

import {  showAreaHiddenContent } from "@/utils/common-utils";


export default {
  name: "Cart",
  computed: {
    ...mapGetters([
      "getStaticText",
      "getCartOptionQty",
      "cart",
      "configuratorRuleMessages",
      "getText",
      "isCheckOutButtonsDisabled",
      "configuratorEditMode",
      "getGId",
    ]),

    orderedCartItems: function() {
      return orderBy(this.cart, "sortOrder");
    },

    isCheckoutDisabled: function() {
      return this.isCheckOutButtonsDisabled;
    }
  },
  components: {
    Alerts,
    MiniStage
  },

  created() {
    this.gtmId = this.getGId;
  },

  mounted() {
    this.$root.$on("cartChange", () => {
      this.$forceUpdate();
    });
  },
  data() {
    return {
      confHidden: false,
      gtmId: null,
    };
  },
  methods: {
    ...mapActions([
      "SELECT_MACHINE_CATEGORY",
      "TOGGLE_CONFIGURATOR_MODE",
      "VALIDATE_CART_OPTIONS",
      "TOGGLE_CHECKOUT_MODE"
    ]),

    showHiddenContent: function (option) {
      return showAreaHiddenContent('cart', option.inHiddenCategory);
    },

    

    toggleCollapse() {
      this.confHidden = !this.confHidden;
    },

    goToCheckout() {
      let language = this.$store.getters.selectedLanguage;

      if (this.$store.getters.configuratorRuleMessages.length > 0) {
        let options = {
          okText: this.getStaticText("yes"),
          cancelText: this.getStaticText("no")
        };

        this.$dialog
          .confirm(this.getStaticText("confirmorder"), options)
          .then(function() {
            router.push("/" + language + "/checkout");
          });

        /* this.$gtm.trackEvent({
          event: "checkout",
          eventCategory: "checkout",
          eventAction: "send_order",
          eventLabel: "Send order to Aconity3D",
          eventValue: this.gtmId
        
        });
         */
       /*  this.$ga.event(
          "Checkout",
          "send_order",
          "Send order to Aconity3D",
          123
        );
 */
      } else {
        // tODO: Add GTM event
      /*   this.$gtm.trackEvent({
          event: "checkout",
          eventCategory: "checkout",
          eventAction: "send_order",
          eventLabel: "Send order to Aconity3D",
          eventValue: this.gtmId
        
        }); */
        /* this.$ga.event(
          "Checkout",
          "send_order",
          "Send order to Aconity3D",
          123
        ); */

        router.push("/" + language + "/checkout");
      }

      // Change to checkout mode
      this.TOGGLE_CONFIGURATOR_MODE(false);
      this.TOGGLE_CHECKOUT_MODE(true);

      // ReCheck the rules again to excute the rules that are not executed by the first check
      // Rules that needs to be checked in checkout stage
      this.VALIDATE_CART_OPTIONS();
    },

    forceRefresh() {
      this.$forceUpdate();
    }
  }
};
</script>
