<template>
    <div class="wrap site__main" role="document">
      <div class="content">
        <div id="configurator" class="main_page_wrapper">
          <div class="mainstage">
            <stage></stage>
          </div>
          <SelectCategory></SelectCategory>
          <div class="container mt-10">
            <div class="row">
              <div class="col-12 col-lg-8">
                <ShowCategoryOptions></ShowCategoryOptions>
              </div>
              <div
                class="col-12 col-lg-4 col-xl-4 p-0 mt-30 configurator-sidebar"
              >
                <cart></cart>
              </div>
            </div>
          </div>
        </div>
        <!-- /.main -->
      </div>
      <!-- /.content -->
    </div>
    <!-- /.wrap -->
  </template>



<script>

import Stage from "./Stage.vue";
import SelectCategory from "./SelectCategory.vue";
import ShowCategoryOptions from "./ShowCategoryOptions.vue";
import Cart from "./Cart.vue";


export default {
  props: ["showCheckout"],
  name: "Configurator",
  components: {
    Stage,
    SelectCategory,
    ShowCategoryOptions,
    Cart
  },



}
</script>