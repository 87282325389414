<template>
  <div class="mb-30">
    <transition name="fade">
      <div class="category-options" v-if="this.$store.getters.ready == true">
        <div
          v-for="(mainoption, index) in orderedOptions"
          :key="index"
          :id="mainoption.id"
        >
          <single-option
            v-if="showHiddenContent(mainoption)"
            :option="mainoption"
            :key="index"
          >
          </single-option>
        </div>
      </div>


    </transition>
    <div class="ph-item" v-if="this.$store.getters.ready == false">
      <div class="ph-col-12">
        <div class="ph-picture p-100 mt-30 mb-0"></div>
        <div class="ph-picture p-100 mt-30 mb-0"></div>
        <div class="ph-picture p-100 mt-30 mb-0"></div>
        <div class="ph-picture p-100 mt-30 mb-0"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SingleOption from "@/components/configurator/SingleOption.vue";
import { orderBy, showAreaHiddenContent } from "@/utils/common-utils";



export default {
  name: "ShowCategoryOptions",
  computed: {
    ...mapGetters([
      "selectedCategoryOptions",
      "selectedMachineCategoryOptions",
      "configuratorEditMode",
    ]),

    orderedOptions: function () {
      return orderBy(this.selectedMachineCategoryOptions, "sortOrder");
    },

   
  },
  components: {
    SingleOption,
  },
 

  methods: {

    showHiddenContent: function (option) {
      return showAreaHiddenContent('category', option.isHidden);
    },
  
  },
};
</script>
