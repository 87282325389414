import Keycloak from "keycloak-js";

export default function setup() {
  let initOptions = {
    url: "https://id.aconity3d.com/auth",
    realm: "aconity3d",
    clientId: "shop_frontend",
    onLoad: "check-sso"
  };

  const keycloak = Keycloak(initOptions);

  keycloak
    .init({ onLoad: initOptions.onLoad })
    .then(auth => {
      if (auth) {
        console.log("Authorized");
        localStorage.setItem(
          "auth._refresh_token.keycloak",
          keycloak.refreshToken
        );
        localStorage.setItem(
          "auth._refresh_token_expiration.keycloak",
          keycloak.refreshTokenParsed.exp * 1000
        );
        localStorage.setItem(
          "auth._token.keycloak",
          "Bearer " + keycloak.token
        );
        localStorage.setItem(
          "auth._token_expiration.keycloak",
          keycloak.tokenParsed.exp * 1000
        );
        localStorage.setItem(
          "auth.keycloak.pkce_state",
          keycloak.sessionId
        )
      } else {
        console.log("Not Authorized");
      }
      //Token Refresh
      setInterval(() => {
        keycloak
          .updateToken(70)
          .then(refreshed => {
            if (refreshed) {
              console.info("Token refreshed" + refreshed);
            } else {
              console.info(
                "Token not refreshed, valid for " +
                  Math.round(
                    keycloak.tokenParsed.exp +
                      keycloak.timeSkew -
                      new Date().getTime() / 1000
                  ) +
                  " seconds"
              );
            }
          })
          .catch(() => {
            console.info("Failed to refresh token");
          });
      }, 6000);
    })
    .catch(() => {
      console.info("Authenticated Failed");
    });

  return keycloak;
}
