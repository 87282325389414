import _ from "lodash";
import storeUtils from "@/utils/store-utils";

export const isNullUndefined = value => value === null || value === undefined;

export const visibilityConditions = {
  'category': (debugMode, editMode, isHidden) => {
    if (debugMode) return true; // Always show if debugMode is on
    if (editMode) return !isHidden; // If editMode, show based on isHidden
    return !isHidden; // Default to not show
  },
  'cart': (debugMode, editMode, isHidden) => {
    if (debugMode) return true; // Always show if debugMode is on
    if (editMode) return !isHidden; // If editMode, show based on isHidden
    return !isHidden; // Default to not show
  },
  // Add other categories as needed
};

export const getCookieByName = (cookieName) => {
  let match = document.cookie.match("(?:^|;)\\s*_ga=([^;]*)");
  const raw = match ? decodeURIComponent(match[1]) : null;
  if (raw) {
    match = raw.match(/(\d+\.\d+)$/);
  }
  let g_client_id = match ? match[1] : null;
  if (g_client_id) {
    console.log("g_client_id", g_client_id);
  }

  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
};
export const getGoogleGTMClientId = () => {
  let match = document.cookie.match("(?:^|;)\\s*_ga=([^;]*)");
  const raw = match ? decodeURIComponent(match[1]) : null;
  if (raw) {
    match = raw.match(/(\d+\.\d+)$/);
  }
  const g_client_id = match ? match[1] : null;

    return g_client_id
};

export const sortBy = (array, key) => {
  return _.sortBy(array, key);
};

export const orderBy = (array, key) => {
  return _.orderBy(array, key);
};

export const getTodayDateFormatted = () => {
  const todayDate = new Date();
  const formartedTodayDate =
    todayDate.getDate() +
    "." +
    (todayDate.getMonth() + 1) +
    "." +
    todayDate.getFullYear();

  return formartedTodayDate;
};

export const showAreaHiddenContent = (area, optionIsHidden) => {

  // REFACTOR this it should be if editMode true return true othrerise return isHiddenContent

  const isDebugMode = storeUtils.isDebugMode()
  const isEditMode = storeUtils.isEditMode()


   // Rule 1: If debug mode is on, always show content
   if (isDebugMode) {
    return true;
  }


  if(visibilityConditions[area]){

    return visibilityConditions[area](isDebugMode, isEditMode, optionIsHidden)
  }



  return false




}