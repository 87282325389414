<template>
  <!--[if IE]>
    <div class="alert alert-warning">
      Ihr Browser ist <strong>veraltet</strong>. Bitte
      <a href="//browsehappy.com/">aktualisieren Sie Ihren Browser</a>, um diese
      Website korrekt dazustellen.
    </div>
  <![endif]-->
  <header class="header header--page site__header">
    <nav class="header__container">
      <a :href="getSiteLink('/')" aria-current="page" class="site__info link">
        <img :src="logoLink" alt="Aconity3D logo" class="site__logo" />
      </a>
      <ul class="header-menu header__menu header-menu--page header-menu--ls">
        <li
          class="header-menu__item"
          v-for="item in menu.primary_1"
          :key="item.id"
        >
          <a
            v-if="item.external"
            target="_blank"
            :href="item.to"
            class="header-menu__link link"
          >
            {{ getLang(`header.menu.${item.id}.label`) }}
          </a>
          <div v-else-if="item.hasOwnProperty('submenu')">
            <dropdown-menu mode="hover" class="v-dropdown-menu">
              <button slot="trigger" class="header-menu__link">
                {{ getLang(`header.menu.${item.id}.label`) }}
              </button>
              <ul slot="body">
                <li
                  v-for="subMenuItem in item.submenu"
                  :key="subMenuItem.id"
                  class="header-submenu__item"
                >
                  <a
                    v-if="subMenuItem.external"
                    :href="subMenuItem.to"
                    target="_blank"
                    class="header-menu__link"
                    >{{ getLang(`header.menu.${subMenuItem.id}.label`) }}</a
                  >
                  <a
                    v-else
                    :href="getSiteLink(subMenuItem.to)"
                    class="header-menu__link link"
                  >
                    {{ getLang(`header.menu.${subMenuItem.id}.label`) }}
                  </a>
                </li>
              </ul>
            </dropdown-menu>
          </div>
          <a
            v-else-if="item.external"
            :href="item.to"
            class="header-menu__link link"
          >
            {{ getLang(`header.menu.${item.id}.label`) }}
          </a>
          <a v-else :href="getSiteLink(item.to)" class="header-menu__link link">
            {{ getLang(`header.menu.${item.id}.label`) }}
          </a>
        </li>
      </ul>
      <ul
        v-mobile-menu
        class="header-menu header__menu header-menu--page header-menu--ss"
      >
        <li
          class="header-menu__item"
          v-for="item in menu.primary_1_mobile"
          :key="item.id"
        >
          <a :href="getSiteLink(item.to)" class="header-menu__link link">{{getLang(`header.menu.${item.id}.label`)}}</a>
        </li>
        <li v-show="!isLoggedIn" class="header-submenu__item">
          <button class="header__cta-button cta cta--sm" @click="login()">
            {{ getLang("header.login") }}
          </button>
        </li>
      </ul>
      <div class="site__langswitcher langswitcher">
        <span class="langswitcher__icon">
          <svg class="symbol symbol--language"
            width="20"
            height="20"
            version="1.1"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m9.99 0c-5.52 0-9.99 4.48-9.99 10s4.47 10 9.99 10c5.53 0 10.01-4.48 10.01-10s-4.48-10-10.01-10zm6.93 6h-2.95c-0.32-1.25-0.78-2.45-1.38-3.56 1.84 0.63 3.37 1.91 4.33 3.56zm-6.92-3.96c0.83 1.2 1.48 2.53 1.91 3.96h-3.82c0.43-1.43 1.08-2.76 1.91-3.96zm-7.74 9.96c-0.16-0.64-0.26-1.31-0.26-2s0.1-1.36 0.26-2h3.38c-0.08 0.66-0.14 1.32-0.14 2s0.06 1.34 0.14 2zm0.82 2h2.95c0.32 1.25 0.78 2.45 1.38 3.56-1.84-0.63-3.37-1.9-4.33-3.56zm2.95-8h-2.95c0.96-1.66 2.49-2.93 4.33-3.56-0.6 1.11-1.06 2.31-1.38 3.56zm3.97 11.96c-0.83-1.2-1.48-2.53-1.91-3.96h3.82c-0.43 1.43-1.08 2.76-1.91 3.96zm2.34-5.96h-4.68c-0.09-0.66-0.16-1.32-0.16-2s0.07-1.35 0.16-2h4.68c0.09 0.65 0.16 1.32 0.16 2s-0.07 1.34-0.16 2zm0.25 5.56c0.6-1.11 1.06-2.31 1.38-3.56h2.95c-0.96 1.65-2.49 2.93-4.33 3.56zm1.77-5.56c0.08-0.66 0.14-1.32 0.14-2s-0.06-1.34-0.14-2h3.38c0.16 0.64 0.26 1.31 0.26 2s-0.1 1.36-0.26 2z"
              fill="#fff"
            />
          </svg>
        </span>
        <dropdown-menu class="v-dropdown-menu">
          <button slot="trigger" class="header-menu__link">
            {{ currentLanguage() }}</button
          >
          <ul slot="body">
            <li
              @click="changeLanguage('DE')"
              :class="langActiveClasses('DE')"
              >German</li>
            <li @click="changeLanguage('EN')" :class="langActiveClasses('EN')">
              English</li>
          </ul>
        </dropdown-menu>
        <span class="langswitcher__icon" style="margin-top: 0.5em">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.76375 6.56382C4.93253 6.3951 5.1614 6.30031 5.40005 6.30031C5.6387 6.30031 5.86758 6.3951 6.03635 6.56382L9.00005 9.52752L11.9638 6.56382C12.0468 6.47786 12.1461 6.4093 12.2559 6.36213C12.3657 6.31496 12.4838 6.29013 12.6033 6.2891C12.7228 6.28806 12.8413 6.31083 12.9519 6.35608C13.0625 6.40133 13.163 6.46816 13.2475 6.55267C13.332 6.63717 13.3988 6.73766 13.4441 6.84826C13.4893 6.95887 13.5121 7.07738 13.5111 7.19688C13.51 7.31638 13.4852 7.43448 13.438 7.54429C13.3909 7.65409 13.3223 7.7534 13.2364 7.83642L9.63635 11.4364C9.46758 11.6051 9.2387 11.6999 9.00005 11.6999C8.7614 11.6999 8.53253 11.6051 8.36375 11.4364L4.76375 7.83642C4.59503 7.66765 4.50024 7.43877 4.50024 7.20012C4.50024 6.96147 4.59503 6.7326 4.76375 6.56382Z"
              fill="#9CA3AF"
            />
          </svg>
        </span>
      </div>
      <div>
        <div v-show="isLoggedIn" class="user-menu">
          <dropdown-menu mode="hover">
            <button slot="trigger" class="header-menu__link"><img src="@/assets/images/login.png" /> <span>{{ username }}</span></button>
            <ul slot="body">
              <li v-if="showAdminLinks" class="header-submenu__item">
                <a href="https://shop.aconity3d.com/agent/manage-products" target="_blank" class="header-menu__link">{{ getLang(`header.user.articles.label`) }}</a>
              </li>
              <li v-if="showAdminLinks" class="header-submenu__item">
                <a href="https://shop.aconity3d.com/agent/manage-agents" target="_blank" class="header-menu__link">{{ getLang(`header.user.agents.label`) }}</a>
              </li>
              <li v-if="showAdminLinks" class="header-submenu__item">
                <a href="https://shop.aconity3d.com/agent/manage-customers" target="_blank" class="header-menu__link">{{ getLang(`header.user.customers.label`) }}</a>
              </li>
              <li v-if="showAdminLinks" class="header-submenu__item">
                <a href="https://shop.aconity3d.com/agent/manage-shipping-costs" target="_blank" class="header-menu__link">{{ getLang(`header.user.shipping_costs.label`) }}</a>
              </li>
              <li v-if="showAdminLinks" class="header-submenu__item">
                <a href="https://shop.aconity3d.com/agent/global-information" target="_blank" class="header-menu__link">{{ getLang(`header.user.global_info.label`) }}</a>
              </li>
              <li class="header-submenu__item">
                <a href="http://support.aconity3d.com/" target="_blank" class="header-menu__link">{{ getLang(`header.user.support.label`) }}</a>
              </li>
              <li class="header-submenu__item">
                <a href="#" class="header-menu__link logout" @click="logout()">{{ getLang(`header.user.logout.label`) }}</a>
              </li>
            </ul>
          </dropdown-menu>
          <span class="langswitcher__icon">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.76375 6.56382C4.93253 6.3951 5.1614 6.30031 5.40005 6.30031C5.6387 6.30031 5.86758 6.3951 6.03635 6.56382L9.00005 9.52752L11.9638 6.56382C12.0468 6.47786 12.1461 6.4093 12.2559 6.36213C12.3657 6.31496 12.4838 6.29013 12.6033 6.2891C12.7228 6.28806 12.8413 6.31083 12.9519 6.35608C13.0625 6.40133 13.163 6.46816 13.2475 6.55267C13.332 6.63717 13.3988 6.73766 13.4441 6.84826C13.4893 6.95887 13.5121 7.07738 13.5111 7.19688C13.51 7.31638 13.4852 7.43448 13.438 7.54429C13.3909 7.65409 13.3223 7.7534 13.2364 7.83642L9.63635 11.4364C9.46758 11.6051 9.2387 11.6999 9.00005 11.6999C8.7614 11.6999 8.53253 11.6051 8.36375 11.4364L4.76375 7.83642C4.59503 7.66765 4.50024 7.43877 4.50024 7.20012C4.50024 6.96147 4.59503 6.7326 4.76375 6.56382Z"
                  fill="#9CA3AF"
              />
            </svg>
          </span>
        </div>
        <button v-show="!isLoggedIn" class="header__cta-button cta cta--sm" @click="login()">
          {{ getLang("header.login") }}
        </button>
      </div>
      <button v-mobile-menu-toggle type="button" class="header__menu-toggle">
        <span class="header__menu-toggle-box">
          <span class="header__menu-toggle-inner"> </span>
        </span>
      </button>
    </nav>
  </header>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import DropdownMenu from "v-dropdown-menu";
import AconityMixin from "../../mixins/Aconity3D";
import MobileMenuMixin from "../../mixins/mobileMenu";
import router from "../../router";
import keycloakSetup from "../../auth/keycloak";
import axios from "axios";

export default {
  name: "Header",
  mixins: [AconityMixin, MobileMenuMixin],
  data() {
    return {
      kc: null,
      token: null,
      me: null,
      showAdminLinks: true
    };
  },
  components: {
    DropdownMenu
  },
  computed: {
    ...mapGetters(["ready", "selectedLanguage", "selectedMachine"]),
    username() {
      return this.me ? this.me.name : "";
    },
    isLoggedIn() {
      return (this.me !== null);
    },
  },
  mounted() {
    this.kc = keycloakSetup();
    console.log(this.kc.userInfo);
    let token = localStorage.getItem("auth._token.keycloak");
    if(token !== 'false') {
      this.token = token
      axios(`https://shop.aconity3d.com/api/me`, {
        headers:{
          Authorization: this.token
        }
      }).then(({ data }) => {
        this.me = data
        this.showAdminLinks = this.me.roles.includes("agent");
      })
    }
  },
  methods: {
    ...mapActions(["SET_LANG"]),

    langActiveClasses(iso) {
      return { "langswitcher__item--active": this.locale === iso };
    },

    logout() {
      this.kc.logout();
    },

    login() {
      window.location.href = this.kc.createLoginUrl();
    },

    currentLanguage() {
      return this.locale;
    },

    changeLanguage: function(iso) {
      this.SET_LANG(iso);
      switch (this.$router.currentRoute.name) {
        case "configurator":
          router.push("/" + iso + "/units/" + this.selectedMachine.name);
          break;
        case "checkout":
          router.push("/" + iso + "/checkout");
          break;
        case "thankyou":
          router.push("/" + iso + "/thankyou");
          break;
      }
    }
  }
};
</script>
<style>
@media screen and (max-width: 776px) {
  .header__container > div > .header__cta-button.cta {
    display: none;
  }
}
.header-submenu__item > .header__cta-button.cta {
  position: inherit;
  margin-left: 0;
}
</style>
