import { apiService, httpService } from "@/services/http";

export default {
  SET_LANG({ commit }, data) {
    commit("SET_LANG", data);
  },
  SET_G_CLIENT_ID({ commit }, data) {
    commit("SET_G_CLIENT_ID", data);
  },

  SET_AUTHINCATED({ commit }, userToken) {
    commit("SET_AUTHINCATED", userToken);
  },

  async LOGIN({ commit }, userCred) {
    await apiService.post("/login", userCred).then((loginRes) => {
      if (loginRes.status === 200) {
        console.log("Recieved loginRes: ", loginRes);

        if (loginRes.data.access_token) {
          commit("SET_AUTHINCATED", loginRes.data.access_token);
        }
      }
    });
  },

  SET_READY({ commit }, readyState) {
    commit("SET_READY", readyState);
  },
  SET_INIT({ commit }, init) {
    commit("SET_INIT", init);
  },

  async preInit(state) {
    console.log("preInit", state);

    await apiService.get("/assets/countries").then((countriesRes) => {
      if (countriesRes.status === 200) {
        console.warn("Recieved countriesRes: ", countriesRes);
        state.commit("SET_COUNTRY_LIST", countriesRes.data.data);
      }
    });

    await httpService
      .get("https://www.cloudflare.com/cdn-cgi/trace", {
        responseType: "text",
        transformResponse: (data) =>
          Object.fromEntries(
            data
              .trim()
              .split("\n")
              .map((line) => line.split("="))
          ),
      })
      .then(async (ipJson) => {
        console.log("Recieved ipJson: ", ipJson);

        if (ipJson.status === 200) {
          const ip = ipJson.data.ip;
          const loc = ipJson.data.loc;

          const countriesList = state.getters.getCountriesList;
          const currentLocData = countriesList.find(
            (country) => country.cca2 === loc
          );

          console.warn("currentLocData: ", currentLocData);

          state.commit("SET_CURRENT_SESSION_DATA", {
            ipAddress: ip,
            loc: currentLocData ? currentLocData.cca2 : null,
            locData: currentLocData, // will be null if not found followed no geo targeting
          });
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        state.commit("SET_CURRENT_SESSION_DATA", {
          ipAddress: "",
          loc: false,
          locData: null,
        });
      });
  },
};
