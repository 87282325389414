<template>
  <div class="wrap" role="document">
    <div class="content">
      <main class="main" id="configurator">
        <div class="container mt-10">
          <div class="row">
            <div class="col-12 col-lg-12 my-50">
              <h2>{{ getStaticText("request_send") }}</h2>

              <div v-html="getStaticText('thankyou')"></div>

              <img
                src="@/assets/images/logo.png"
                alt="Aconity3d"
                style="width: 150px"
              />
            </div>
          </div>
        </div>
      </main>
      <!-- /.main -->
    </div>
    <!-- /.content -->
  </div>
  <!-- /.wrap -->
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ThankYouView",
  computed: {
    ...mapGetters(["getStaticText"]),
  },
};
</script>
