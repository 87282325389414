<template>
  <div class="site app-wrapper">
    <Layout></Layout>
  </div>
</template>

<script>
import Layout from "@/views/layout/Layout.vue";
import { mapActions, mapGetters } from "vuex";

import {  getGoogleGTMClientId } from "../utils/common-utils";

export default {
  name: "AppMainView",
  components: {
    Layout
  },
  computed: {
    ...mapGetters(["machineList", "getDefaultMachine"])
  },
  methods: {
    ...mapActions(["SET_LANG", "SET_G_CLIENT_ID"])
  },
  data() {
    return {
      googleGtmId: null,
    };
  },
  created() {

    this.googleGtmId = getGoogleGTMClientId()

    console.warn("GOOGLE GTM ID", this.googleGtmId)

    this.SET_G_CLIENT_ID(this.googleGtmId)

    if (!this.$store.getters.ready) {

      const userLang = navigator.language || navigator.userLanguage;

      // Set language if requested
      if (this.$route.params.lang !== undefined) {
        const lang = this.$route.params.lang;

        this.SET_LANG(lang.toUpperCase());

        // Otherwise read use browser env
      } else {
        if (userLang === "en-DE") {
          this.SET_LANG("DE");
        } else {
          this.SET_LANG("EN");
        }
      }

    }
  },

  
};
</script>
