import Vue from "vue";
import VueRouter from "vue-router";
import ConfiguratorView from "../views/ConfiguratorView.vue";
import CheckoutView from "../views/CheckoutView.vue";
import ThankYouView from "../views/ThankYouView.vue";
import AppMainView from "../views/AppMainView.vue";
import store from "../store";
import authService from "@/services/auth";
import {
  routeRequireAuth,
  hasDebugQueryParams,
  routeHasQueryParams,
  hasEditQueryParams,
} from "@/services/util";

Vue.use(VueRouter);

//

const routes = [
  {
    path: "/",
    name: "entry",
    redirect: { name: "configuratorView" },
  },
  {
    path: "",
    component: AppMainView,
    redirect: "",
    children: [
      {
        path: ":lang/units/:unit",
        name: "configuratorView",
        component: ConfiguratorView,
        props: (route) => ({ query: route.query.q }),
        beforeEnter: async (to, from, next) => {

          // TEMPORARY till we move to new edit view
          if (routeHasQueryParams(to)) {
            console.warn("DEBUG MODE IS REQUESTED");
            await store.dispatch("SET_CONFIGURATOR_EDIT_MODE", {
              editMode:  hasEditQueryParams(to.query),
              debugMode: hasDebugQueryParams(to.query),
            });
          }

          next();
        },
      },
      {
        path: ":lang/checkout",
        name: "checkoutView",
        component: CheckoutView,
        beforeEnter: async (to, from, next) => {
          const appIsInit = store.getters.init;
          console.log("appIsInit", appIsInit);
          next();
        },
      },
      {
        path: ":lang/thankyou",
        name: "thankYouView",
        component: ThankYouView,
      },
      {
        path: ":lang/editor",
        name: "configuratorEditorView",
        component: () =>
          import(
            /* webpackChunkName: "ConfiguratorEditorView" */ "../views/ConfiguratorEditorView"
          ),
        props: (route) => ({ query: route.query.q }),
        beforeEnter: async (to, from, next) => {
          console.warn("sdfsdfsd", to.query);

          if (routeHasQueryParams(to)) {
            console.warn("DEBUG MODE IS REQUESTED");
            await store.dispatch("SET_CONFIGURATOR_EDIT_MODE", {
              editMode: hasEditQueryParams(to.query),
              debugMode: hasDebugQueryParams(to.query),
            });
          }

          next();
        },
      },
      {
        path: "login",
        name: "LoginView",
        component: () =>
          import(/* webpackChunkName: "LoginView" */ "../views/LoginView.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve(async (to, from, next) => {
  console.log("router.beforeResolve");
  console.log("to", to);
  console.log("from", from);

  const appIsInit = store.getters.init;

  if (!appIsInit) {
    console.warn('######################################### INITIALIZING APP #########################################')
    await store.dispatch("preInit");
    await store.dispatch("initConfigurator");
    await store.dispatch("SET_INIT", true);
    console.warn('######################################### DONE INITIALIZING APP #########################################')


    next();
  } else {
    next();
  }

  if (
    (to.name === "configuratorView" || to.name === "configuratorEditorView") &&
    routeRequireAuth(to.query)
  ) {
    if (authService.isLoggedIn()) {
      next();
    } else {
      // router.push({ name: "login" });
      next({ path: "/Login" });
    }
  } else {
    next();
  }
});

export default router;
