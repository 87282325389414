import { apiService } from "@/services/http";

import configuratorRuleService from "@/services/configurator-rule-service";
import storeUtils from "@/utils/store-utils";

export default {
  SET_DEFAULT_MACHINE({ commit }) {
    commit("SET_DEFAULT_MACHINE");
  },

  SELECT_MACHINE_CATEGORY({ commit }, categoryId) {
    commit("SELECT_MACHINE_CATEGORY", categoryId);
  },
  /**
   * Set the configurator selected machine
   * @param {*} param0
   * @param {*} machine
   */
  SET_CONFIGURATOR_SELECTED_MACHINE({ state, commit, rootState }, machine) {
    commit("SET_CONFIGURATOR_SELECTED_MACHINE", {
      state: state,
      rootState: rootState,
      machine: machine,
    });
  },
  SET_CONFIGURATOR_FIRST_MACHINE_SELECTION(
    { state, commit, rootState },
    value
  ) {
    commit("SET_CONFIGURATOR_FIRST_MACHINE_SELECTION", {
      state: state,
      rootState: rootState,
      value: value,
    });
  },

  TOGGLE_CONFIGURATOR_MODE({ state, commit, rootState }, value) {
    commit("TOGGLE_CONFIGURATOR_MODE", {
      state: state,
      rootState: rootState,
      value: value,
    });
  },
  async SET_CONFIGURATOR_EDIT_MODE({ commit, dispatch }, payload) {
    commit("SET_CONFIGURATOR_EDIT_MODE", payload);
    // Always load the configurator editor data in both edit and debug mode
    await dispatch("LOAD_CONFIGURATOR_EDIT_DATA");
  },

  async initConfigurator(state) {
    console.log("initConfigurator", state);

    await apiService.get("/machines").then((machinesRes) => {
      console.log("Recieved machinesRes: ", machinesRes);

      if (machinesRes.status === 200) {
        state.commit("SET_MACHINES", {
          machines: machinesRes.data.data,
        });
        state.commit("SET_DEFAULT_MACHINE");
      }
    });

    Promise.allSettled([
      apiService.get("/staticText"),
      apiService.get("/categories"),
      apiService.get("/articleTypes"),
    ]).then((results) => {
      const staticTextData =
        results[0].status === "fulfilled" ? results[0].value.data.data : [];
      const categoriesData =
        results[1].status === "fulfilled" ? results[1].value.data.data : [];
      const articleTypesData =
        results[2].status === "fulfilled" ? results[2].value.data.data : [];

      state.commit("SET_DATA_STATICTEXT", {
        statictexts: staticTextData,
      });

      state.commit("SET_DATA_CATEGORIES", {
        categories: categoriesData,
      });

      state.commit("SET_DATA_ARTICLE_TYPES", {
        types: articleTypesData,
      });
    });

    return true;
  },

  /**
   * Load all options for the current selected machine
   * @param {*} param0
   */
  async LOAD_MACHINE_OPTIONS({ state, commit, rootState }) {
    await Promise.allSettled([
      apiService.get("/machineOptions/machine/" + state.selectedMachine.id),
      apiService.get("/machineStageLayers/machine/" + state.selectedMachine.id),
    ]).then((results) => {
      console.log(results);

      const selectedMachineOptionsData =
        results[0].status === "fulfilled" ? results[0].value.data.data : [];
      const selectedMachineImageLayersData =
        results[1].status === "fulfilled" ? results[1].value.data.data : [];

      commit("SET_SELECTED_MACHINE_OPTIONS", {
        state: state,
        rootState: rootState,
        machineOptions: selectedMachineOptionsData,
        imageLayers: selectedMachineImageLayersData,
      });
    });
  },
  async LOAD_CONFIGURATOR_EDITOR_DOCUMENT(
    { state, commit, rootState, dispatch },
    payload
  ) {
    console.log("LOAD_CONFIGURATOR_EDITOR_DOCUMENT");

    const { requestId, documentId } = payload;
    console.log("documentId ", documentId);
    console.log("requestId ", requestId);

    return await Promise.allSettled([
      apiService.get("/orders/inquiries/" + documentId),
    ]).then((results) => {
      const inquiry = results[0].status === "fulfilled" ? results[0].value : {};

      console.log("Recieved inquiry: ", inquiry);

      const inquiryData = inquiry.data.data;

      const inquiryMachine = inquiryData.systemType;

      commit("SET_CONFIGURATOR_DOCUMENT", {
        state: state,
        rootState: rootState,
        document: inquiryData,
        requestId: requestId,
      });

      dispatch("SET_CONFIGURATOR_SELECTED_MACHINE", inquiryMachine);
    });
  },
  async LOAD_CONFIGURATOR_EDIT_DATA({ commit }) {
    console.log("LOAD_CONFIGURATOR_EDIT_DATA");

    return await Promise.allSettled([
      apiService.get("/configurator/articles"),
      /* apiService.get("/configurator/modules"), */
    ]).then((results) => {
      const configuratorArticles =
        results[0].status === "fulfilled" ? results[0].value.data : [];

      console.warn("Recieved configuratorArticles: ", configuratorArticles);

      commit("SET_ALTERNATIVE_ARTICLES", configuratorArticles);
    });
  },

  async LOAD_MACHINE_CONFIGURATION_RULES({ state, commit, rootState }) {
    console.log("LOAD_MACHINE_CONFIGURATION_RULES");
    console.log(state.selectedMachine);

    await apiService
      .get("/configurationRules/machine/" + state.selectedMachine.id)
      .then((machineConfigurationRulesRes) => {
        console.log(
          "Recieved machineConfigurationRulesRes: ",
          machineConfigurationRulesRes
        );

        if (machineConfigurationRulesRes.status === 200) {
          commit("SET_SELECTED_MACHINE_CONFIGURATION_RULES", {
            state: state,
            rootState: rootState,
            configurationRules: machineConfigurationRulesRes.data.data,
          });
        }
      });
  },

  /**
   *
   * @param {*} param0
   */
  async LOAD_SELECTED_MACHINE_OPTIONS({ dispatch }) {
    console.log("LOAD_SELECTED_MACHINE_OPTIONS");

    await dispatch("LOAD_MACHINE_OPTIONS");
    await dispatch("LOAD_MACHINE_CONFIGURATION_RULES");
  },

  /////////////////////////////////////////
  /// CART ACTIONS
  /////////////////////////////////////////
  async ADD_DEFAULT_OPTIONS_TO_CART({ dispatch }) {
    console.log("ADD_DEFAULT_OPTIONS_TO_CART");

    await dispatch("ADD_MACHINE_DEFAULT_OPTION_TO_CART");
  },

  TOGGLE_CHECKOUT_MODE({ commit }, value) {
    commit("TOGGLE_CHECKOUT_MODE", value);
  },

  ADD_OPTION_TO_CART({ commit }, option) {
    commit("ADD_OPTION_TO_CART", option);
  },
  REMOVE_OPTION_FROM_CART({ commit }, option) {
    commit("REMOVE_OPTION_FROM_CART", option);
  },
  CLEAR_CART({ commit }) {
    commit("CLEAR_CART");
  },

  ADD_MACHINE_DEFAULT_OPTION_TO_CART({ state, commit, rootState }) {
    commit("ADD_MACHINE_DEFAULT_OPTION_TO_CART", {
      state: state,
      rootState: rootState,
    });
  },

  ADD_CONFIGURATOR_EDIT_OPTIONS_TO_CART({ state, commit, rootState, getters  }) {
    commit("ADD_CONFIGURATOR_EDIT_OPTIONS_TO_CART", {
      state: state,
      rootState: rootState,
      getters: getters,
    });
  },

  CHANGE_OPTION_QTY({ commit }, payload) {
    commit("CHANGE_OPTION_QTY", payload);
  },
  CHANGE_OPTION_ALTERNATIVE_ARTICLE({ commit }, payload) {
    commit("CHANGE_OPTION_ALTERNATIVE_ARTICLE", payload);
  },
  CLEAR_OPTION_ALTERNATIVE_ARTICLE({ commit }, payload) {
    commit("CLEAR_OPTION_ALTERNATIVE_ARTICLE", payload);
  },

  SET_orderData({ commit }, value) {
    commit("SET_orderData", value);
  },

  VALIDATE_CART_OPTIONS({ state, commit, getters, rootState }) {
    const selectedMachineConfigurationRules = state.selectedMachineConfigurationRules;

    const isInCheckoutMode = state.isInCheckoutMode;

    const allArticleTypes = state.allArticleTypes;

    const articleTypeQtyInCart = getters.getArticleTypeQtyInCart;
    const isOptionInCartFn = getters.isMachineOptionInCart;
    const getMachineOptionQtyInCartFn = getters.getMachineOptionQtyInCart;
    const getAlertMsgLangFn = getters.getConfiguratorAlertMessageLang;

    const getMachineOptionByArticleIdFn = storeUtils.getMachineOptionByArticleId;

    const clearRuleActionMsgFn = () =>
      commit("CLEAR_RULE_VALIDATE_ACTION_MESSAGE");

    const addRuleActionMsgFn = (msg) =>
      commit("ADD_RULE_VALIDATE_ACTION_MESSAGE", msg);

    const changeMachineOptionQtyInCartFn = (payload) =>
      commit("CHANGE_OPTION_QTY_IN_CART", payload);

    const addOrChangeMachineOptionQtyInCartFn = (payload) =>
      commit("ADD_OR_CHANGE_OPTION_QTY_TO_CART", payload);

    const removeMachineOptionFromCartFn = (payload) =>
      commit("REMOVE_OPTION_FROM_CART", payload);
    const decreaseMachineOptionQtyInCartFn = (payload) =>
      commit("DECREASE_OPTION_QTY_IN_CART", payload);

    const appSessionData = rootState.app.session;

    const facts = {
      articleTypesQty: articleTypeQtyInCart,
      articleTypes: allArticleTypes,
      clearRuleOutputActionMessageFn: clearRuleActionMsgFn,
      removeMachineOptionFn: removeMachineOptionFromCartFn,
      decreaseMachineOptionQtyFn: decreaseMachineOptionQtyInCartFn,
      addOrChangeMachineOptionFn: addOrChangeMachineOptionQtyInCartFn,
      changeMachineOptionQtyFn: changeMachineOptionQtyInCartFn,
      // isInCheckoutMode: isInCheckoutMode,
      getMachineOptionByArticleId: getMachineOptionByArticleIdFn,
      isOptionInCartFn: isOptionInCartFn,
      getMachineOptionQtyInCartFn: getMachineOptionQtyInCartFn,
      getAlertMsgLangFn: getAlertMsgLangFn,
      addRuleActionMsg: addRuleActionMsgFn,
    };

    configuratorRuleService.runRules(
      selectedMachineConfigurationRules,
      appSessionData,
      isOptionInCartFn,
      getMachineOptionQtyInCartFn,
      clearRuleActionMsgFn,
      addRuleActionMsgFn,
      changeMachineOptionQtyInCartFn,
      addOrChangeMachineOptionQtyInCartFn,
      removeMachineOptionFromCartFn,
      decreaseMachineOptionQtyInCartFn,
      getMachineOptionByArticleIdFn,
      isInCheckoutMode,
      articleTypeQtyInCart,
      getAlertMsgLangFn,
      allArticleTypes,
      facts
    );
  },

  TOGGLE_CART_SUBMIT_READY({ state, commit }, readyState) {
    console.log("state", state);

    commit("TOGGLE_CART_SUBMIT_READY", readyState);
  },

  // Resolve rule action
  RESOLVE_CART_OPTIONS({ state, commit }, message) {
    console.warn("RESOLVE_CART_OPTIONS", message);

    const isInCheckoutMode = state.isInCheckoutMode;

    const ruleActions = message.resolveActions;

    const getMachineOptionByArticleIdFn =
      storeUtils.getMachineOptionByArticleId;

    const addOrChangeMachineOptionQtyInCartFn = (payload) =>
      commit("ADD_OR_CHANGE_OPTION_QTY_TO_CART", payload);

    const removeMachineOptionFromCartFn = (payload) =>
      commit("REMOVE_OPTION_FROM_CART", payload);
    const decreaseMachineOptionQtyInCartFn = (payload) =>
      commit("DECREASE_OPTION_QTY_IN_CART", payload);

    if (ruleActions !== undefined) {
      ruleActions.forEach(async (ruleAction) => {
        await configuratorRuleService.ruleActionResolver(
          isInCheckoutMode,
          ruleAction,
          addOrChangeMachineOptionQtyInCartFn,
          removeMachineOptionFromCartFn,
          decreaseMachineOptionQtyInCartFn,
          getMachineOptionByArticleIdFn
        );
      });
    }

    commit("REMOVE_RULE_VALIDATE_ACTION_MESSAGE", message.id);
  },

  SUBMIT_ORDER({ state, commit, rootState }, orderData) {
    commit("SUBMIT_ORDER", {
      state: state,
      rootState: rootState,
      orderData: orderData,
    });
  },
};
