import Cookies from "js-cookie";

import store from "../store";

const AUTH_TOKEN_KEY = "loginToken";

const auth = {
  isLoggedIn: () => {
    const authenticated = store.getters.authenticated;
    return authenticated;
  },

  setAuthCookie: (value, expires) => {
    return Cookies.set(AUTH_TOKEN_KEY, value, {
      expires: expires,
      sameSite: "strict"
    });
  },

  getAuthCookie: () => {
    return JSON.parse(Cookies.get(AUTH_TOKEN_KEY));
  },

  clearAuthCookie: () => {
    return Cookies.remove("user");
  },
};

export default auth;
