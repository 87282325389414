/* eslint-disable prettier/prettier */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-undef */
/*! js-cookie v3.0.1 | MIT */

import eventEmitterInstance from './configurator-rule-em'
import { isNullUndefined } from './common-utils'

const configuratorRuleEngine = {
  eventEmitter: eventEmitterInstance,
  /* validateRule: (options: {}) => {}, */

  filterGeoTargetRules: (rule, session) => {
    if (
      rule.is_geo_target === undefined ||
      rule.is_geo_target === false ||
      rule.geo_target == null ||
      rule.geo_target_type == null
    ) {
      return true;
    }

    const currentLoc = session.loc ? session.loc : "";
    const currentLocContinents =
      session.locData && session.locData.continents
        ? session.locData.continents
        : [];

    let isGeoTarget = false;

    if (rule.geo_target_type === "continent") {
      const currentCountryContinent = currentLocContinents[0]
        ? currentLocContinents[0]
        : "";

      isGeoTarget = rule.geo_target.some(
        (cont) => cont.toLowerCase() == currentCountryContinent.toLowerCase()
      );
    } else if (rule.geo_target_type === "country") {
      isGeoTarget = rule.geo_target.some(
        (ruleLoc) => ruleLoc.toLowerCase() == currentLoc.toLowerCase()
      );
    }

    return isGeoTarget;
  },

  buildInputConditionsTree: (ruleInputs) => { 
    console.groupCollapsed("buildInputConditionsTree()");
    console.log("ruleInputs :", ruleInputs);

    const ruleConditions = [];

    let subCondition = [];

    ruleInputs.forEach((ruleInput, inputIndex) => {
      if (ruleInput.level === 1) {
        subCondition.push(ruleInput);
      } else if (ruleInput.level === 0) {
        console.log("subCondition: ", subCondition);
        console.log("END of Sub Condition");

        ruleConditions.push([...subCondition]);

        // Add Condition Operator
        ruleConditions.push(ruleInput);

        // Clear Sub Condition arr
        subCondition = [];
      }

      if (inputIndex === ruleInputs.length - 1) {
        // Add Condition Operator
        ruleConditions.push([...subCondition]);
      }
    });

    console.groupEnd();
    return ruleConditions;
  },

  evaluateRuleInput: {
    "!=": (a, b) => a != b,
    "==": (a, b) => a == b,
    "||": (a, b) => a || b,
    "&&": (a, b) => a && b,
    ">": (a, b) => a > b,
    ">=": (a, b) => a >= b,
    "<": (a, b) => a < b,
    "<=": (a, b) => a <= b,
  },

  /**
   * Check a givin rule input against the cart
   * If input is not yet in cart it will evaluate to false
   * if input is in cart it will evaluate to the condition specified in input
   * @param {*} input
   * @returns boolean - true if input condition passes otherwise false
   */
  checkRuleInput: (
    ruleInput,
    articleTypes,
    optionInCartFn,
    machineOptionQtyInCartFn,
    articleTypeQtyInCart
  ) => {
    console.groupCollapsed("checkRuleInput()");
    console.log(ruleInput);

    /** Handle undefined */

    if (isNullUndefined(ruleInput)){
      console.error("ruleInput is undefined");
      console.groupEnd();
      return false;
    }

    /** Rule*/
    const { inputQty, inputType, inputOperator } = ruleInput;

    if (isNullUndefined(inputQty) || isNullUndefined(inputType) || isNullUndefined(inputOperator)) {
      console.error("one of the input attr is null or undefined");
      console.groupEnd();
      return false;
    }


    /** Cart State */
    let isInCart = false;
    let inputQtyInCart = 0;

    if (ruleInput.inputType == "articleType") {
      const inputType = articleTypes.find((type) => type.id == ruleInput.input);

      isInCart = articleTypeQtyInCart.hasOwnProperty(inputType.type);
      inputQtyInCart =
        articleTypeQtyInCart[inputType.type] !== undefined
          ? articleTypeQtyInCart[inputType.type]
          : 0;

      console.log(
        `Input ${ruleInput.inputType}: ${inputType.type} Qty in Cart ${inputQtyInCart}`
      );
    } else if (ruleInput.inputType == "machineOption") {
      isInCart = optionInCartFn(ruleInput.input);
      console.log("isInCart", isInCart);
      //isInCart = isOptionInCart(state, ruleInput.input);
      inputQtyInCart = machineOptionQtyInCartFn(ruleInput.input);
      /*   inputQtyInCart = getOptionQtyInCart(
                       state,
                       ruleInput.input
                     ); */

      console.log(
        `Input ${ruleInput.inputType}:  Qty in Cart ${inputQtyInCart}`
      );
    }

    console.log("inputQty", inputQty);
    console.log("inputOperator", inputOperator);
    console.log("isInCart", isInCart);
    console.log("inputQtyInCart", inputQtyInCart);

    // Input is not in cart not selected yet
    /*   if(isInCart === false) {

            console.groupEnd();
            return undefined;
        }  */

    console.log(
      "checkRuleInput Result ",
      configuratorRuleEngine.evaluateRuleInput[inputOperator](
        inputQtyInCart,
        inputQty
      )
    );

    console.groupEnd();
    return configuratorRuleEngine.evaluateRuleInput[inputOperator](
      inputQtyInCart,
      inputQty
    );
  },
  executeRuleMessage: (rule, getAlertMsgLangFn, addRuleActionMsgFn) => {
    console.groupCollapsed("executeRuleMessage()");

    /** Rule output message & actions*/
    const ruleSeverity = rule.severity;

    /** Rule output message & actions*/

    const showRuleOutputMessage = rule.show_output_message;

    console.log("showRuleOutputMessage", showRuleOutputMessage);

    /** RULE MESSAGE */

    if (showRuleOutputMessage) {
      const message = getAlertMsgLangFn(rule.output_message);
      console.log("message", message);

      if (message) {
        addRuleActionMsgFn({
          id: rule.id,
          ruleName: rule.name,
          type: "RecommenedRuleCheck",
          title: message && message.title ? message.title : "",
          message: message && message.body ? message.body : "",
          severity: ruleSeverity,
          hasResolveAction: rule.action.length !== 0 ? true : false,
          resolveActions: rule.action,
        });
        /* commit("ADD_RULE_VALIDATE_ACTION_MESSAGE", {
                         id: rule.id,
                         type: "RecommenedRuleCheck",
                         title: message && message.title ? message.title : "",
                         message: message && message.body ? message.body : "",
                         severity: ruleSeverity,
                         hasResolveAction:
                           rule.action.length !== 0 ? true : false,
                         resolveActions: rule.action,
                       }); */
      }
    }

    console.groupEnd();
  },
  executeRuleAction: (
    rule,
    rulePassed,
    isInCheckoutMode,
    addOrChangeMachineOptionQtyInCartFn,
    removeMachineOptionFromCartFn,
    decreaseMachineOptionQtyInCartFn,
    getMachineOptionByArticleIdFn
  ) => {
    console.groupCollapsed("executeRuleAction()");

    console.log("rulePassed :", rulePassed);

    /** RULE ACTION */

    const executeActionOn = JSON.parse(rule.execute_action_on);
    const showOutputMessage = JSON.parse(rule.show_output_message);

    // console.log('executeActionOn', executeActionOn);

    // ONLY EXECUTE ACTION IF RULE NOT PASSED and rule has not message
    if (showOutputMessage === false && rulePassed === false) {
      /** Auto resolve all actions marked with auto_resolve */
      const ruleAutoResolveAction = rule.action.filter(
        (action) => action.auto_resolve === true
      );

      ruleAutoResolveAction.forEach((ruleAction) => {
        console.log("ruleAction", ruleAction);
        configuratorRuleEngine.ruleActionResolver(
          isInCheckoutMode,
          ruleAction,
          addOrChangeMachineOptionQtyInCartFn,
          removeMachineOptionFromCartFn,
          decreaseMachineOptionQtyInCartFn,
          getMachineOptionByArticleIdFn
        );
      });
    }
    console.groupEnd();
    return;

    /// if(rulePassed === executeActionOn) {

    ///  /** Auto resolve all actions marked with auto_resolve */
    ///  const ruleAutoResolveAction = rule.action.filter(action => action.auto_resolve === true);

    ///  ruleAutoResolveAction.forEach(ruleAction => {

    ///      ruleActionResolver(rootState, commit, ruleAction)
    ///
    ///  });

    ///

    /// onsole.groupEnd()
  },

  ruleActionResolver: (
    checkoutMode,
    ruleAction,
    addOrChangeMachineOptionQtyInCartFn,
    removeMachineOptionFromCartFn,
    decreaseMachineOptionQtyInCartFn,
    getMachineOptionByArticleIdFn
  ) => {
    console.groupCollapsed("resolveRuleAction - START");
    console.log("ruleAction", ruleAction);

    const isInCheckoutMode = checkoutMode;

    if (ruleAction.is_active) {
      console.log("RESOLVE RULE ACTION", ruleAction);
      const option = getMachineOptionByArticleIdFn(ruleAction.action);
      /*   const option = getMachineOptionByArticleId(
                     rootState,
                     ruleAction.action
                   ); */
      //  const isHidden = option.isHidden; // Can be changed later

      console.log("Action Option: ", option);
      // console.log("Option Article ID: ", option.articleUnitId);

      // Check if we still in configurator mode and if the option  hidden
      // Some rules actions require to be executed in checkout mode after the configurator view is left

      if (isInCheckoutMode === false && ruleAction.execute_checkout === true) {
        console.warn("Option action only in checkout mode");
        console.groupEnd();
        return;
      }

      if (ruleAction.action_type.toLowerCase() == "add") {
        if (option) {
          console.log("ADD Option: ", option.name[0].value);
          // commit("ADD_OPTION_TO_CART", option);
          addOrChangeMachineOptionQtyInCartFn({
            option,
            optionQty: ruleAction.action_qty,
          });
          /* commit("ADD_OR_CHANGE_OPTION_QTY_TO_CART", {
          option,
          optionQty: ruleAction.action_qty
        }); */
        }
      } else if (ruleAction.action_type.toLowerCase() == "remove") {
        if (option) {
          console.log("REMOVE Option: ", option.name[0].value);
          removeMachineOptionFromCartFn(option);
          /* commit("REMOVE_OPTION_FROM_CART", option); */
        }
      } else if (ruleAction.action_type.toLowerCase() == "decrease") {
        console.log("DECREASE Option: ", option.name[0].value);

        decreaseMachineOptionQtyInCartFn({
          option,
          optionQty: ruleAction.action_qty,
        });
        /* commit("DECREASE_OPTION_QTY_IN_CART", {
        option,
        optionQty: ruleAction.action_qty
      }); */
      }
    }

    console.groupEnd();
  },
  evaluateRuleInputCondition: {
    "&&": (left, right) => left && right,
    "||": (left, right) => left || right,
    xor: (arr) => arr.reduce((prev, curr) => prev ^ curr),
  },
  evaluateRuleCondition: {
    "&&": (arr, value) => arr.every((item) => item === value),
    "||": (arr, value) => arr.some((item) => item === value),
    //"&&": (arr) => arr.every(item => item === arr[0]),
    //"||": (arr) => arr.some(item => item === arr[0]),
    xor: (arr) => arr.reduce((prev, curr) => prev ^ curr),
  },
  evilCondition: (fn)  => {
    return new Function("return " + fn)();
  },


  filterActiveConditionRules: (rules, sessionData) => {

    return rules.filter((rule) =>  (
      rule.input !== null &&
      (rule.rule_type == "condition" || rule.rule_type == null) &&
      sessionData ? configuratorRuleEngine.filterGeoTargetRules(rule, sessionData) : true
     /*  configuratorRuleEngine.filterGeoTargetRules(rule, sessionData) */
    ));

  },

  filterActiveLinkedConditionRules: (rules, sessionData) => { 

    return rules.filter((rule) =>   
    rule.rule_type == "linkedCondition" && 
    rule.input_linked !== null  &&
    sessionData ? configuratorRuleEngine.filterGeoTargetRules(rule, sessionData) : true
    );

  },
};

export default configuratorRuleEngine;
