// prettier-ignore
/* eslint-disable */
import configuratorRuleEngine from "../utils/configurator-rule-engine";
//import configuratorRuleEngine from "@aconity3d/configurator-rule-engine";




const configuratorRuleService = {
  eventEmitter: configuratorRuleEngine.eventEmitter,

    initializeEventSubscriptions() {
     /*  this.eventEmitter.on("*", (emittedData) => {
        console.group("-------- runRules Event --------");

        console.log("Log Event Received:", emittedData);

        const { event, action, data } = emittedData;


        switch (event) {
          case configuratorRuleService.eventEmitter.events.LOG:
            console.log("Match event: LOG");
            console.log("LOGGER: ", data);
            
            break;
          case this.eventEmitter.events.CLEAR_RULE_OUTPUT:
            console.log("Run Rule Event: " + this.eventEmitter.events.CLEAR_RULE_OUTPUT);
           
          //  clearRuleActionMsgFn();
            
            break;
  
          case this.eventEmitter.events.CONDITION_RULE_SATISFIED:
            console.warn("Match event: conditionRuleSatisfied - Rule:" + data.rule.name);          
            break;
          case this.eventEmitter.events.CONDITION_RULE_NOT_SATISFIED:
            console.warn("Match event: executeNotSatisfiedRuleAction - Rule:" + data.rule.name);          
            break;
        
          default:
            break;
        }

        
      console.log('--------')
      console.groupEnd();
      }); */
  
    
    },


  ruleActionResolver(
    checkoutMode,
    ruleAction,
    addOrChangeMachineOptionQtyInCartFn,
    removeMachineOptionFromCartFn,
    decreaseMachineOptionQtyInCartFn,
    getMachineOptionByArticleIdFn
  ) {
    console.groupCollapsed("resolveRuleAction - START");
    console.log("ruleAction", ruleAction);

    const isInCheckoutMode = checkoutMode;

    if (ruleAction.is_active) {
      console.log("RESOLVE RULE ACTION", ruleAction);
      const option = getMachineOptionByArticleIdFn(ruleAction.action);
      /*   const option = getMachineOptionByArticleId(
                     rootState,
                     ruleAction.action
                   ); */
      //  const isHidden = option.isHidden; // Can be changed later

      console.log("Action Option: ", option);
      // console.log("Option Article ID: ", option.articleUnitId);

      // Check if we still in configurator mode and if the option  hidden
      // Some rules actions require to be executed in checkout mode after the configurator view is left

      if (isInCheckoutMode === false && ruleAction.execute_checkout === true) {
        console.warn("Option action only in checkout mode");
        console.groupEnd();
        return;
      }

      if (ruleAction.action_type.toLowerCase() == "add") {
        if (option) {
          console.log("ADD Option: ", option.name[0].value);
          // commit("ADD_OPTION_TO_CART", option);
          addOrChangeMachineOptionQtyInCartFn({
            option,
            optionQty: ruleAction.action_qty,
          });
          /* commit("ADD_OR_CHANGE_OPTION_QTY_TO_CART", {
          option,
          optionQty: ruleAction.action_qty
        }); */
        }
      } else if (ruleAction.action_type.toLowerCase() == "remove") {
        if (option) {
          console.log("REMOVE Option: ", option.name[0].value);
          removeMachineOptionFromCartFn(option);
          /* commit("REMOVE_OPTION_FROM_CART", option); */
        }
      } else if (ruleAction.action_type.toLowerCase() == "decrease") {
        console.log("DECREASE Option: ", option.name[0].value);

        decreaseMachineOptionQtyInCartFn({
          option,
          optionQty: ruleAction.action_qty,
        });
        /* commit("DECREASE_OPTION_QTY_IN_CART", {
        option,
        optionQty: ruleAction.action_qty
      }); */
      }
    }

    console.groupEnd();
  },

  /**
   *
   * // only check if rule qty
   * rule is valid only when rule qty does not equal qty in cart
   * @param {*} rules - rules to be checked
   * @param {*} sessionData
   * @param {*} optionInCartFn
   * @param {*} machineOptionQtyInCartFn
   * @param {*} clearRuleActionMsgFn
   * @param {*} addRuleActionMsgFn
   * @param {*} changeMachineOptionQtyInCartFn
   * @param {*} addOrChangeMachineOptionQtyInCartFn
   * @param {*} removeMachineOptionFromCartFn
   * @param {*} decreaseMachineOptionQtyInCartFn
   * @param {*} getMachineOptionByArticleIdFn
   * @param {*} isInCheckoutMode
   * @param {*} articleTypeQtyInCart
   * @param {*} getAlertMsgLangFn
   * @param {*} articleTypes
   * @param {Object} facts - facts to be used in rule engine
   * @param {Function} facts.articleTypeQtyInCart - article type qty in cart
   * @param {Function} facts.clearRuleOutputActionMessageFn - function to clear rule output action message
   * @param {Array} facts.articleTypes - article types
   * @param {Object} facts.articleTypesQty - Map of article type qty by articleType
   * @param {Function} facts.removeMachineOptionFn - function to remove machine option from cart
   * @param {Function} facts.decreaseMachineOptionQtyFn - function to decrease machine option qty in cart
   * @param {Function} facts.addOrChangeMachineOptionFn - function to add or change machine option qty in cart
   * @param {Function} facts.changeMachineOptionQtyFn - function to change machine option qty in cart
   *
   */
  runRules(
    rules,
    sessionData,
    optionInCartFn,
    machineOptionQtyInCartFn,
    clearRuleActionMsgFn,
    addRuleActionMsgFn,
    changeMachineOptionQtyInCartFn,
    addOrChangeMachineOptionQtyInCartFn,
    removeMachineOptionFromCartFn,
    decreaseMachineOptionQtyInCartFn,
    getMachineOptionByArticleIdFn,
    isInCheckoutMode,
    articleTypeQtyInCart,
    getAlertMsgLangFn,
    articleTypes,
    facts = {}
  ) {
    console.groupCollapsed("configurationRuleValidator - START");
   /*  configuratorRuleService.eventEmitter.emit({
      event: configuratorRuleService.eventEmitter.events.START_RUN_RULES,
      action: "startRunRules",
      data: {}, 
    }); */

    // Get normal rules with conditions
    const selectedMachineActiveRules = configuratorRuleEngine.filterActiveConditionRules(rules, sessionData);

    // Get speical linked options rules
    const selectedMachineActiveLinkedRules = configuratorRuleEngine.filterActiveLinkedConditionRules(rules, sessionData);

    /* configuratorRuleService.eventEmitter.emit({
      event: configuratorRuleService.eventEmitter.events.LOG,
      action: "clearRuleOutput",
      data: {
        selectedMachineActiveRules: selectedMachineActiveRules,
        selectedMachineActiveLinkedRules: selectedMachineActiveLinkedRules,
      }, 
    }); */

    console.log("Current Machine Active Rules : ", selectedMachineActiveRules);
    console.log("Current Machine Active Linked Rules : ", selectedMachineActiveLinkedRules);

    // Clear old messages first
    // clearRuleActionMsgFn();
    facts.clearRuleOutputActionMessageFn();
   /*  configuratorRuleService.eventEmitter.emit({
      event:this.eventEmitter.events.CLEAR_RULE_OUTPUT,
      action: "clearRuleOutput",
      data: {}, 
    }); */

   // console.warn("CLEAR RULE ACTION MESSAGES");
    /* commit("CLEAR_RULE_VALIDATE_ACTION_MESSAGE"); */
    //commit("TOGGLE_CART_SUBMIT_READY", false)

   /*  configuratorRuleService.eventEmitter.emit({
      event: configuratorRuleService.eventEmitter.events.START_CHECK_RULES,
      action: "startCheckActiveConditionRules",
      data: {}, 
    }); */

    selectedMachineActiveRules.forEach(async (rule) => {
      console.groupCollapsed("Checking Condition Rule: " + rule.name);
      console.log("CHECK RULE: ", rule);
     /*  configuratorRuleService.eventEmitter.emit({
        event: configuratorRuleService.eventEmitter.events.START_RULE_CHECK,
        action: "startCheckRuleCondition",
        data: {ruleName: rule.name}, 
      }); */

      /*  if (rule.input === null || rule.input === undefined) {
      console.warn("Rule has no input conditions");
      console.groupEnd();
      return;
    } */

      /// START RULE CHECK

      const ruleInputsTree = configuratorRuleEngine.buildInputConditionsTree(rule.input);

      const ruleExpectedValue = JSON.parse(rule.expected_value);

      ////////////////////////////////////////////////////////////////////////////////
      /** Check RUle Inputs and sub conditions */
      // RULE CONDITIONS
      const ruleSubConditionsEvalResult = [];

      ruleInputsTree.forEach((condition) => {
        /** SUB CONDITION */
        /*
                Sub conditons should be evaluated in the same order as they are defined in the rule
                Sub conditions are evaluated seperately from the main rule conditions
              */
        if (Array.isArray(condition)) {
          const ruleCondition = [];

          condition.forEach((subCondition) => {
            
            if (subCondition.type == "ruleInput") {
              const inputCondRes = configuratorRuleEngine.checkRuleInput(
                subCondition,
                articleTypes,
                optionInCartFn,
                machineOptionQtyInCartFn,
                articleTypeQtyInCart
              );

              ruleCondition.push(inputCondRes);
            } else if (subCondition.type == "conditionOperator") {
              ruleCondition.push(subCondition.inputOperator);
            }
          });

          console.log("Rule Input SubCondiiton: ", ruleCondition);
          ruleSubConditionsEvalResult.push([...ruleCondition]);

        } else if (typeof condition === "object") {

          /** CONDITON OPERATOR */
          //  DELETE NOT NEED its always object and its always operator
          if (condition.type == "ruleInput") {
            const inputCondRes = configuratorRuleEngine.checkRuleInput(
              condition,
              articleTypes,
              optionInCartFn,
              machineOptionQtyInCartFn,
              articleTypeQtyInCart
            );
          } else if (condition.type == "conditionOperator") {
            ruleSubConditionsEvalResult.push(condition.inputOperator);
          }
        }

      });

      console.log(" TRY TO EVAL RULE CONDIITONS REUSLT : ", ruleSubConditionsEvalResult );

      const revRes = [];
      ruleSubConditionsEvalResult.forEach((condition) => {
        if (Array.isArray(condition)) {
          revRes.push(eval(condition.join(" ")));
        } else if (typeof condition === "string") {
          revRes.push(condition);
        }
      });

      const ruleRes = eval(revRes.join(" "));
      const ruleResult = !(ruleRes == ruleExpectedValue);
      console.log("ruleResult ? : ", ruleResult);

      let rulePassed = false;

      if (ruleResult) {
        rulePassed = true;
        /* **** RULE SATISFIED **** */
        console.info("----- RULE SATISFIED -----");

       /*  configuratorRuleService.eventEmitter.emit({
          event:configuratorRuleService.eventEmitter.events.CONDITION_RULE_SATISFIED,
          action: "conditionRuleSatisfied",
          data: {
            rule: rule,
            ruleName: rule.name,
            ruleResult: ruleResult,
          }, 
        }); */
    

        // executeRuleMessage(rule);

        // execute_true_actions()
        configuratorRuleEngine.executeRuleAction(
          rule,
          true,
          isInCheckoutMode,
          addOrChangeMachineOptionQtyInCartFn,
          removeMachineOptionFromCartFn,
          decreaseMachineOptionQtyInCartFn,
          getMachineOptionByArticleIdFn
        );
        //executeRuleAction(rule, true);


        /* configuratorRuleService.eventEmitter.emit({
          event: "executeSatisfiedRuleAction",
          action: "executeRuleAction",
          data: {
            rule: rule,
            ruleName: rule.name,
            rulePassed: rulePassed,
          }, 
        }); */

      } else {
        rulePassed = false;

        /* **** RULE NOT SATISFIED **** */
        console.warn("----- RULE NOT SATISFIED -----");

       /*  configuratorRuleService.eventEmitter.emit({
          event: configuratorRuleService.eventEmitter.events.CONDITION_RULE_SATISFIED,
          action: "conditionRuleNotSatisfied",
          data: {
            rule: rule,
            ruleName: rule.name,
            ruleResult: ruleResult,
          }, 
        }); */

        

        //executeRuleMessage(rule);
        configuratorRuleEngine.executeRuleMessage(
          rule,
          getAlertMsgLangFn,
          addRuleActionMsgFn
        );

        // execute_false_actions()
        configuratorRuleEngine.executeRuleAction(
          rule,
          false,
          isInCheckoutMode,
          addOrChangeMachineOptionQtyInCartFn,
          removeMachineOptionFromCartFn,
          decreaseMachineOptionQtyInCartFn,
          getMachineOptionByArticleIdFn
        );
        //executeRuleAction(rule, false);

        /* configuratorRuleService.eventEmitter.emit({
          event: "executeNotSatisfiedRuleAction",
          action: "executeRuleAction",
          data: {
            rule: rule,
            ruleName: rule.name,
            rulePassed: rulePassed,
          }, 
        }); */

        // send_message()
      }

      console.info("rulePassed RESULT: ", rulePassed);
      console.log("----- DONE ------");
      console.groupEnd();
    });


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //// *****************************************************  ***********************************************************************////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////
    /// New rule type linked options
    /// These has to be executed after all normal rules checked as it will modify some options qty in cart when found
    /////////////////////////////////////////////////

   
    /***
     * Store all right side options when all rule met
     *  the qty of right side option should be the sum from all rules that link the same right side option
     * Key: option id - value: option qty to change in cart
    */
    let optionsToChange = {};

    selectedMachineActiveLinkedRules.forEach(async (rule, ruleIndex) => {
      console.groupCollapsed("Checking LinkedOption Rule: " + rule.name);
      console.log("CHECK RULE: ", rule);

      const ruleInputsTree = configuratorRuleEngine.buildInputConditionsTree(rule.input_linked);
      console.log("ruleInputsTree", ruleInputsTree);


      ruleInputsTree.forEach((ruleInput) => {
        // ONLY one condition is allowed for now
        // It can be changed later on
        if (Array.isArray(ruleInput)) {
          let firstLinkedInputInCart = false;
          let firstLinkedInputQtyInCart = 0;

          ruleInput.forEach((linkedInput, index) => {
            if (
              linkedInput.inputType !== null ||
              linkedInput.inputType !== undefined
            ) {
              // Get first input used as base for linked input
              // To be compared with the rest of inputs
              if (index === 0) {
                if (linkedInput.inputType == "machineOption") {
                  firstLinkedInputInCart = optionInCartFn(linkedInput.input);
                  console.warn(
                    "firstLinkedInputInCart",
                    firstLinkedInputInCart
                  );
                  /*  firstLinkedInputInCart = isOptionInCart(
                                   state,
                                   linkedInput.input
                                 ); */
                  firstLinkedInputQtyInCart = machineOptionQtyInCartFn(
                    linkedInput.input
                  );
                  /*  firstLinkedInputQtyInCart = getOptionQtyInCart(
                                   state,
                                   linkedInput.input
                                 ); */
                } else if (linkedInput.inputType == "articleType") {
                  const inputType = articleTypes.find(
                    (type) => type.id == linkedInput.input
                  );

                  firstLinkedInputInCart = articleTypeQtyInCart.hasOwnProperty(
                    inputType.type
                  );
                  firstLinkedInputQtyInCart =
                    articleTypeQtyInCart[inputType.type] !== undefined
                      ? articleTypeQtyInCart[inputType.type]
                      : 0;

                  console.warn(
                    "firstLinkedInputInCart",
                    firstLinkedInputInCart
                  );
                  console.warn(
                    "firstLinkedInputQtyInCart",
                    firstLinkedInputQtyInCart
                  );
                }
              } else {
                // If any of the rest of inputs found in cart change its qty to match the first base input qty

                if (linkedInput.inputType == "machineOption") {
                  let isInCart = optionInCartFn(linkedInput.input);
                  //   let isInCart = isOptionInCart( state,linkedInput.input); 
                  let inputQtyInCart = machineOptionQtyInCartFn(
                    linkedInput.input
                  );
                 // let inputQtyInCart = getOptionQtyInCart(  state, linkedInput.input);

                  console.log("isInCart", isInCart);
                  console.log("inputQtyInCart", inputQtyInCart);

                  // If the base linked option found in cart
                  // change any linked option qty to match the base option qty
                  if (firstLinkedInputInCart && isInCart) {
                    // CHANGE inpt to match lastQty in cart

                    console.warn( "BASE Option was found in cart, TRY to change linked option qty to match base option qty");

                    if (optionsToChange[linkedInput.input]) {

                      optionsToChange[linkedInput.input] = optionsToChange[linkedInput.input] + firstLinkedInputQtyInCart;

                    } else {
                      optionsToChange[linkedInput.inpu ] = firstLinkedInputQtyInCart;
                    }

                     //commit('CHANGE_OPTION_QTY_IN_CART', { optionId: linkedInput.input , qty: inputQtyInCart + firstLinkedInputQtyInCart});

                    // executeRuleMessage(rule);
                    configuratorRuleEngine.executeRuleMessage(
                      rule,
                      getAlertMsgLangFn,
                      addRuleActionMsgFn
                    );
                  }
                } else if (linkedInput.inputType == "articleType") {
                  // ERROR  ONLY first input is allowed to be article type
                }
              }
            }

            /*  if(linkedInput.inputType == 'machineOption'){


                  if(index === 0) {
                    firstLinkedInputInCart = isOptionInCart(state, linkedInput.input);
                    firstLinkedInputQtyInCart = getOptionQtyInCart(state, linkedInput.input);



                  } else  {
        
                      let isInCart = isOptionInCart(state, linkedInput.input);
                      let inputQtyInCart = getOptionQtyInCart(state, linkedInput.input);
        
                      console.log('isInCart', isInCart);
                      console.log('inputQtyInCart', inputQtyInCart);
      
                
                      if(firstLinkedInputInCart && isInCart){
        
                        lastInputQtyInCart = inputQtyInCart > lastInputQtyInCart ? inputQtyInCart : lastInputQtyInCart;
        
      
                        // CHANGE inpt to match lastQty in cart          

                          console.warn('First Optin is in cart and second is in cart');
    
                          commit('CHANGE_OPTION_QTY_IN_CART', { optionId: linkedInput.input , qty: firstLinkedInputQtyInCart});

                          executeRuleMessage(rule)
                           
                      }
      
                  }
     
     
              } */
          });
        }
      });

      // Once all rules checked
      // Change all linked option in passed rules
      if (ruleIndex === selectedMachineActiveLinkedRules.length - 1) {
        console.log("Try to change All optiosn qty: ", optionsToChange);

        for (let [key, value] of Object.entries(optionsToChange)) {
          changeMachineOptionQtyInCartFn({
            optionId: parseInt(key),
            qty: value,
          });

          /*  commit("CHANGE_OPTION_QTY_IN_CART", {
                           optionId: parseInt(key),
                           qty: value,
                         }); */
        }
      }

      console.log("----- DONE ------");
      console.groupEnd();
    });



   /*  configuratorRuleService.eventEmitter.emit({
      event:configuratorRuleService.eventEmitter.events.END_RUN_RULES,
      action: "endRunRules",
      data: {}, 
    }); */

    console.log("configurationRuleValidator - END");
    console.groupEnd();
  },
};

configuratorRuleService.initializeEventSubscriptions();


export default configuratorRuleService;
