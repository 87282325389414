import { sortBy } from "@/utils/common-utils";
import storeUtils from "@/utils/store-utils";


const configurator = {
  buildConfiguratorMachineOptions: (machineOptions, imageLayers) => {
    console.groupCollapsed("buildConfiguratorMachineOptions()");
    console.log("machineOptions", machineOptions);
    console.log("imageLayers", imageLayers);

    const configuratorMachineOptions = [];

    machineOptions
      .filter(option => option.is_active) //       // Load only active options
      .forEach(machineOption => {
        console.log("Process machineOption", machineOption);

        const optionArticle = machineOption.article;

        const optionAltArticles = optionArticle.alt_articles || [];

        // These only loaded when configurator is loaded in debug or edit mode
        const aconityArticles = storeUtils.getAcontiyArticles();
      

        const articleTranslations = optionArticle.translations || [];


        const machineOptionAltArticlesList = aconityArticles.filter(article => {
          return optionAltArticles.includes(article.articleId);
        });

        const configuratorMachineOption = {
          id: machineOption.id,
          optionName: machineOption.name,
          displayName: optionArticle.name,
          type: optionArticle.article_type.type,
          article: optionArticle,
          articleId: optionArticle.id,
          articleUnitId: optionArticle.unitid,
          altArticles: machineOptionAltArticlesList || [], //optionArticle.alt_articles || [],
          altArticle: null,
          categoryId: machineOption.category.id,
          isDefault: machineOption.is_default,
          isRequired: machineOption.is_required,
          inHiddenCategory: machineOption.category.is_hidden,
          isHidden: machineOption.is_hidden,
          checkoutIgnored: machineOption.is_ignored,
          allowOrderQty: machineOption.allow_qty,
          minQty: machineOption.min_qty,
          maxQty: machineOption.max_qty,
          sortOrder: machineOption.option_order,
          orderQty: 0,
          name: [],
          caption: [],
          short_desc: [],
          specs: [],
          images: [],
          thumbnail: null,
          thumbnailMulti: null,
          thumbnails: [],
          pictogram: "",
          imagelayers: [],
          imageLayer: null,
          childOptions: []
        };

        configuratorMachineOption.name.push({
          lang: "EN",
          value: optionArticle.caption
        });
        configuratorMachineOption.caption.push({
          lang: "EN",
          value: optionArticle.caption
        });
        configuratorMachineOption.specs.push({
          lang: "EN",
          value: optionArticle.specs
        });
        configuratorMachineOption.short_desc.push({
          lang: "EN",
          value: optionArticle.description
        });

        articleTranslations.forEach(articleTransation => {
          configuratorMachineOption.name.push({
            lang: articleTransation.iso.toUpperCase(),
            value: articleTransation.caption
          });
          configuratorMachineOption.caption.push({
            lang: articleTransation.iso.toUpperCase(),
            value: articleTransation.caption
          });
          configuratorMachineOption.specs.push({
            lang: articleTransation.iso.toUpperCase(),
            value: articleTransation.specs
          });
          configuratorMachineOption.short_desc.push({
            lang: articleTransation.iso.toUpperCase(),
            value: articleTransation.description
          });
        });

        configurator.addconfiguratorMachineOptionMedia(
          configuratorMachineOption,
          optionArticle,
          imageLayers
        );

        configuratorMachineOptions.push(configuratorMachineOption);
      });

    console.log("configuratorMachineOptions", configuratorMachineOptions);
    console.groupEnd();
    return configuratorMachineOptions;
  },

  addconfiguratorMachineOptionMedia: (
    configuratorMachineOption,
    optionArticle,
    imageLayers
  ) => {
    // const articleTranslations = optionArticle.translations || [];
    const articlePictograms =
      optionArticle.pictogram !== undefined ? optionArticle.pictogram : [];
    const articleThumbnails =
      optionArticle.thumbnail !== undefined ? optionArticle.thumbnail : [];
    const articleImages =
      optionArticle.images !== undefined ? optionArticle.images : [];

    articlePictograms.forEach(pictogram => {
      console.log(pictogram);
      configuratorMachineOption.pictogram = `${process.env.VUE_APP_API_BASE_FILES}${pictogram.url}`;
    });

    articleThumbnails.forEach(thumbnail => {
      if (thumbnail.target.includes("default")) {
        configuratorMachineOption.thumbnail = `${process.env.VUE_APP_API_BASE_FILES}${thumbnail.url}`;
      }

      if (thumbnail.target.includes("thumbnail_n")) {
        configuratorMachineOption.thumbnailMulti = `${process.env.VUE_APP_API_BASE_FILES}${thumbnail.url}`;
      }

      configuratorMachineOption.thumbnails.push(
        `${process.env.VUE_APP_API_BASE_FILES}${thumbnail.url}`
      );
    });

    /**
     *  Add normal Option images
     */
    articleImages.forEach(componentImage => {
      console.log("componentImage", componentImage);

      const optionImage = {
        url: `${process.env.VUE_APP_API_BASE_FILES}${componentImage.url}`
      };

      configuratorMachineOption.images.push(optionImage);
    });

    // ADD IMAGE LAYERS
    const machineOptionImageLayer = imageLayers.find(
      ilayer => ilayer.article.id == optionArticle.id
    );

    console.log("machineOptionImageLayer", machineOptionImageLayer);
    if (machineOptionImageLayer) {
      configuratorMachineOption.imageLayer = {
        position: machineOptionImageLayer.position,
        baseLayer: `${process.env.VUE_APP_API_BASE_FILES}${machineOptionImageLayer.base_layer.url}`,
        viewDefault: `${process.env.VUE_APP_API_BASE_FILES}${machineOptionImageLayer.base_layer.url}`,
        viewMulti: `${process.env.VUE_APP_API_BASE_FILES}${machineOptionImageLayer.base_layer.url}`
      };

      if (Array.isArray(machineOptionImageLayer.layers)) {
        console.log("Machine State Layers have multi views layer");

        machineOptionImageLayer.layers.map(layerView => {
          console.log(layerView);

          if (layerView.target.includes("1") && layerView.url) {
            console.log(layerView);

            const optionLayerView = {
              view1: layerView.url
            };

            configuratorMachineOption.imagelayers.push(optionLayerView);
            configuratorMachineOption.imageLayer.viewDefault = `${process.env.VUE_APP_API_BASE_FILES}${layerView.url}`;
          }

          if (layerView.target.includes("n") && layerView.url) {
            const optionLayerView = {
              viewn: layerView.url
            };

            configuratorMachineOption.imagelayers.push(optionLayerView);
            configuratorMachineOption.imageLayer.viewMulti = `${process.env.VUE_APP_API_BASE_FILES}${layerView.url}`;
          }
        });
      }
    }
  },

  getConfiguratorMachineOptionsCategoryList: (
    machineOptions,
    allCategories
  ) => {
    console.groupCollapsed("getConfiguratorMachineOptionsCategoryList()");

    let currentMachineCategoriesSet = new Set();

    machineOptions.map(machineOption => {
      currentMachineCategoriesSet.add(machineOption.category);
    });

    const currentMachineCategoriesArr = [...currentMachineCategoriesSet];

    const currentMachineCategoriesArrFiltered = allCategories.filter(
      ({ id: id1 }) =>
        currentMachineCategoriesArr.some(({ id: id2 }) => id2 === id1)
    );

    console.log(
      "currentMachineCategoriesArrFiltered",
      currentMachineCategoriesArrFiltered
    );
    console.groupEnd();
    return currentMachineCategoriesArrFiltered;
  },
  /**
   *  Prepare the configurator checkout cart order
   * @param {array} configuratorCheckoutCart  - The configurator checkout cart
   * @returns  {array} orderArticles - The order articles sorted by sort order for the configurator checkout request
   */
  prepareConfiguratorCheckoutCartOrder: configuratorCheckoutCart => {
    console.groupCollapsed("prepareConfiguratorCheckoutCartOrder()");
    console.log("configuratorCheckoutCart", configuratorCheckoutCart);
    const actualOrderArticles = configuratorCheckoutCart.filter(
      cartItem => cartItem.checkoutIgnored === false
    );

    const orderArticles = actualOrderArticles.map(cartItem => {
      return {
        articleId:  cartItem.altArticle ? cartItem.altArticle : cartItem.articleUnitId, // cartItem.articleUnitId, //
        qty: cartItem.orderQty,
        sort: cartItem.sortOrder
      };
    });

    console.log("orderArticles", orderArticles);
    console.groupEnd();
    return sortBy(orderArticles, "sort");
  },

  /**
   * Map the configurator inquiry document to the configurator data object for the configurator checkout form
   */
  parseInquiryDocumentToConfiguratorData: (documentData, requestId) => {
    console.groupCollapsed("parseInquiryDocumentToConfiguratorData()");
    console.log("documentData", documentData);
    console.log("requestId", requestId);

    const { document, systemType } = documentData;

    console.warn(storeUtils.storeCountriesList());

    const fullCountryName = storeUtils
      .storeCountriesList()
      .find(country => country.cca2 == document.receiverAddress.country);

    const isReturningCustomer =
      Number(document.receiverAddress.addressId) !==
      Number(document.receiverAddress.owner);

    const customerNumber = isReturningCustomer
      ? document.receiverAddress.owner
      : "";

    const customerData = {
      returningCustomer: isReturningCustomer,
      company: document.receiverAddress.name,
      salutation: document.receiverUser.salutation == "Herr" ? "sir" : "madam",
      firstName: document.receiverUser.firstName,
      lastName: document.receiverUser.lastName,
      street: document.receiverAddress.street,
      housenumber: "",
      zip: document.receiverAddress.zip,
      place: document.receiverAddress.city,
      country: fullCountryName ? fullCountryName.cca2 : "",
      customerNumber: customerNumber,
      phone: document.receiverUser.phone,
      email: document.receiverUser.email,
      contact_salutation: document.receiverUser.email,
      contact_firstName: "",
      contact_lastName: "",
      contact_phone: "",
      contact_email: "",
      currency: document.currency == "EUR" ? "1" : "2",
      terms: true,
      msg: document.receiverAddress.memo
    };

    const articles = document.positions.map(article => {
      const articleData = {
        id: article.articleId,
        qty: article.quantity
      };

      return articleData;
    });

    const parsedDocumentData = {
      id: document.id,
      requestId: requestId,
      documentId: document.documentId,
      machineName: systemType,
      customerData: customerData,
      articles: articles
    };

    console.log("parsedDocumentData", parsedDocumentData);
    console.groupEnd();
    return parsedDocumentData;
  }
};

export default configurator;
