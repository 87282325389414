<template>
  <div class="configurator-navi">
    <div class="container">
      <div>
        <nav
          class="navbar navbar-toggleable-md navbar-inverse bg-faded c-navbar"
        >
          <button
            class="navbar-toggler my-20 p-10 float-right"
            type="button"
            data-toggle="collapse"
            data-target="#configuratorcategories"
            aria-controls="configuratorcategories"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
            {{ getStaticText("categories") }}
          </button>
          <div
            id="configuratorcategories"
            class="collapse navbar-collapse c-navbar__collapse"
          >
            <ul class="navbar-nav">
              <li class="nav-item active">
                {{ getStaticText("navbarcheckout") }}
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Nav",
  computed: {
    ...mapGetters(["getStaticText"]),
  },
};
</script>
