import { RafPromiseQueue } from "./rafQueue";
import anime from "animejs/lib/anime.es.js";

let rafQueue = new RafPromiseQueue();

let defaultOptions = {
  activeClass: "header__menu-toggle--active",
};

let menuOptions = {
  activeClass: "header-menu--expanded",
};

/**
 * @param {HTMLElement} $el
 * @param {String} className
 * @param {Boolean} active
 */
const toggleClass = ($el, className, active) => {
  if (active && !$el.classList.contains(className)) {
    $el.classList.add(className);
  } else {
    $el.classList.remove(className);
  }
};

/**
 * @param {HTMLElement} $el
 * @param {Number} duration
 * @param {MobileMenu|*} context
 */
function createMenuAnimation($el, duration, context) {
  let display = $el.getAttribute("data-expanded"),
    $body = self.document.body;

  /**
   * @param {*} instance
   */
  function onBeginAndComplete(instance) {
    rafQueue.clear();

    const displayMenu = instance.reversed ? !display : display;

    if (!context.isOpen) {
      instance.reverse();
    }

    if (displayMenu) {
      if (!instance.completed) {
        rafQueue.add(function () {
          $el.setAttribute("data-expanded", "true");
          toggleClass($el, menuOptions.activeClass, context.isOpen);
        });

        rafQueue.add(function () {
          $body.classList.add("scroll-lock");
        });

        rafQueue.run();
      }
    }

    if (!displayMenu) {
      if (instance.completed) {
        $body.classList.remove("scroll-lock");
        $el.setAttribute("data-expanded", "false");
        toggleClass($el, menuOptions.activeClass, false);
        context.$appContext && context.$appContext.$emit("navigate");
      }
    }
  }

  var timeline = anime.timeline({
    autoplay: false,
    duration,
    loop: false,
    easing: "easeInCubic",
    begin: onBeginAndComplete,
    complete: onBeginAndComplete,
  });

  timeline
    .add({
      targets: $el,
      opacity: [0, 1],
    })
    .add({
      targets: $el.querySelectorAll(".header-menu__item"),
      opacity: [0, 1],
      translateX: display ? [-15, 0] : [0, -15],
      delay: (_, i) => (i + 1) * 75,
    });

  return timeline;
}

class MobileMenu {
  /** @type {Vue|null} */
  $appContext;

  constructor() {
    /** @type {Boolean|String} */
    this.isOpen = false;
    this.menuAnimation = null;
    this.$appContext = null;
  }

  /****************************
   * Register
   ****************************/

  /**
   * @param {HTMLElement} $el
   */
  setToggle($el) {
    this.$toggleEl = $el;
    this.$toggleEl.addEventListener("click", this.toggleMobileMenu.bind(this));
    this.updateToggleElement();
  }

  /**
   * @param {HTMLElement} $el
   */
  setMenu($el) {
    this.$menuEl = $el;
    this.$menuEl.setAttribute("data-expanded", this.isOpen);
    toggleClass(this.$menuEl, menuOptions.activeClass, this.isOpen);
    this.menuAnimation = createMenuAnimation(this.$menuEl, 200, this);

    this.$menuEl
      .querySelectorAll(".header-menu__item")
      .forEach(($item) =>
        $item.addEventListener("click", this.toggleMobileMenu.bind(this))
      );
  }

  /**
   * @param {Vue} $appContext
   */
  setAppContext($appContext) {
    this.$appContext = $appContext;
  }

  /****************************
   * Unregister
   ****************************/

  removeToggle() {
    if (!this.$toggleEl) return;
    this.$toggleEl.removeEventListener(
      "click",
      this.toggleMobileMenu.bind(this)
    );
    this.$toggleEl.removeAttribute("data-expanded");
    this.$toggleEl = null;
  }

  removeMenu() {
    if (!this.$menuEl) return;
    this.$menuEl.removeAttribute("data-expanded");
    toggleClass(this.$menuEl, menuOptions.activeClass, false);
    this.$menuEl
      .querySelectorAll(".header-menu__item")
      .forEach(($item) =>
        $item.removeEventListener("click", this.toggleMobileMenu.bind(this))
      );
    this.$menuEl = null;
    this.menuAnimation = null;
  }

  removeAppContext() {
    this.$appContext = null;
  }

  /****************************
   * Updates
   ****************************/

  toggleMobileMenu() {
    this.isOpen = !this.isOpen;
    this.updateElements();
  }

  updateElements() {
    this.updateToggleElement();
    this.updateMenuElement();
  }

  updateToggleElement() {
    if (!this.$toggleEl) return;
    this.$toggleEl.setAttribute("data-expanded", this.isOpen);
    toggleClass(this.$toggleEl, defaultOptions.activeClass, this.isOpen);
  }

  updateMenuElement() {
    if (!this.$menuEl) return;
    if (!this.menuAnimation) {
      this.menuAnimation = createMenuAnimation(this.$menuEl, 200, this);
    }

    if (!this.isOpen) {
      this.menuAnimation.reverse();
      this.menuAnimation.play();
    } else {
      this.menuAnimation.play();
    }
  }
}

/**
 * VuePlugin
 */
const MobileMenuPlugin = {
  /**
   * @param {Vue} Vue
   */
  install: function (Vue) {
    let mobileMenuInstance = new MobileMenu();

    Vue.mixin({
      directives: {
        "mobile-menu-toggle": {
          /**
           * @param {HTMLElement} $el
           */
          bind($el) {
            mobileMenuInstance.setAppContext(new Vue());
            mobileMenuInstance.setToggle($el);
          },
          unbind() {
            mobileMenuInstance.removeToggle();
          },
        },
        "mobile-menu": {
          /**
           * @param {HTMLElement} $el
           */
          bind($el) {
            mobileMenuInstance.setMenu($el);
          },
          unbind() {
            mobileMenuInstance.removeMenu();
            mobileMenuInstance.removeAppContext();
          },
        },
      },
      methods: {
        /**
         * @param {*} pathConfig
         * @param {Event} e
         */
        delayRouting(pathConfig, e) {
          e.preventDefault();
          let cb = () => {
            this.$router.push(pathConfig);
            mobileMenuInstance.$appContext.$off("navigate", cb);
          };
          mobileMenuInstance.$appContext.$on("navigate", cb);
        },
      },
    });
  },
};

export default MobileMenuPlugin;
