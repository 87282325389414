import authService from "@/services/auth";

export default {
  SET_LANG(state, lang) {
    state.selectedLanguage = lang;
  },
  SET_G_CLIENT_ID(state, gid) {
    state.gid = gid;
  },

  SET_READY(state, readyState) {
    state.ready = readyState;
  },
  SET_INIT(state, initState) {
    state.init = initState;
  },

  SET_AUTHINCATED(state, userToken) {
    if (userToken) {
      authService.setAuthCookie(JSON.stringify(userToken), 1);

      state.authenticated = true;
      state.authToken = userToken;
      /*  state.session = {
        ...state.session,
        accessToken: userTokenInfo.access_token,
        refreshToken: userTokenInfo.refresh_token,
        tokenType: userTokenInfo.token_type,
        expiresIn: userTokenInfo.expires_in,
      }; */
    }
  },

  SET_COUNTRY_LIST(state, countriesList) {
    state.countriesList = countriesList;
  },

  SET_CURRENT_SESSION_DATA(state, sessionData) {
    for (const [key, value] of Object.entries(sessionData)) {
      console.log(`${key}: ${value}`);

      state.session = {
        ...state.session,
        [key]: value,
      };
    }

    // state.session = {};
  },
};
