<template>
  <div class="wrap" role="document">
    <div class="content">
      <main class="main checkout" id="configurator">
        <stage></stage>
        <navbar></navbar>
        <div class="container mt-10">
          <div class="row">
            <ValidationObserver ref="form">
              <div class="col-12 col-lg-12 mt-15">
                <!-- <h2>Bestellung Ihrer Aconity Lab Konfiguration</h2> -->
                <h2>{{ getStaticText("headlinecheckout") }}</h2>

                <p>{{ getStaticText("textcheckout") }}</p>

                <form class="form-horizontal mt-50" v-if="!error">
                  <div class="row">
                    <div class="col-lg-9 mt-15">
                      <div class="row">
                        <div class="col-lg-12 mt-15">
                          <ValidationProvider
                            name="returningCustomer"
                          >
                            <div class="checkbox p-15">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  name="returningCustomer"
                                  v-model="order.returningCustomer"
                                />
                                <span class="cr"
                                  ><i class="cr-icon fa fa-check"></i
                                ></span>
                                Returning Customer
                              </label>
                            </div>
                          </ValidationProvider>
                        </div>
                      </div>
                      <!-- EXISTING Customer ROW -->
                      <div class="row" v-if="order.returningCustomer">
                        <div class="col-lg-12 mt-15">
                          <h3 class="formlabel">Existing Customer</h3>

                          <div class="option-box">
                          
                            <div class="row">
                              <div class="form-group col-md-6">
                                <label
                                  class="control-label"
                                  for="customerNumber"
                                  >{{
                                    getStaticText("customer-number")
                                  }}*</label
                                >
                                <ValidationProvider
                                  name="customerNumber"
                                  rules="required|max:49"
                                  v-slot="{ errors }"
                                >
                                  <input
                                    id="customerNumber"
                                    name="customerNumber"
                                    v-model="order.customerNumber"
                                    type="text"
                                    :maxlength="50"
                                    class="form-control input-md"
                                    :class="{
                                      'is-invalid': submitted && errors.length > 0
                                    }"
                                  />
                                </ValidationProvider>
                              </div>
                              <div class="form-group col-md-6">
                                <label
                                  class="control-label"
                                  for="orderMessage"
                                  >{{ getStaticText("wishes") }}</label
                                >
                                <textarea
                                  style="height: 100px"
                                  class="form-control"
                                  id="orderMessage"
                                  name="orderMessage"
                                  v-model="order.msg"
                                  :placeholder="getStaticText('message')"
                                ></textarea>
                              </div>

                              
                            </div>

                            <!--  <div class="form-group">
                                
                                  <label class="control-label" for="customerNumber">{{
                                    getStaticText("customer-number")
                                  }}*</label>
                                  <ValidationProvider
                                    name="company"
                                    rules="required|max:49"
                                    v-slot="{ errors }"
                                  >
                                    <input
                                      id="customerNumber"
                                      name="customerNumber"
                                      v-model="order.customerNumber"
                                      type="text"
                                      :maxlength="50"
                                      class="form-control input-md"
                                      :class="{
                                        'is-invalid': submitted && errors.length > 0,
                                        'is-invalid': errors.length >= 50
                                      }"
                                    />
                                  </ValidationProvider>
                                </div> -->
                          </div>
                        </div>
                        <!--  <div class="col-lg-6 mt-15">

                             </div> -->
                      </div>
                      <!-- EXISTING Customer ROW -->
                      <!-- NEW Customer ROW -->
                      <div class="row" v-if="!order.returningCustomer">
                        <div class="col-lg-6 mt-15">
                          <!-- Billing -->
                          <h3 class="formlabel">
                            {{ getStaticText("billingaddress") }}
                          </h3>
                          <div class="option-box">
                            <div class="form-group">
                              <label class="control-label" for="company"
                                >{{ getStaticText("company") }}*</label
                              >
                              <ValidationProvider
                                name="company"
                                rules="required_if:returningCustomer,false"
                                v-slot="{ errors }"
                                
                              >
                                <input
                                  id="company"
                                  name="company"
                                  v-model="order.company"
                                  type="text"
                                  :maxlength="50"
                                  class="form-control input-md"
                                  :class="{
                                    'is-invalid':
                                      submitted && errors.length > 0
                                  }"
                                />
                              </ValidationProvider>
                            </div>
                           
                            <div class="row">
                              <div class="form-group col-md-12">
                                <label class="control-label" for="street"
                                  >{{ getStaticText("street") }} /
                                  {{ getStaticText("housenumber") }}*</label
                                >
                                <ValidationProvider
                                  name="street"
                                  rules="required_if:returningCustomer,false|max:49"
                                  v-slot="{ errors }"
                                >
                                  <input
                                    id="street"
                                    name="street"
                                    v-model="order.street"
                                    :maxlength="49"
                                    type="text"
                                    class="form-control input-md"
                                    :class="{
                                      'is-invalid':
                                        submitted && errors.length > 0,
                                    }"
                                  />
                                </ValidationProvider>
                              </div>

                              <!--  <div class="form-group col-md-4">
                            <label class="control-label" for="housenumber"
                              >{{ getStaticText("housenumber") }}*</label
                            >
                            <ValidationProvider
                              name="housenumber"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <input
                                id="housenumber"
                                name="housenumber"
                                v-model="order.housenumber"
                                type="text"
                                class="form-control input-md"
                                :class="{
                                  'is-invalid': submitted && errors.length > 0,
                                }"
                              />
                            </ValidationProvider>
                          </div> -->
                            </div>

                            <div class="row">
                              <div class="form-group col-md-5">
                                <label class="control-label" for="zip"
                                  >{{ getStaticText("postcode") }}*</label
                                >
                                <ValidationProvider
                                  name="zip"
                                  rules="required_if:returningCustomer,false"
                                  v-slot="{ errors }"
                                >
                                  <input
                                    id="zip"
                                    name="zip"
                                    v-model="order.zip"
                                    type="text"
                                    class="form-control input-md"
                                    :class="{
                                      'is-invalid':
                                        submitted && errors.length > 0,
                                    }"
                                  />
                                </ValidationProvider>
                              </div>

                              <div class="form-group col-md-7">
                                <label class="control-label" for="place"
                                  >{{ getStaticText("place") }}*</label
                                >
                                <ValidationProvider
                                  name="place"
                                  rules="required_if:returningCustomer,false|max:49"
                                  v-slot="{ errors }"
                                >
                                  <input
                                    id="place"
                                    name="place"
                                    v-model="order.place"
                                    type="text"
                                    class="form-control input-md"
                                    :class="{
                                      'is-invalid':
                                        submitted && errors.length > 0,
                                    }"
                                  />
                                </ValidationProvider>
                              </div>
                            </div>
                            <div class="form-group">
                              <label class="control-label" for="country">{{
                                getStaticText("country")
                              }}</label>
                              <ValidationProvider
                                name="country"
                                rules="required_if:returningCustomer,false"
                                v-slot="{ errors }"
                              >
                                <select
                                  id="country"
                                  name="country"
                                  v-model="order.country"
                                  :selected="defaultCountry"
                                  class="form-control input-md"
                                  :class="{
                                    'is-invalid':
                                      submitted && errors.length > 0,
                                  }"
                                >
                                  <option
                                    v-for="(country, i) in countries"
                                    v-bind:value="country.key"
                                    :key="i"
                                  >
                                    {{ country.value }}
                                  </option>
                                </select>
                              </ValidationProvider>
                            </div>
                            <!--  <div class="row">
                          <div class="form-group col-md-12">
                            <label class="control-label" for="customerNumber">{{
                              getStaticText("customer-number")
                            }}</label>
                            <input
                              id="customerNumber"
                              name="customerNumber"
                              v-model="order.customerNumber"
                              type="text"
                              class="form-control input-md"
                            />
                          </div>
                        </div> -->

                            
                          </div>
                          <!-- //Billing -->
                        </div>
                        <div class="col-lg-6 mt-15">
                          <!-- Contact -->
                          <h3 class="formlabel">
                            {{ getStaticText("contact-person") }}
                            <!-- <small>{{ getStaticText("optional") }}</small> -->
                          </h3>
                          <div class="option-box">
                             <div class="row">
                              <div class="form-group col-md-4">
                                <label class="control-label" for="salutation"
                                  >{{ getStaticText("salutation") }}*</label
                                >
                                <ValidationProvider
                                  name="salutation"
                                  rules="required_if:returningCustomer,false|oneOf:sir,madam"
                                  v-slot="{ errors }"
                                >
                                  <select
                                    id="salutation"
                                    class="form-control"
                                    name="salutation"
                                    v-model="order.salutation"
                                    :class="{
                                      'is-invalid':
                                        submitted && errors.length > 0,
                                    }"
                                  >
                                    <option>
                                      -{{ getStaticText("choose") }}-
                                    </option>
                                    <option value="sir">
                                      {{ getStaticText("sir") }}
                                    </option>
                                    <option value="madam">
                                      {{ getStaticText("madam") }}
                                    </option>
                                  </select>
                                </ValidationProvider>
                              </div>
                              <div class="form-group col-md-4">
                                <label class="control-label" for="firstName"
                                  >{{ getStaticText("firstname") }}*</label
                                >
                                <ValidationProvider
                                  name="firstName"
                                  rules="required_if:returningCustomer,false|max:49"
                                  v-slot="{ errors }"
                                >
                                  <input
                                    id="firstName"
                                    name="firstName"
                                    v-model="order.firstName"
                                    type="text"
                                    class="form-control input-md"
                                    :class="{
                                      'is-invalid':
                                        submitted && errors.length > 0,
                                    }"
                                  />
                                </ValidationProvider>
                              </div>

                              <div class="form-group col-md-4">
                                <label class="control-label" for="lastName"
                                  >{{ getStaticText("name") }}*</label
                                >
                                <ValidationProvider
                                  name="lastName"
                                  rules="required_if:returningCustomer,false|max:49"
                                  v-slot="{ errors }"
                                >
                                  <input
                                    id="lastName"
                                    name="lastName"
                                    v-model="order.lastName"
                                    type="text"
                                    class="form-control input-md"
                                    :class="{
                                      'is-invalid':
                                        submitted && errors.length > 0,
                                    }"
                                  />
                                </ValidationProvider>
                              </div>
                            </div>


                              <div class="row">
                              <div class="form-group col-md-6">
                                <label class="control-label" for="tel"
                                  >{{ getStaticText("phone-number") }}*</label
                                >
                                <ValidationProvider
                                  name="phone"
                                  rules="required_if:returningCustomer,false"
                                  v-slot="{ errors }"
                                >
                                  <input
                                    id="tel"
                                    name="phone"
                                    v-model="order.phone"
                                    type="text"
                                    class="form-control input-md"
                                    :class="{
                                      'is-invalid':
                                        submitted && errors.length > 0,
                                    }"
                                  />
                                </ValidationProvider>
                              </div>
                              <div class="form-group col-md-6">
                                <label class="control-label" for="email"
                                  >{{ getStaticText("emailaddress") }}*</label
                                >
                                <ValidationProvider
                                  name="email"
                                  rules="required_if:returningCustomer,false|email"
                                  v-slot="{ errors }"
                                >
                                  <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    v-model="order.email"
                                    class="form-control input-md"
                                    :class="{
                                      'is-invalid':
                                        submitted && errors.length > 0,
                                    }"
                                  />
                                </ValidationProvider>
                              </div>
                            </div>
                            
                            <div class="row" v-if="false">
                              <div class="form-group col-lg-4">
                                <label
                                  class="control-label"
                                  for="contact_salutation"
                                  >{{ getStaticText("salutation") }}</label
                                >
                                <select
                                  id="contact_salutation"
                                  class="form-control"
                                  name="contact_salutation"
                                  v-model="order.contact_salutation"
                                >
                                  <option value="sir">
                                    {{ getStaticText("sir") }}
                                  </option>
                                  <option value="madam">
                                    {{ getStaticText("madam") }}
                                  </option>
                                </select>
                              </div>
                              <div class="form-group col-lg-4">
                                <label
                                  class="control-label"
                                  for="contact_firstName"
                                  >{{ getStaticText("firstname") }}</label
                                >
                                <input
                                  id="contact_firstName"
                                  name="contact_firstName"
                                  type="text"
                                  class="form-control input-md"
                                  v-model="order.contact_firstName"
                                />
                              </div>

                              <div class="form-group col-lg-4">
                                <label
                                  class="control-label"
                                  for="contact_lastName"
                                  >{{ getStaticText("name") }}</label
                                >
                                <input
                                  id="contact_lastName"
                                  name="contact_lastName"
                                  type="text"
                                  class="form-control input-md"
                                  v-model="order.contact_lastName"
                                />
                              </div>

                              <div class="form-group col-lg-6">
                                <label class="control-label" for="tel">{{
                                  getStaticText("phone-number")
                                }}</label>
                                <input
                                  id="contact_phone"
                                  name="contact_phone"
                                  type="text"
                                  class="form-control input-md"
                                  v-model="order.contact_phone"
                                />
                              </div>

                              <div class="form-group col-lg-6">
                                <label
                                  class="control-label"
                                  for="contact_email"
                                  >{{ getStaticText("emailaddress") }}</label
                                >
                                <ValidationProvider
                                  name="contact_email"
                                  rules="email"
                                  v-slot="{ errors }"
                                >
                                  <input
                                    id="contact_email"
                                    name="contact_email"
                                    type="email"
                                    class="form-control input-md"
                                    v-model="order.contact_email"
                                    :class="{
                                      'is-invalid':
                                        submitted && errors.length > 0,
                                    }"
                                  />
                                </ValidationProvider>
                              </div>
                            </div>
                            <!-- // row -->
                          </div>
                          <!-- //Contact -->

                          <!-- Payments -->
                          <h3 class="formlabel mt-15">
                            {{ getStaticText("headlinepayment") }}
                          </h3>
                          <div class="option-box">
                            <div class="row">
                              <div class="form-group col-md-12">
                                <label for="currency"
                                  >{{ getStaticText("currency") }}*</label
                                >
                                <ValidationProvider
                                  name="currency"
                                  rules="required_if:returningCustomer,false|oneOf:1,2"
                                  v-slot="{ errors }"
                                >
                                  <select
                                    id="currency"
                                    class="form-control"
                                    name="currency"
                                    v-model="order.currency"
                                    :class="{
                                      'is-invalid':
                                        submitted && errors.length > 0,
                                    }"
                                  >
                                    <option value="1">
                                      {{ getStaticText("euro") }}
                                    </option>
                                    <option value="2">
                                      {{ getStaticText("dollar") }}
                                    </option>
                                  </select>
                                </ValidationProvider>
                              </div>
                              <!-- 
													<div class="form-group col-md-6">
														<label for="delivery">{{ getStaticText('delivery')}}</label>
														<select id="delivery" class="form-control" name="delivery" v-model="order.delivery" v-validate="'required|included:1,2'" data-vv-as="selected" :class="{ 'is-invalid': submitted && errors.length > 0 }">
															<option>{{ getStaticText('choose')}}</option>
															<option value="1">{{ getStaticText('shipping1')}}</option>
															<option value="2"> {{ getStaticText('shipping2')}}</option>
														</select>
													</div> -->

                              <div class="form-group col-md-12">
                                <label
                                  class="control-label"
                                  for="orderMessage"
                                  >{{ getStaticText("wishes") }}</label
                                >
                                <textarea
                                  style="height: 100px"
                                  class="form-control"
                                  id="orderMessage"
                                  name="orderMessage"
                                  v-model="order.msg"
                                  :placeholder="getStaticText('message')"
                                ></textarea>
                              </div>
                            </div>
                            <!-- // row -->
                          </div>
                          <!-- //Payments -->
                        </div>
                      </div>
                      <!-- NEW Customer ROW -->
                    </div>

                    <div class="col-lg-3 mt-15">
                      <!-- Contact -->
                      <h3 class="formlabel">{{ getStaticText("curconf") }}</h3>
                      <div class="option-box p-0 checkout-cart">
                        <cart-list />
                      </div>
                      <!-- //Contact -->
                    </div>
                  </div>
                  <!-- // row -->
                  <div class="row mb-50">
                    <div class="col-lg-8 mt-15">
                      <ValidationProvider
                        name="terms"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <div
                          class="checkbox p-15"
                          :class="{
                            'alert-danger': submitted && errors.length > 0,
                          }"
                        >
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              name="terms"
                              v-model="order.terms"
                            />
                            <span class="cr"
                              ><i class="cr-icon fa fa-check"></i
                            ></span>
                            {{ getStaticText("datenschutzcheckout") }}*
                          </label>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-lg-4 mt-15">
                      <a
                        @click="submitOrder"
                        id="confirmorder"
                        class="btn btn-primary btn-block pointer"
                        :class="{
                            'disabled': !order.terms,
                          }"
                        :disabled="!order.terms"
                        >{{
                          configuratorEditMode
                            ? "Update"
                            : getStaticText("buttoncheckout")
                        }}</a
                      >
                      <loading
                        v-if="this.$store.checkoutready == false"
                      ></loading>
                    </div>

                    <div class="col-12 mt-15">
                      <p>{{ getStaticText("required") }}</p>
                    </div>
                  </div>
                </form>

                <div class="alert alert-danger" v-if="error">
                  <p>
                    <strong
                      ><i class="fa fa-warning"></i>
                      {{ getStaticText("errorSubmittingData") }}</strong
                    >
                  </p>
                </div>
              </div>
            </ValidationObserver>
          </div>

          <!-- OLD FORM -->
          <div class="row" v-if="false">
            <ValidationObserver ref="form">
              <div class="col-12 col-lg-12 mt-15">
                <!-- <h2>Bestellung Ihrer Aconity Lab Konfiguration</h2> -->
                <h2>{{ getStaticText("headlinecheckout") }}</h2>

                <p>{{ getStaticText("textcheckout") }}</p>

                <form class="form-horizontal mt-50" v-if="!error">
                  <div class="row">
                    <div class="col-lg-5 mt-15">
                      <!-- Billing -->
                      <h3 class="formlabel">
                        {{ getStaticText("billingaddress") }}
                      </h3>
                      <div class="option-box">
                        <div class="form-group">
                          <label class="control-label" for="company"
                            >{{ getStaticText("company") }}*</label
                          >
                          <ValidationProvider
                            name="company"
                            rules="required|max:49"
                            v-slot="{ errors }"
                          >
                            <input
                              id="company"
                              name="company"
                              v-model="order.company"
                              type="text"
                              :maxlength="50"
                              class="form-control input-md"
                              :class="{
                                'is-invalid': submitted && errors.length > 0,
                                'is-invalid': errors.length >= 50,
                              }"
                            />
                          </ValidationProvider>
                        </div>
                        <div class="row">
                          <div class="form-group col-md-4">
                            <label class="control-label" for="salutation"
                              >{{ getStaticText("salutation") }}*</label
                            >
                            <ValidationProvider
                              name="salutation"
                              rules="required|oneOf:sir,madam"
                              v-slot="{ errors }"
                            >
                              <select
                                id="salutation"
                                class="form-control"
                                name="salutation"
                                v-model="order.salutation"
                                :class="{
                                  'is-invalid': submitted && errors.length > 0,
                                }"
                              >
                                <option>-{{ getStaticText("choose") }}-</option>
                                <option value="sir">
                                  {{ getStaticText("sir") }}
                                </option>
                                <option value="madam">
                                  {{ getStaticText("madam") }}
                                </option>
                              </select>
                            </ValidationProvider>
                          </div>
                          <div class="form-group col-md-4">
                            <label class="control-label" for="firstName"
                              >{{ getStaticText("firstname") }}*</label
                            >
                            <ValidationProvider
                              name="firstName"
                              rules="required|max:49"
                              v-slot="{ errors }"
                            >
                              <input
                                id="firstName"
                                name="firstName"
                                v-model="order.firstName"
                                type="text"
                                class="form-control input-md"
                                :class="{
                                  'is-invalid': submitted && errors.length > 0,
                                }"
                              />
                            </ValidationProvider>
                          </div>

                          <div class="form-group col-md-4">
                            <label class="control-label" for="lastName"
                              >{{ getStaticText("name") }}*</label
                            >
                            <ValidationProvider
                              name="lastName"
                              rules="required|max:49"
                              v-slot="{ errors }"
                            >
                              <input
                                id="lastName"
                                name="lastName"
                                v-model="order.lastName"
                                type="text"
                                class="form-control input-md"
                                :class="{
                                  'is-invalid': submitted && errors.length > 0,
                                }"
                              />
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-md-12">
                            <label class="control-label" for="street"
                              >{{ getStaticText("street") }} /
                              {{ getStaticText("housenumber") }}*</label
                            >
                            <ValidationProvider
                              name="street"
                              rules="required|max:49"
                              v-slot="{ errors }"
                            >
                              <input
                                id="street"
                                name="street"
                                v-model="order.street"
                                :maxlength="49"
                                type="text"
                                class="form-control input-md"
                                :class="{
                                  'is-invalid': submitted && errors.length > 0,
                                }"
                              />
                            </ValidationProvider>
                          </div>

                          <!--  <div class="form-group col-md-4">
                            <label class="control-label" for="housenumber"
                              >{{ getStaticText("housenumber") }}*</label
                            >
                            <ValidationProvider
                              name="housenumber"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <input
                                id="housenumber"
                                name="housenumber"
                                v-model="order.housenumber"
                                type="text"
                                class="form-control input-md"
                                :class="{
                                  'is-invalid': submitted && errors.length > 0,
                                }"
                              />
                            </ValidationProvider>
                          </div> -->
                        </div>

                        <div class="row">
                          <div class="form-group col-md-5">
                            <label class="control-label" for="zip"
                              >{{ getStaticText("postcode") }}*</label
                            >
                            <ValidationProvider
                              name="zip"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <input
                                id="zip"
                                name="zip"
                                v-model="order.zip"
                                type="text"
                                class="form-control input-md"
                                :class="{
                                  'is-invalid': submitted && errors.length > 0,
                                }"
                              />
                            </ValidationProvider>
                          </div>

                          <div class="form-group col-md-7">
                            <label class="control-label" for="place"
                              >{{ getStaticText("place") }}*</label
                            >
                            <ValidationProvider
                              name="place"
                              rules="required|max:49"
                              v-slot="{ errors }"
                            >
                              <input
                                id="place"
                                name="place"
                                v-model="order.place"
                                type="text"
                                class="form-control input-md"
                                :class="{
                                  'is-invalid': submitted && errors.length > 0,
                                }"
                              />
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="control-label" for="country">{{
                            getStaticText("country")
                          }}</label>
                          <ValidationProvider
                            name="country"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <select
                              id="country"
                              name="country"
                              v-model="order.country"
                              :selected="defaultCountry"
                              class="form-control input-md"
                              :class="{
                                'is-invalid': submitted && errors.length > 0,
                              }"
                            >
                              <option
                                v-for="(country, i) in countries"
                                v-bind:value="country.key"
                                :key="i"
                              >
                                {{ country.value }}
                              </option>
                            </select>
                          </ValidationProvider>
                        </div>
                        <div class="row">
                          <div class="form-group col-md-12">
                            <label class="control-label" for="customerNumber">{{
                              getStaticText("customer-number")
                            }}</label>
                            <input
                              id="customerNumber"
                              name="customerNumber"
                              v-model="order.customerNumber"
                              type="text"
                              class="form-control input-md"
                            />
                          </div>
                        </div>

                        <div class="row">
                          <div class="form-group col-md-6">
                            <label class="control-label" for="tel"
                              >{{ getStaticText("phone-number") }}*</label
                            >
                            <ValidationProvider
                              name="phone"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <input
                                id="tel"
                                name="phone"
                                v-model="order.phone"
                                type="text"
                                class="form-control input-md"
                                :class="{
                                  'is-invalid': submitted && errors.length > 0,
                                }"
                              />
                            </ValidationProvider>
                          </div>
                          <div class="form-group col-md-6">
                            <label class="control-label" for="email"
                              >{{ getStaticText("emailaddress") }}*</label
                            >
                            <ValidationProvider
                              name="email"
                              rules="required|email"
                              v-slot="{ errors }"
                            >
                              <input
                                id="email"
                                name="email"
                                type="email"
                                v-model="order.email"
                                class="form-control input-md"
                                :class="{
                                  'is-invalid': submitted && errors.length > 0,
                                }"
                              />
                            </ValidationProvider>
                          </div>
                        </div>
                      </div>
                      <!-- //Billing -->
                    </div>
                    <!-- col -->
                    <div class="col-lg-4 mt-15">
                      <!-- Contact -->
                      <h3 class="formlabel">
                        {{ getStaticText("contact-person") }}
                        <small>{{ getStaticText("optional") }}</small>
                      </h3>
                      <div class="option-box">
                        <div class="row">
                          <div class="form-group col-lg-4">
                            <label
                              class="control-label"
                              for="contact_salutation"
                              >{{ getStaticText("salutation") }}</label
                            >
                            <select
                              id="contact_salutation"
                              class="form-control"
                              name="contact_salutation"
                              v-model="order.contact_salutation"
                            >
                              <option value="sir">
                                {{ getStaticText("sir") }}
                              </option>
                              <option value="madam">
                                {{ getStaticText("madam") }}
                              </option>
                            </select>
                          </div>
                          <div class="form-group col-lg-4">
                            <label
                              class="control-label"
                              for="contact_firstName"
                              >{{ getStaticText("firstname") }}</label
                            >
                            <input
                              id="contact_firstName"
                              name="contact_firstName"
                              type="text"
                              class="form-control input-md"
                              v-model="order.contact_firstName"
                            />
                          </div>

                          <div class="form-group col-lg-4">
                            <label
                              class="control-label"
                              for="contact_lastName"
                              >{{ getStaticText("name") }}</label
                            >
                            <input
                              id="contact_lastName"
                              name="contact_lastName"
                              type="text"
                              class="form-control input-md"
                              v-model="order.contact_lastName"
                            />
                          </div>

                          <div class="form-group col-lg-6">
                            <label class="control-label" for="tel">{{
                              getStaticText("phone-number")
                            }}</label>
                            <input
                              id="contact_phone"
                              name="contact_phone"
                              type="text"
                              class="form-control input-md"
                              v-model="order.contact_phone"
                            />
                          </div>

                          <div class="form-group col-lg-6">
                            <label class="control-label" for="contact_email">{{
                              getStaticText("emailaddress")
                            }}</label>
                            <ValidationProvider
                              name="contact_email"
                              rules="email"
                              v-slot="{ errors }"
                            >
                              <input
                                id="contact_email"
                                name="contact_email"
                                type="email"
                                class="form-control input-md"
                                v-model="order.contact_email"
                                :class="{
                                  'is-invalid': submitted && errors.length > 0,
                                }"
                              />
                            </ValidationProvider>
                          </div>
                        </div>
                        <!-- // row -->
                      </div>
                      <!-- //Contact -->

                      <!-- Payments -->
                      <h3 class="formlabel mt-15">
                        {{ getStaticText("headlinepayment") }}
                      </h3>
                      <div class="option-box">
                        <div class="row">
                          <div class="form-group col-md-12">
                            <label for="currency"
                              >{{ getStaticText("currency") }}*</label
                            >
                            <ValidationProvider
                              name="currency"
                              rules="required|oneOf:1,2"
                              v-slot="{ errors }"
                            >
                              <select
                                id="currency"
                                class="form-control"
                                name="currency"
                                v-model="order.currency"
                                :class="{
                                  'is-invalid': submitted && errors.length > 0,
                                }"
                              >
                                <option value="1">
                                  {{ getStaticText("euro") }}
                                </option>
                                <option value="2">
                                  {{ getStaticText("dollar") }}
                                </option>
                              </select>
                            </ValidationProvider>
                          </div>
                          <!-- 
													<div class="form-group col-md-6">
														<label for="delivery">{{ getStaticText('delivery')}}</label>
														<select id="delivery" class="form-control" name="delivery" v-model="order.delivery" v-validate="'required|included:1,2'" data-vv-as="selected" :class="{ 'is-invalid': submitted && errors.length > 0 }">
															<option>{{ getStaticText('choose')}}</option>
															<option value="1">{{ getStaticText('shipping1')}}</option>
															<option value="2"> {{ getStaticText('shipping2')}}</option>
														</select>
													</div> -->

                          <div class="form-group col-md-12">
                            <label class="control-label" for="orderMessage">{{
                              getStaticText("wishes")
                            }}</label>
                            <textarea
                              style="height: 100px"
                              class="form-control"
                              id="orderMessage"
                              name="orderMessage"
                              v-model="order.msg"
                              :placeholder="getStaticText('message')"
                            ></textarea>
                          </div>
                        </div>
                        <!-- // row -->
                      </div>
                      <!-- //Payments -->
                    </div>

                    <div class="col-lg-3 mt-15">
                      <!-- Contact -->
                      <h3 class="formlabel">{{ getStaticText("curconf") }}</h3>
                      <div class="option-box p-0 checkout-cart">
                        <cart-list />
                      </div>
                      <!-- //Contact -->
                    </div>
                  </div>
                  <!-- // row -->
                  <div class="row mb-50">
                    <div class="col-lg-8 mt-15">
                      <ValidationProvider
                        name="terms"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <div
                          class="checkbox p-15"
                          :class="{
                            'alert-danger': submitted && errors.length > 0,
                          }"
                        >
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              name="terms"
                              v-model="order.terms"
                            />
                            <span class="cr"
                              ><i class="cr-icon fa fa-check"></i
                            ></span>
                            {{ getStaticText("datenschutzcheckout") }}*
                          </label>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-lg-4 mt-15">
                      <a
                        @click="submitOrder"
                        id="confirmorder"
                        class="btn btn-primary btn-block pointer"
                        >{{
                          configuratorEditMode
                            ? "Update"
                            : getStaticText("buttoncheckout")
                        }}</a
                      >
                      <loading
                        v-if="this.$store.checkoutready == false"
                      ></loading>
                    </div>

                    <div class="col-12 mt-15">
                      <p>{{ getStaticText("required") }}</p>
                    </div>
                  </div>
                </form>

                <div class="alert alert-danger" v-if="error">
                  <p>
                    <strong
                      ><i class="fa fa-warning"></i>
                      {{ getStaticText("errorSubmittingData") }}</strong
                    >
                  </p>
                </div>
              </div>
            </ValidationObserver>
          </div>
          <!-- OLD FORM -->
        </div>
      </main>
      <!-- /.main -->
    </div>
    <!-- /.content -->
  </div>
  <!-- /.wrap -->
</template>

<script>
import { mapGetters } from "vuex";
import Stage from "@/components/configurator/Stage.vue";
import Navbar from "@/components/checkout/Navbar.vue";
import CartList from "@/components/configurator/Cart.vue";
import router from "../router";
import { apiService } from "@/services/http";
import configuratorService from "@/services/configurator";
import { load } from "recaptcha-v3";
import Loading from "@/components/system/LoadingAnimation.vue";
import { getTodayDateFormatted } from "@/utils/common-utils";


export default {
  name: "CheckoutView",
  components: {
    Stage,
    Navbar,
    CartList,
    Loading,
  },
  computed: {
    ...mapGetters([
      "cart",
      "getStaticText",
      "getStaticTextJson",
      "selectedLanguage",
      "configuratorEditMode",
      "configuratorEditDocumentData",
      "configuratorEditDocument",
      "getCountriesList",
      "getGId"
    ]),
  },
  data() {
    return {
      error: false,
      order: {
        returningCustomer: false,
        customerNumber: "",
        company: "",
        salutation: "",
        firstName: "",
        lastName: "",
        street: "",
        housenumber: "",
        zip: "",
        place: "",
        country: "",
        phone: "",
        email: "",
        contact_salutation: "",
        contact_firstName: "",
        contact_lastName: "",
        contact_phone: "",
        contact_email: "",
        currency: "",
        terms: false,
        msg: "",
      },
      submitted: false,
      countries: [],
      gtmId: null,
      defaultCountry: "",
    };
  },

  created() {
    // const countriesObj = this.getStaticTextJson("countries");
    this.gtmId = this.getGId;

    this.countries = [];

    let defaultCountry = [];
    defaultCountry["DE"] = "DE";
    defaultCountry["EN"] = "DE";

    const checkOutCountryList = this.getCountriesList
      .map(({ cca2, name }) => ({ key: cca2, value: name.common }))
      .sort((a, b) => (a.value > b.value ? 1 : -1));

    this.countries = checkOutCountryList;

    this.order.country = defaultCountry[this.$store.getters.selectedLanguage];

    if (this.configuratorEditMode) {
      this.order = {
        ...this.configuratorEditDocumentData,
      };
      console.warn("this.order", this.order);
    }
  },

  methods: {
    async validateCaptcha() {
      let valid = false;


      const recaptcha = await load("6LcNz64iAAAAAJoK7PJ--f1HirkmiBHs6RjR_eg9");
      await recaptcha.execute();

      this.submitted = true;

      const validCaptcha = await this.$refs.form.validate();

      console.log("validCaptcha", validCaptcha);

      valid = validCaptcha;

      if (validCaptcha) {
        console.log("FORM valid!");
        // Valid eh? Lets check if you are a bot!
        this.$store.commit("SET_orderData", this.order);

        let res = await this.sendOrder();

        console.log(res);
      } else {
        console.log("FORM not valid!");
      }

      return valid;
    },
    submitOrder() {
      console.group("submitOrder");
      console.log("order", this.order);

     

      this.$store.checkoutready = false;
      this.submitted = true;

      this.$gtm.trackEvent({
        event: "configurator_quote_form_submitted"
      });

      const enableCaptcha = process.env.VUE_APP_ENABLE_CAPTCHA;

      if (enableCaptcha) {
        this.validateCaptcha().then((res) => {
          console.log(res);
        });
      } else {
        this.sendOrder().then((res) => {
          console.log(res);
        });
      }

      console.groupEnd();
    },

    sendOrder() {
      const checkoutData = {
        ga_clientId: this.gtmId,
        returningCustomer: this.order.returningCustomer,
        language: this.$store.getters.selectedLanguage,
        selectedMachineId: this.$store.getters.selectedMachine.id,
        address: {
          company: this.order.company,
          salutation: this.order.salutation,
          firstName: this.order.firstName,
          lastName: this.order.lastName,
          street: this.order.street + " " + this.order.housenumber,
          country: this.order.country,
          zip: this.order.zip,
          city: this.order.place,
          date: getTodayDateFormatted(),
          customerId: this.order.customerNumber,
          phone: this.order.phone,
          email: this.order.email,
        },
        contact: {
          salutation: this.order.contact_salutation,
          firstName: this.order.contact_firstName,
          lastName: this.order.contact_lastName,
          phone: this.order.contact_phone,
          email: this.order.contact_email,
        },
        payment: {
          currency: this.order.currency === "1" ? "EUR" : "USD",
          message: this.order.msg,
        },
        articles: configuratorService.prepareConfiguratorCheckoutCartOrder(
          this.cart
        ),
      };

      console.warn("checkoutData ----------: ", checkoutData);
      console.warn("articles -: ", checkoutData.articles);

      if (this.configuratorEditMode) {
        return apiService
          .put(
            `/orders/${this.configuratorEditDocument.requestId}/inquiries/${this.configuratorEditDocument.documentId}`,
            checkoutData
          )
          .then((response) => {
            console.log("response", response);
            this.$store.checkoutready = true;
            router.push("/DE/ThankYou");
          })
          .catch((error) => {
            this.error = true;
            console.log(error);
          });
      }

      return apiService.post("/checkoutOrder", checkoutData)
        .then((response) => {
          console.log("response", response);
          this.$store.checkoutready = true;
          router.push("/DE/ThankYou");
        })
        .catch((error) => {
          this.error = true;
          console.log(error);
        });
    },
  },
};
</script>
