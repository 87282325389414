export default {
  init: false,
  ready: false,
  gid: null,
  selectedLanguage: "DE",
  authenticated: false,
  authToken: "",
  countriesList: [],
  session: {},
};
