import configuratorService from "@/services/configurator";
import storeUtils from "@/utils/store-utils";

export default {
  TOGGLE_CONFIGURATOR_MODE(state, { value }) {
    state.isInConfiguratorMode = value;
  },
  SET_CONFIGURATOR_EDIT_MODE(state, payload) {
    const { editMode, debugMode } = payload;

    state.configuratorDebugMode = debugMode;

    state.configuratorEditMode = editMode;
  },

  SET_MACHINES(state, { machines }) {
    const availableMachines = [];

    // Only set active machines
    machines.map((machine) => {
      if (machine.is_active) {
        const configuratorMachine = {
          id: machine.id,
          name: machine.name,
          default: machine.is_default,
          displayName: machine.display_name,
          description: machine.description,
        };

        availableMachines.push(configuratorMachine);
      }
    });

    state.allMachines = availableMachines;
  },

  SET_DEFAULT_MACHINE(state) {
    const defaultMachine = state.allMachines.find(
      (machine) => machine.default === true
    );

    if (defaultMachine) {
      state.defaultMachine = defaultMachine;
    } else {
      state.defaultMachine = state.allMachines[0];
    }
  },

  SET_ALTERNATIVE_ARTICLES(state, altArticles) {
    state.alternativeArticles = altArticles;
  },

  SET_DATA_ARTICLE_TYPES(state, { types }) {
    state.allArticleTypes = types;
  },
  SET_DATA_CATEGORIES(state, { categories }) {
    const allCategories = [];

    const sortedCategories = categories.sort(
      (a, b) => a.category_order - b.category_order
    );

    sortedCategories.forEach((category) => {
      const categoryTransation = [];
      const categoryDescriptionTranslation = [];

      for (const [key, value] of Object.entries(category.title)) {
        categoryTransation.push({ lang: key.toUpperCase(), value: value });
      }

      for (const [key, value] of Object.entries(category.description)) {
        categoryDescriptionTranslation.push({
          lang: key.toUpperCase(),
          value: value,
        });
      }

      const configurtorMultiLangCategory = {
        id: category.id,
        category_order: category.category_order,
        isHidden: category.is_hidden,
        name: [...categoryTransation],
        description: [...categoryDescriptionTranslation],
      };

      allCategories.push(configurtorMultiLangCategory);
    });

    state.allCategories = allCategories;
  },

  SET_DATA_STATICTEXT(state, { statictexts }) {
    const appStaticTexts = [];

    statictexts.forEach((statictext) => {
      if (statictext.iso == "en") {
        const categoryTextDE = statictexts.find(
          (statictext_) =>
            statictext_.iso == "de" && statictext_.name == statictext.name
        );

        appStaticTexts.push({
          id: statictext.id,
          name: statictext.name,
          text: [
            {
              lang: categoryTextDE.iso.toUpperCase(),
              value: categoryTextDE.text,
            },
            { lang: statictext.iso.toUpperCase(), value: statictext.text },
          ],
          json: [
            {
              lang: categoryTextDE.iso.toUpperCase(),
              value: categoryTextDE.json,
            },
            { lang: statictext.iso.toUpperCase(), value: statictext.json },
          ],
        });
      }
    });

    state.allStaticText = appStaticTexts;
  },

  /**
   * Set available options for current selected machine
   * Create new configurator options for current machine options
   * @param {*} state
   * @param {*} param1
   */
  SET_CONFIGURATOR_SELECTED_MACHINE(state, { machine, rootState }) {
    console.groupCollapsed("mutatuon - SET_CONFIGURATOR_SELECTED_MACHINE");
    console.log("state", state);
    console.log("rootState", rootState);
    console.log("machine", machine);

    const allMachines = state.allMachines;
    const selectedMachine = allMachines.find(
      (machine_) => machine_.name === machine
    );

    if (selectedMachine) {
      state.selectedMachine = selectedMachine;
    }

    console.log("state.selectedMachine", state.selectedMachine);

    console.log("state.selectedMachineOptions", state.selectedMachineOptions);
    console.groupEnd();
  },

  SET_CONFIGURATOR_FIRST_MACHINE_SELECTION(state, { value, rootState }) {
    console.groupCollapsed(
      "mutatuon - SET_CONFIGURATOR_FIRST_MACHINE_SELECTION"
    );
    console.log("state", state);
    console.log("value", value);
    console.log("rootState", rootState);

    state.firstMachineSelected = value;

    sessionStorage.setItem("firstMachineSelected", value);

    console.groupEnd();
  },

  SET_CONFIGURATOR_DOCUMENT(state, { document, requestId }) {
    console.groupCollapsed("mutatuon - SET_CONFIGURATOR_DOCUMENT");
    console.log("state", state);
    console.log("document", document);
    console.log("requestId", requestId);

    const parsedConfiguratorDocumentData = configuratorService.parseInquiryDocumentToConfiguratorData(
      document,
      requestId
    );

    console.log(
      "systemTypesystemTypesystemTypesystemType: ",
      document.systemType
    );

    console.log(
      "parsedConfiguratorDocumentData",
      parsedConfiguratorDocumentData
    );

    state.configuratorEditDocument = parsedConfiguratorDocumentData;

    console.log(
      "state.configuratorEditDocument",
      state.configuratorEditDocument
    );
    console.groupEnd();
  },

  /**
   * Make options for the selected machine
   * @param {*} state
   * @param {*} param1
   */
  SET_SELECTED_MACHINE_OPTIONS(state, { machineOptions, imageLayers }) {
    console.groupCollapsed("mutatuon - SET_SELECTED_MACHINE_OPTIONS");
    console.log("machineOptions", machineOptions);
    console.log("imageLayers", imageLayers);

    const selectedMachineOptions = machineOptions;
    const allCategories = state.allCategories;

    const selectedMachineConfiguratorOptions = configuratorService.buildConfiguratorMachineOptions(
      selectedMachineOptions,
      imageLayers
    );
    console.log(
      "selectedMachineConfiguratorOptions",
      selectedMachineConfiguratorOptions
    );

    state.selectedMachineCategories = configuratorService.getConfiguratorMachineOptionsCategoryList(
      selectedMachineOptions,
      allCategories
    );
    state.selectedMachineOptions = [...selectedMachineConfiguratorOptions];

    console.log("state.selectedMachineOptions", state.selectedMachineOptions);
    console.groupEnd();
  },

  /**
   * Set data for selected machine options
   * Add data to machine options from components
   *
   * @param {*} state
   * @param {*} param1
   */
  SET_SELECTED_MACHINE_CONFIGURATOR_DATA(state, { rootState }) {
    console.groupCollapsed("mutatuon - SET_SELECTED_MACHINE_CONFIGURATOR_DATA");
    console.log(rootState);

    // const allComponents = rootState.app.allComponents;
    // const allMachineImageLayers = rootState.app.allMachineImageLayers;
    const selectedMachineConfigurator = state.selectedMachineOptions;
    //const selectedMachine = state.selectedMachine;
    const selectedMachineImageLayers = state.selectedMachineImageLayers;
    /*  const filetedMahcineLayers = allMachineImageLayers.filter(
      (layer) => layer.machine.id === selectedMachine.id
    ); */

    selectedMachineConfigurator.forEach((option) => {
      console.log("Adding component Data to option: ", option);

      // Search for component for this option
      //const dataComponent = allComponents.find( (comp) => comp.id === option.componentId  );
      const dataComponent = option.article;

      if (dataComponent) {
        const dataComponentTranslation = dataComponent.translations || [];
        const componentPictograms =
          dataComponent.pictogram !== undefined ? dataComponent.pictogram : [];
        const componentThumbnails =
          dataComponent.thumbnail !== undefined ? dataComponent.thumbnail : [];
        const componentImages =
          dataComponent.images !== undefined ? dataComponent.images : [];

        option.name.push({ lang: "EN", value: dataComponent.caption });
        option.caption.push({ lang: "EN", value: dataComponent.caption });
        option.specs.push({ lang: "EN", value: dataComponent.specs });
        option.short_desc.push({
          lang: "EN",
          value: dataComponent.description,
        });

        option.type = dataComponent.article_type.type;

        dataComponentTranslation.forEach((componentTransation) => {
          option.name.push({
            lang: componentTransation.iso.toUpperCase(),
            value: componentTransation.caption,
          });
          option.caption.push({
            lang: componentTransation.iso.toUpperCase(),
            value: componentTransation.caption,
          });
          option.specs.push({
            lang: componentTransation.iso.toUpperCase(),
            value: componentTransation.specs,
          });
          option.short_desc.push({
            lang: componentTransation.iso.toUpperCase(),
            value: componentTransation.description,
          });
        });

        componentPictograms.forEach((componentPictogram) => {
          option.pictogram = `${process.env.VUE_APP_API_BASE_FILES}${componentPictogram.url}`;
        });

        componentThumbnails.forEach((componentThumbnail) => {
          if (componentThumbnail.target.includes("default")) {
            option.thumbnail = `${process.env.VUE_APP_API_BASE_FILES}${componentThumbnail.url}`;
          }

          if (componentThumbnail.target.includes("thumbnail_n")) {
            option.thumbnailMulti = `${process.env.VUE_APP_API_BASE_FILES}${componentThumbnail.url}`;
          }

          option.thumbnails.push(
            `${process.env.VUE_APP_API_BASE_FILES}${componentThumbnail.url}`
          );
        });

        /**
         *  Add normal Option images
         */
        componentImages.forEach((componentImage) => {
          console.log("componentImage", componentImage);

          const optionImage = {
            url: `${process.env.VUE_APP_API_BASE_FILES}${componentImage.url}`,
          };

          option.images.push(optionImage);
        });

        console.log(selectedMachineImageLayers);
        /**
         * Set stage layers for layer
         */
        selectedMachineImageLayers.forEach((machineImageLayer) => {
          if (machineImageLayer.article.id === option.articleId) {
            // console.log("PROCESS machineImageLayer", machineImageLayer);

            option.imagelayers = [];

            option.imageLayer = {
              position: machineImageLayer.position,
              baseLayer: `${process.env.VUE_APP_API_BASE_FILES}${machineImageLayer.base_layer.url}`,
              viewDefault: `${process.env.VUE_APP_API_BASE_FILES}${machineImageLayer.base_layer.url}`,
              viewMulti: `${process.env.VUE_APP_API_BASE_FILES}${machineImageLayer.base_layer.url}`,
            };

            if (Array.isArray(machineImageLayer.layers)) {
              console.log("Machine State Layers have multi views layer");

              machineImageLayer.layers.map((layerView) => {
                console.log(layerView);

                if (layerView.target.includes("1") && layerView.url) {
                  console.log(layerView);

                  const optionLayerView = {
                    view1: layerView.url,
                  };

                  option.imagelayers.push(optionLayerView);
                  option.imageLayer.viewDefault = `${process.env.VUE_APP_API_BASE_FILES}${layerView.url}`;
                }

                if (layerView.target.includes("n") && layerView.url) {
                  const optionLayerView = {
                    viewn: layerView.url,
                  };

                  option.imagelayers.push(optionLayerView);
                  option.imageLayer.viewMulti = `${process.env.VUE_APP_API_BASE_FILES}${layerView.url}`;
                }
              });
            }
          }
        });
      }
    });

    console.log("state.selectedMachineOptions", state.selectedMachineOptions);
    console.groupEnd();
  },

  /**
   * Select machine category if undefined always select first category
   * Filter selected machine option per category
   * @param {*} state
   * @param {*} categoryId
   */
  SELECT_MACHINE_CATEGORY(state, categoryId) {
    console.groupCollapsed("mutatuon - SELECT_MACHINE_CATEGORY");
    console.log("SELECT_MACHINE_CATEGORY", categoryId);

    let selectedMachineOptions = state.selectedMachineOptions;

    // Handle undefined categoryId always select first one
    if (categoryId == undefined) {
      categoryId = state.selectedMachineCategories[0].id;
    }

    const category = state.selectedMachineCategories.find(
      (cat_) => cat_.id === categoryId
    );

    let filteredMachineOptions = selectedMachineOptions.filter(
      (option) => option.categoryId === categoryId
    );

    filteredMachineOptions = filteredMachineOptions.sort(
      (a, b) => a.option_order - b.option_order
    );

    state.selectedMachineCategoryOptions = filteredMachineOptions;
    state.selectedMachineCategory = category;

    console.log(
      "state.selectedMachineCategoryOptions",
      state.selectedMachineCategoryOptions
    );
    console.log("state.selectedMachineCategory", state.selectedMachineCategory);
    console.groupEnd();
  },

  SET_SELECTED_MACHINE_CONFIGURATION_RULES(state, { configurationRules }) {
    console.groupCollapsed(
      "Mutation - SET_SELECTED_MACHINE_CONFIGURATION_RULES"
    );
    console.log("rules", configurationRules);

    const filteredconfigurationRules = configurationRules.filter(
      (rule) => rule.is_active
    );

    state.selectedMachineConfigurationRules = filteredconfigurationRules;

    console.log(
      "state.selectedMachineConfigurationRules",
      state.selectedMachineConfigurationRules
    );
    console.groupEnd();
  },

  SET_SELECTED_MACHINE_IMAGELAYERS(state, imageLayers) {
    console.groupCollapsed("mutatuon - SET_SELECTED_MACHINE_IMAGELAYERS");
    console.log(imageLayers);

    state.selectedMachineImageLayers = [...imageLayers];

    const selectedMachineOptions = state.selectedMachineOptions;

    imageLayers.forEach((imageLayer) => {
      if (imageLayer.article && imageLayer.article.id !== undefined) {
        const machineOption = selectedMachineOptions.find(
          (option) => option.article.id === imageLayer.article.id
        );

        if (machineOption) {
          console.log(machineOption);

          machineOption.imagelayers = [];

          machineOption.imageLayer = {
            position: imageLayer.position,
            baseLayer: `${process.env.VUE_APP_API_BASE_FILES}${imageLayer.base_layer.url}`,
            viewDefault: `${process.env.VUE_APP_API_BASE_FILES}${imageLayer.base_layer.url}`,
            viewMulti: `${process.env.VUE_APP_API_BASE_FILES}${imageLayer.base_layer.url}`,
          };

          if (Array.isArray(imageLayer.layers)) {
            console.log("Machine State Layers have multi views layer");

            imageLayer.layers.map((layerView) => {
              console.log(layerView);

              if (layerView.target.includes("1") && layerView.url) {
                console.log(layerView);

                const optionLayerView = {
                  view1: layerView.url,
                };

                machineOption.imagelayers.push(optionLayerView);
                machineOption.imageLayer.viewDefault = `${process.env.VUE_APP_API_BASE_FILES}${layerView.url}`;
              }

              if (layerView.target.includes("n") && layerView.url) {
                const optionLayerView = {
                  viewn: layerView.url,
                };

                machineOption.imagelayers.push(optionLayerView);
                machineOption.imageLayer.viewMulti = `${process.env.VUE_APP_API_BASE_FILES}${layerView.url}`;
              }
            });
          }
        }
      }
    });

    console.log(
      "state.selectedMachineImageLayers",
      state.selectedMachineImageLayers
    );
    console.groupEnd();
  },

  // Add rule message
  ADD_RULE_VALIDATE_ACTION_MESSAGE(state, ruleMessage) {
    console.groupCollapsed("mutatuon - ADD_RULE_VALIDATE_ACTION_MESSAGE");
    console.log("ruleMessage", ruleMessage);

    let messageExists = state.configuratorRuleMessages.find(
      (msg) => msg.id == ruleMessage.id
    );

    if (!messageExists) {
      state.configuratorRuleMessages.push(ruleMessage);
    }
    console.log(
      "state.configuratorRuleMessages",
      state.configuratorRuleMessages
    );
    console.groupEnd();
  },

  // Remove rule message by id
  REMOVE_RULE_VALIDATE_ACTION_MESSAGE(state, ruleMessageId) {
    console.groupCollapsed("mutatuon - REMOVE_RULE_VALIDATE_ACTION_MESSAGE");
    console.log("ruleMessageId", ruleMessageId);

    state.configuratorRuleMessages = state.configuratorRuleMessages.filter(
      (msg) => {
        return msg.id !== ruleMessageId;
      }
    );

    console.log(
      "state.configuratorRuleMessages",
      state.configuratorRuleMessages
    );
    console.groupEnd();
  },

  // Clear All Rules messages
  CLEAR_RULE_VALIDATE_ACTION_MESSAGE(state) {
    console.groupCollapsed("mutatuon - CLEAR_RULE_VALIDATE_ACTION_MESSAGE");
    state.configuratorRuleMessages.splice(
      0,
      state.configuratorRuleMessages.length
    );
    console.log(
      "state.configuratorRuleMessages",
      state.configuratorRuleMessages
    );
    console.groupEnd();
  },

  ///////////////////////////////////
  ///////////////////////////////////
  TOGGLE_CHECKOUT_MODE(state, value) {
    console.groupCollapsed("mutations - TOGGLE_CHECKOUT_MODE");

    state.isInCheckoutMode = value;

    console.log("state.isInCheckoutMode", state.isInCheckoutMode);
    console.groupEnd();
  },
  SET_orderData(state, orderData) {
    console.groupCollapsed("mutations - SET_orderData");

    state.orderData = orderData;
    console.groupEnd();
  },

  ADD_OPTION_TO_CART(state, option) {
    console.groupCollapsed("mutations - ADD_OPTION_TO_CART");

    const cart = state.cart;
    const isInCart = cart.find((item) => item.id === option.id);

    if (isInCart == undefined) {
      option.orderQty = 1;
      state.cart.push(option);
    }

    console.log("state.cart", state.cart);
    console.groupEnd();
  },
  CLEAR_CART(state) {
    console.groupCollapsed("mutations - CLEAR_CART");

    state.cart.splice(0, state.cart.length);

    console.log("state.cart", state.cart);
    console.groupEnd();
  },

  /**
   * Add option to cart if it is not in cart.
   * If it is in cart, increase the qty by 1.
   * @param {*} state
   * @param {*} option
   */
  ADD_OR_CHANGE_OPTION_QTY_TO_CART(state, payload) {
    console.groupCollapsed("mutations - ADD_OR_CHANGE_OPTION_QTY_TO_CART");
    console.log("payload", payload);

    const { option, optionQty } = payload;

    const optionInCart = state.cart.find((item) => item.id === option.id);
    const optionQtyChange = optionQty !== undefined ? optionQty : 1;

    // Option not in cart add it
    if (optionInCart == undefined) {
      option.orderQty = optionQtyChange;
      state.cart.push(option);
    }

    // Option in cart increasse its qty if it allows
    else {
      if (optionInCart.allowOrderQty) {
        const newQty = optionInCart.orderQty + optionQtyChange;

        if (newQty > optionInCart.maxQty) {
          optionInCart.orderQty = optionInCart.maxQty;
        } else {
          optionInCart.orderQty = newQty;
        }
      }
    }

    console.log("state.cart", state.cart);
    console.groupEnd();
  },

  /**
   *  Decrease option qty by 1 if it is in cart.
   * @param {*} state
   * @param {Object} option  - Option to decrease qty
   */
  DECREASE_OPTION_QTY_IN_CART(state, payload) {
    console.groupCollapsed("mutations - DECREASE_OPTION_QTY_IN_CART");
    console.log("payload", payload);
    const { option, optionQty } = payload;

    const optionInCart = state.cart.find((item) => item.id === option.id);
    const optionQtyChange = optionQty !== undefined ? optionQty : 1;

    // Option not in cart add it
    if (optionInCart) {
      const newQty = optionInCart.orderQty - optionQtyChange;

      optionInCart.orderQty = newQty < 0 ? 0 : newQty;
    }

    console.log("state.cart", state.cart);
    console.groupEnd();
  },

  /**
   * Change a given option qty in cart with the given qty
   *
   * @param {*} state
   * @param {Object.articleId} articleId  - Article ID of the option to remove
   * @param {Object.qty} qty  - Qty of the option in cart to change
   */
  CHANGE_OPTION_QTY_IN_CART(state, payload) {
    console.groupCollapsed("mutations - CHANGE_OPTION_QTY_IN_CART");
    console.log("payload", payload);

    const optionInCart = state.cart.find(
      (item) => item.articleId === payload.optionId
    );

    if (optionInCart) {
      //optionInCart.allowOrderQty

      console.log("optionInCart", optionInCart);

      optionInCart.orderQty = payload.qty;
    }

    console.log("state.cart", state.cart);
    console.groupEnd();
  },

  TOGGLE_CART_SUBMIT_READY(state, value) {
    console.groupCollapsed("mutations - TOGGLE_CART_SUBMIT_READY");
    state.cartButtonDisabled = value;
    console.log("state.cartButtonDisabled", state.cartButtonDisabled);
    console.groupEnd();
  },

  REMOVE_OPTION_FROM_CART(state, option) {
    console.groupCollapsed("mutations - REMOVE_OPTION_FROM_CART");
    console.log("option", option);

    // Reset option qty to 0
    option.orderQty = 0;

    if (Array.isArray(option)) {
      option.forEach((option) => {
        state.cart = state.cart.filter((cartItem) => {
          return cartItem.id !== option.id;
        });
      });
    } else {
      state.cart = state.cart.filter((cartItem) => {
        return cartItem.id !== option.id;
      });
    }

    console.log("state.cart", state.cart);
    console.groupEnd();
  },

  CHANGE_OPTION_QTY(state, payload) {
    console.groupCollapsed("mutations: CHANGE_OPTION_QTY");
    console.log("state", state);
    console.log("payload", payload);

    const optionInCart = state.cart.find(
      (cartIem) => cartIem.allowOrderQty && cartIem.id === payload.option.id
    );

    if (optionInCart) {
      optionInCart.orderQty = payload.count;
    }

    // payload.option.orderQty = payload.count;

    console.groupEnd();
  },
  CHANGE_OPTION_ALTERNATIVE_ARTICLE(state, payload) {
    console.groupCollapsed("mutations: CHANGE_OPTION_ALTERNATIVE_ARTICLE");

    const { option, altArticleId } = payload;

    // Get the the target alt article option
    const altArticleMachineOption = storeUtils.getMachineOptionByArticleUnitid(
      Number(altArticleId)
    );

    if (altArticleMachineOption) {
      console.warn("FOUND altArticleMachineOption", altArticleMachineOption);

      /**
       * If there previous alt option not equal to target alt option remote it first
       */

      const optionPrevAltArticleId = option.altArticles
        .filter((altArticle) => altArticle.articleId !== altArticleId)
        .map((altArticle) => altArticle.articleId);
      console.log("optionPrevAltArticleId", optionPrevAltArticleId);

      optionPrevAltArticleId.map((altArticleId) => {
        const prevAltArticleMAchineOption = storeUtils.getMachineOptionByArticleUnitid(
          Number(altArticleId)
        );

        if (
          state.cart.find((item) => item.id === prevAltArticleMAchineOption.id)
        ) {
          console.log(
            "previous alt article option",
            prevAltArticleMAchineOption
          );
          // REmove the previous alt article option from cart
          prevAltArticleMAchineOption.orderQty = 0;
          state.cart = state.cart.filter((cartItem) => {
            return cartItem.id !== prevAltArticleMAchineOption.id;
          });
        }
      });

      /**
       * Check if the target alt article option is not in cart and add it
       */
      if (!state.cart.find((item) => item.id === altArticleMachineOption.id)) {
        console.log(
          "altArticleMachineOption not in cart",
          altArticleMachineOption
        );
        altArticleMachineOption.orderQty = 1;
        state.cart.push(altArticleMachineOption);
      }

      /**
       * Remove the original option from cart
       */

      // Reset option qty to 0
      option.orderQty = 0;
      option.altArticle = altArticleId; // STORE the alt article option so we can remote it later

      if (Array.isArray(option)) {
        option.forEach((opt) => {
          state.cart = state.cart.filter((cartItem) => {
            return cartItem.id !== opt.id;
          });
        });
      } else {
        state.cart = state.cart.filter((cartItem) => {
          return cartItem.id !== option.id;
        });
      }
    }

    console.log("altArticleMachineOption", altArticleMachineOption);
    console.groupEnd();
  },
  CLEAR_OPTION_ALTERNATIVE_ARTICLE(state, payload) {
    console.groupCollapsed("mutations: CLEAR_OPTION_ALTERNATIVE_ARTICLE");
    console.log("state", state);
    console.log("payload", payload);

    const mainMachineOption = storeUtils.getMachineOptionByid(
      payload.option.id
    );
    const altMAchineOption = storeUtils.getMachineOptionByArticleUnitid(
      Number(mainMachineOption.altArticle)
    );

    // Get the alt option in cart
    const altOptionInCart = state.cart.find(
      (cartIem) => cartIem.id === altMAchineOption.id
    );

    if (altOptionInCart) {
      mainMachineOption.altArticle = null; // Clear first
      // Remove the alt article option from cart and add the original option

      altOptionInCart.orderQty = 0;
      state.cart = state.cart.filter((cartItem) => {
        return cartItem.id !== altOptionInCart.id;
      });

      // Add main option to cart
      mainMachineOption.orderQty = 1;

      state.cart.push(mainMachineOption);
    }

    console.groupEnd();
  },

  ADD_MACHINE_DEFAULT_OPTION_TO_CART(state, { rootState }) {
    console.groupCollapsed("mutation: ADD_MACHINE_DEFAULT_OPTION_TO_CART");
    console.log("state", state);
    console.log("rootState", rootState);
    const selectedMachineOptions = state.selectedMachineOptions;
    console.log("selectedMachineOptions", selectedMachineOptions);

    selectedMachineOptions.forEach((option) => {
      if (option.isDefault) {
        const isInCart = state.cart.find((item) => item.id === option.id);

        if (isInCart == undefined) {
          option.orderQty = 1;
          state.cart.push(option);
        }
      }
    });

    console.log("state.cart", state.cart);

    console.groupEnd();
  },

  ADD_CONFIGURATOR_EDIT_OPTIONS_TO_CART(state, { rootState }) {
    console.groupCollapsed("mutation: ADD_CONFIGURATOR_EDIT_OPTIONS_TO_CART");
    console.log("state", state);
    console.log("rootState", rootState);

    const configuratorEditDocument = state.configuratorEditDocument;

    console.warn("configuratorEditDocument", configuratorEditDocument);

    const configuratorEditDocumentArticles = configuratorEditDocument.articles;

    console.warn("configuratorEditDocumentArticles", configuratorEditDocumentArticles);

    const documentPositionIds = configuratorEditDocument.articles.map(({ id }) => Number(id));
    console.log("documentPositionIds ", documentPositionIds);

    const targetMOptions = storeUtils.getMultipleMachineOptionByArticleId(
      documentPositionIds
    );

    console.warn("targetMOptions", targetMOptions);

    targetMOptions.forEach((option) => {
      const isInCart = state.cart.find((item) => item.id === option.id);

      if (isInCart == undefined) {
        const documentPositionArticle = configuratorEditDocument.articles.find(
          ({ id }) => Number(id) === option.articleUnitId
        );

        if (documentPositionArticle) {
          option.orderQty = documentPositionArticle.qty;
          state.cart.push(option);
        }
      }
    });

    console.groupEnd();
  },

  SUBMIT_ORDER(state, { rootState }) {
    console.groupCollapsed("mutation: SUBMIT_ORDER");
    console.log("state", state);
    console.log("rootState", rootState);

    console.groupEnd();
  },
};
