import axios from "axios";
import store from "../store";


const httpService = axios.create({
  timeout: 10000,
  headers: { "Content-Type": "application/json" },
});
const apiService = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  timeout: 10000,
  // headers: { "Content-Type": "application/json" }
});

//axios.defaults.timeout = 10000;

apiService.interceptors.request.use(
  (config) => {
    // console.log("store.getters.authToken", store.getters.authToken);

    if (store.getters.authToken) {
      config.headers.Authorization = "Bearer" + " " + store.getters.authToken;
    }
    return config;
    /* if (config.url == "/auth/signin") {
      return config;
    }
    const token = getLocalAccessToken();
    if (token) {
      config.headers.Authorization = "Bearer" + " " + token;
      return config;
    } */
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiService.interceptors.response.use(
  (response) => {
    console.log("response", response);
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },

  (error) => {
    console.log("error", error);
    /*    if (error.response.status) {
      //
    } */
    return Promise.reject(error.response);
  }
);

export { apiService, httpService };
