import { mapGetters } from "vuex";

export default {
  data() {
    return {
      url: "https://aconity3d.com",
      defaultLocale: "EN",
      menu,
      locale: 'EN'
    };
  },
  computed: {
    ...mapGetters(["selectedLanguage"]),
    locale() {
      return this.selectedLanguage == undefined
        ? this.$route.params.lang
        : this.selectedLanguage;
    },
    logoLink() {
      return `/assets/images/logo.png`;
    },
  },
  methods: {
    copyrightYear() {
      let date = new Date();
      return date.getFullYear();
    },
    getSiteLink(path) {
      let localePart =
        this.locale == this.defaultLocale
          ? ""
          : "/" + this.locale.toLowerCase();
      return `${this.url}${localePart}${path}`;
    },
    getLang(text) {
      return dotGet(text, langData[this.locale.toLowerCase()]);
    },
  },
};

var dotGet = function(str, obj) {
  return str.split(".").reduce(function(obj, i) {
    return obj[i];
  }, obj);
};

const menu = {
  primary_1_mobile: [
    { id: "machines", to: "/machines" },
    { id: "jobshop", to: "/job-shop" },
    { id: "testing", to: "/testing" },
    { id: "consulting", to: "/consulting" },
    { id: "shop", to: "https://shop.aconity3d.com", external: true },
    { id: "academy", to: "/academy"},
    { id: "news", to: "https://news.aconity3d.com", external: true },
    { id: "team", to: "/team" },
    { id: "jobs", to: "/jobs" },
  ],
  primary_1: [
    { id: "machines", to: "/machines" },
    {
      id: "services",
      submenu: [
        { id: "jobshop", to: "/job-shop" },
        { id: "testing", to: "/testing" },
        { id: "consulting", to: "/consulting" },
      ],
    },
    { id: "shop", to: "https://shop.aconity3d.com", external: true },
    { id: "academy", to: "/academy"},
    {
      id: "about_us",
      submenu: [
        { id: "news", to: "https://news.aconity3d.com", external: true },
        { id: "team", to: "/team" },
        { id: "jobs", to: "/jobs" },
      ],
    },
  ],
  footer_1: [
    { id: "consulting", to: "/consulting" },
    { id: "testing", to: "/testing" },
    { id: "jobshop", to: "/job-shop" },
  ],
  footer_2: [
    { id: "aconity_two", to: "/products/aconity-two" },
    { id: "aconity_midi_plus", to: "/products/aconity-midi-plus" },
    { id: "aconity_midi", to: "/products/aconity-midi" },
    { id: "aconity_micro", to: "/products/aconity-micro" },
    { id: "aconity_mini", to: "/products/aconity-mini" },
    { id: "aconity_wire", to: "/products/3dmetalwire" }
  ],
  footer_3: [
    { id: "linkedin" },
    { id: "youtube" },
    { id: "instagram" },
    { id: "facebook" },
    { id: "twitter" }
  ],
  footer_4: [
    { id: "team", to: "/team" },
    { id: "jobs", to: "/jobs" },
  ],
};

const langData = {
  en: {
    site: {
      copyright: "Copyright",
      all_rights_reserved: "All rights reserved.",
    },
    header: {
      menu: {
        machines: {
          label: "Machines",
        },
        team: {
          label: "Our Team",
        },
        testing: {
          label: "Testing",
        },
        jobshop: {
          label: "Jobshop",
        },
        consulting: {
          label: "Consulting",
        },
        about: {
          label: "About",
        },
        about_us: {
          label: "About us",
        },
        academy: {
          label: "Academy",
        },
        equipment: {
          label: "Equipment",
        },
        services: {
          label: "Services",
        },
        shop: {
          label: "Shop",
        },
        news: {
          label: "News",
        },
        jobs: {
          label: "Jobs",
        },
      },
      user: {
        articles: {
          label: "Manage articles"
        },
        agents: {
          label: "Manage agents"
        },
        customers: {
          label: "Manage customers"
        },
        shipping_costs: {
          label: "Manage shipping costs"
        },
        global_info: {
          label: "Global information"
        },
        support: {
          label: "Support"
        },
        logout: {
          label: "Logout"
        }
      },
      login: "Login",
      cta_label: "Get Started",
      cta_get_quote: "Contact us",
    },
    footer: {
      link_privacy: "Privacy Policy",
      link_impressum: "Impressum",

      title_services: "Services",
      title_equipment: "Equipment",
      title_social_links: "Follow Us",
      title_about_us: "About us",

      link_consulting: "Consulting",
      link_testing: "Testing",
      link_jobshop: "JobShop",
      link_equipment: "Equipment",

      link_aconity_two: `Aconity<span class="aconity-brand-text">TWO</span>`,
      link_aconity_midi_plus: `Aconity<span class="aconity-brand-text">MIDI+</span>`,
      link_aconity_mini: `Aconity<span class="aconity-brand-text">MINI</span>`,
      link_aconity_midi: `Aconity<span class="aconity-brand-text">MIDI</span>`,
      link_aconity_micro: `Aconity<span class="aconity-brand-text">MICRO</span>`,
      link_aconity_wire: `Aconity<span class="aconity-brand-text">WIRE</span>`,

      link_about_us: "About Us",
      link_contact_us: "Contact Us",
      link_careers: "Careers",
      link_team: "Our Team",
      link_jobs: "Jobs",
      link_news: "News",
    },
  },

  de: {
    site: {
      copyright: "Copyright",
      all_rights_reserved: "Alle Rechte vorbehalten.",
    },
    header: {
      menu: {
        machines: {
          label: "Maschinen",
        },
        team: {
          label: "Unser Team",
        },
        testing: {
          label: "Testing",
        },
        jobshop: {
          label: "Jobshop",
        },
        consulting: {
          label: "Beratung",
        },
        about: {
          label: "Über",
        },
        about_us: {
          label: "Über Uns",
        },
        equipment: {
          label: "Ausrüstung",
        },
        services: {
          label: "Dienstleistungen",
        },
        shop: {
          label: "Shop",
        },
        news: {
          label: "Nachrichten",
        },
        jobs: {
          label: "Jobs",
        },
      },
      user: {
        articles: {
          label: "Manage articles"
        },
        agents: {
          label: "Manage agents"
        },
        customers: {
          label: "Manage customers"
        },
        shipping_costs: {
          label: "Manage shipping costs"
        },
        global_info: {
          label: "Global information"
        },
        support: {
          label: "Support"
        },
        logout: {
          label: "Logout"
        }
      },
      login: "Login",
      cta_label: "Loslegen",
      cta_get_quote: "Kontakt",
    },

    footer: {
      link_privacy: "Datenschutzerklärung",
      link_impressum: "Impressum",

      title_services: "Services",
      title_equipment: "Maschinen",
      title_social_links: "Folge uns",
      title_about_us: "Über uns",

      link_consulting: "Beratung",
      link_testing: "Testen",
      link_jobshop: "JobShop",
      link_equipment: "Equipment",

      link_aconity_two: `Aconity <span class="aconity-brand-text">TWO</span>`,
      link_aconity_midi_plus: `Aconity <span class="aconity-brand-text">MIDI+</span>`,
      link_aconity_mini: `Aconity <span class="aconity-brand-text">MINI</span>`,
      link_aconity_midi: `Aconity <span class="aconity-brand-text">MIDI</span>`,
      link_aconity_micro: `Aconity <span class="aconity-brand-text">MICRO</span>`,
      link_aconity_wire: `Aconity <span class="aconity-brand-text">WIRE</span>`,

      link_about_us: "Über uns",
      link_contact_us: "Kontaktiere uns",
      link_careers: "Careers",
      link_team: "Unser Team",
      link_jobs: "Jobs",

      link_news: "News",
    },
  },
};
